import { InvoiceResponse } from "@progresspay-next/dtos";
import { ConfirmDialog } from "../ui/confirm";
import { Button } from "../ui/button";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getApi } from "@/utils/api";
import { App, Col, Divider, Row } from "antd";
import { queryKey } from "@/utils/query";
import moment from "moment";
import { momentjsFormat } from "@progresspay-next/shared";
import { CalendarDatePicker } from "@/components/CalendarDatePicker";
import { invoiceHelper } from "@progresspay-next/shared";
import { Input } from "../ui/input";
import { Copy } from "lucide-react";

type InvoiceAdminControlPanelProps = {
  invoice: InvoiceResponse;
};
export const InvoiceAdminControlPanel = (
  props: InvoiceAdminControlPanelProps
) => {
  const { invoice } = props;
  const [isExpiryConfirmOpen, setIsExpiryConfirmOpen] = useState(false);
  const [isOrigPayDueDateConfirmOpen, setIsOrigPayDueDateConfirmOpen] =
    useState(false);
  const [isERPIdConfirmOpen, setIsERPIdConfirmOpen] = useState(false);
  const api = getApi();
  const queryClient = useQueryClient();
  const { message } = App.useApp();

  const [selectedPaymentDate, setSelectedPaymentDate] = useState<
    Date | undefined
  >(() => {
    if (invoice.original_payment_due_date) {
      return moment(invoice.original_payment_due_date).toDate();
    }

    return undefined;
  });

  const [erpId, setERPId] = useState<string>(invoice.erp_id || "");

  const markExpiredMutation = useMutation({
    mutationFn: () => api.expireInvoice(invoice.id),
    onSuccess: () => {
      message.success(`This invoice has been successfully marked as expired.`);
      queryClient.invalidateQueries({ queryKey: queryKey.invoices() });
    },
  });
  const markOrigPayDueDateMutation = useMutation({
    mutationFn: () =>
      api.updateInvoice({
        id: invoice.id,
        original_payment_due_date: moment(selectedPaymentDate).format(
          momentjsFormat.dateDB
        ),
      }),
    onSuccess: () => {
      message.success(`This invoice has been successfully updated.`);
      queryClient.invalidateQueries({ queryKey: queryKey.invoices() });
    },
  });
  const updateERPIdMutation = useMutation({
    mutationFn: () =>
      api.updateInvoice({
        id: invoice.id,
        erp_id: erpId,
      }),
    onSuccess: () => {
      message.success(`This invoice has been successfully updated.`);
      queryClient.invalidateQueries({ queryKey: queryKey.invoices() });
    },
  });

  const transactionDataMutation = useMutation({
    mutationFn: () => api.getJobpacInvoiceTransactionData(invoice.id),
    onSuccess: () => {
      message.success(`Successfully fetched transaction data from Jobpac`);
    },
  })

  return (
    <div className="py-6">
      <div>
        <div className="mb-6 items-center flex flex-row gap-2">
          <div className="flex-0 w-1/3">
            <label className="">Invoice ID</label>
          </div>
          <div className="flex-1 w-5/12">
            <div className="mb-1 bg-white bg-opacity-5">
              <Input value={invoice.id} readOnly />
            </div>
          </div>
          <div className="flex-0 w-1/4">
            <Button
              onClick={() => {
                navigator.clipboard.writeText(invoice.id);
                message.success(`Invoice ID copied to clipboard`);
              }}
              variant={"ghost"}
              size={"sm"}
              className="text-slate-500"
            >
              <Copy className="w-4 h-4"/>
            </Button>
          </div>
        </div>
      </div>
      <Divider />
      <div>
        <div className="mb-6 items-center flex flex-row gap-2">
          <div className="flex-0 w-1/3">
            <label className="">Payment Terms</label>
          </div>
          <div className="flex-0 w-2/3">
            <div className="bg-white bg-opacity-5">
              {invoice.contract?.metadata?.payment_terms && (
                <pre className="bg-gray-50 p-2 rounded-md border border-gray-300 leading-tight">
                  <code className="text-xs">
                    {JSON.stringify(
                      invoice.contract?.metadata?.payment_terms,
                      null,
                      4
                    )}
                  </code>
                </pre>
              )}
            </div>
          </div>
        </div>
      </div>
      <Divider />
      <div>
        <div className="mb-6 items-center flex flex-row gap-2">
          <div className="flex-0 w-1/3">
            <label className="">Basis Date</label>
          </div>
          <div className="flex-1 w-5/12">
            <div className="mb-1 bg-white bg-opacity-5">
              <Input value={invoice.external_data?.claim_as_at} readOnly />
            </div>
          </div>
          <div className="flex-0 w-1/4"></div>
        </div>
      </div>
      <Divider />
      <div>
        <div className="mb-6 items-center flex flex-row gap-2">
          <div className="flex-0 w-1/3">
            <label className="">Original Payment Due Date</label>
          </div>
          <div className="flex-1 w-5/12">
            <div className="mb-1 bg-white bg-opacity-5">
              <CalendarDatePicker
                fromDate={new Date()}
                value={selectedPaymentDate}
                onChange={setSelectedPaymentDate}
              />
            </div>
          </div>
          <div className="flex-0 w-1/4">
            <Button
              onClick={() => setIsOrigPayDueDateConfirmOpen(true)}
              disabled={
                moment(invoice.original_payment_due_date).format(
                  "DD/MM/YYYY"
                ) === moment(selectedPaymentDate).format("DD/MM/YYYY")
              }
            >
              Update
            </Button>
            <ConfirmDialog
              open={isOrigPayDueDateConfirmOpen}
              onOpenChange={(v) => setIsOrigPayDueDateConfirmOpen(v)}
              callback={() => markOrigPayDueDateMutation.mutate()}
              title={
                <div className="mb-4">
                  Do you want to update the original payment due date?
                </div>
              }
            ></ConfirmDialog>
          </div>
        </div>
        <div className="mb-6 items-center flex flex-row gap-2">
          <div className="flex-0 w-1/3">
            <label className="">Revised Payment Due Date</label>
          </div>
          <div className="flex-1 w-5/12">
            <div className="mb-1 bg-white bg-opacity-5">
              <Input value={invoice.revised_payment_due_date || ""} readOnly />
            </div>
          </div>
          <div className="flex-0 w-1/4"></div>
        </div>
      </div>
      <Divider />
      <div>
        <div className="mb-6 items-center flex flex-row gap-2">
          <div className="flex-0 w-1/3">
            <label className="">Transaction Data</label>
          </div>
          <div className="flex-1 w-5/12">
            <div className="bg-white bg-opacity-5">
              {transactionDataMutation.data && (
                <pre className="bg-gray-50 p-2 rounded-md border border-gray-300 leading-tight">
                  <code className="text-xs">
                    {JSON.stringify(
                      transactionDataMutation.data,
                      null,
                      4
                    )}
                  </code>
                </pre>
              )}
            </div>
          </div>
          <div className="flex-0 w-1/4">
            <Button
              onClick={() => transactionDataMutation.mutate()}
              disabled={transactionDataMutation.isPending || invoice.external_data.claim_status !== 'APPROVED'}
            >
              Fetch from Jobpac
            </Button>
            <ConfirmDialog
              open={isERPIdConfirmOpen}
              onOpenChange={(v) => setIsERPIdConfirmOpen(v)}
              callback={() => updateERPIdMutation.mutate()}
              title={
                <div className="mb-4">Do you want to update the ERP ID?</div>
              }
            >
              <div>
                New ERP ID will be{" "}
                <span className="font-bold text-slate-500">{erpId}</span>
              </div>
            </ConfirmDialog>
          </div>
        </div>
      </div>
      <Divider />
      <div>
        <div className="mb-6 items-center flex flex-row gap-2">
          <div className="flex-0 w-1/3">
            <label className="">ERP ID (Transaction ID)</label>
          </div>
          <div className="flex-1 w-5/12">
            <div className="mb-1 bg-white bg-opacity-5">
              <Input value={erpId} onChange={(e) => setERPId(e.target.value)} />
            </div>
          </div>
          <div className="flex-0 w-1/4">
            <Button
              onClick={() => setIsERPIdConfirmOpen(true)}
              disabled={erpId === (invoice.erp_id || "")}
            >
              Update
            </Button>
            <ConfirmDialog
              open={isERPIdConfirmOpen}
              onOpenChange={(v) => setIsERPIdConfirmOpen(v)}
              callback={() => updateERPIdMutation.mutate()}
              title={
                <div className="mb-4">Do you want to update the ERP ID?</div>
              }
            >
              <div>
                New ERP ID will be{" "}
                <span className="font-bold text-slate-500">{erpId}</span>
              </div>
            </ConfirmDialog>
          </div>
        </div>
      </div>
      <Divider />
      <div>
        <div className="mb-6 items-center flex flex-row gap-2">
          <div className="flex-0 w-3/4">
            <p className="">
              {invoice.expired_at
                ? `Marked as expired on ${moment(invoice.expired_at).format(
                    momentjsFormat.dateTime
                  )}`
                : ``}
            </p>
          </div>
          <div className="flex-0 w-1/4">
            <Button
              onClick={() => setIsExpiryConfirmOpen(true)}
              disabled={!invoiceHelper.canExpire(invoice)}
            >
              Mark as Expired
            </Button>
            <ConfirmDialog
              open={isExpiryConfirmOpen}
              onOpenChange={(v) => setIsExpiryConfirmOpen(v)}
              callback={() => markExpiredMutation.mutate()}
              title={
                <>
                  <div className="mb-4">Are you sure?</div>
                  <div className="font-normal text-base mb-4">
                    By expiring the invoice, it will no longer show in the
                    dashboard.
                  </div>
                </>
              }
            ></ConfirmDialog>
          </div>
        </div>
      </div>
    </div>
  );
};
