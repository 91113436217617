import { CreateExclusionRequest, FindExclusionsRequest } from '@progresspay-next/dtos';
import { ExclusionResponse, ExclusionsResponse } from '@progresspay-next/dtos';
import Request from './_request';
const { _get, _post, _put, _delete } = Request;

export const findExclusionsByOrganisationId = (organisation_id: string) => {
  const request: FindExclusionsRequest = {
    organisation_id: `${organisation_id}`
  }
  return _get<ExclusionsResponse>('/exclusions', request);
}

export const createExclusion = (payload: CreateExclusionRequest) => {
  return _post<ExclusionResponse>('/exclusions', payload)
}

export const deleteExclusionById = (id: string) => {
  return _delete<ExclusionResponse>(`/exclusions/${id}`)
}
