"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImportExternalContractRequestSchema = exports.ImportExternalProjectRequestSchema = void 0;
const zod_1 = require("zod");
exports.ImportExternalProjectRequestSchema = zod_1.z.object({
    organisation_id: zod_1.z.string(),
    erp_id: zod_1.z.string(),
    name: zod_1.z.string(),
    data: zod_1.z.any().optional(),
});
exports.ImportExternalContractRequestSchema = zod_1.z.object({
    organisation_id: zod_1.z.string(),
    project_id: zod_1.z.string(),
    erp_id: zod_1.z.string(),
    name: zod_1.z.string(),
    metadata: zod_1.z.any().optional(),
});
