"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const zod_1 = require("zod");
const JobpacContractsResponseSchema = zod_1.z.object({
    data: zod_1.z.any().array(),
});
const JobpacOrganisationsResponseSchema = zod_1.z.object({
    workIdListings: zod_1.z.any().array(),
    erp_id_key: zod_1.z.string()
});
const JobpacProjectsResponseSchema = zod_1.z.object({
    JobListing: zod_1.z.any().array(),
    erp_id_key: zod_1.z.string()
});
const JobpacSubcontractorsResponseSchema = zod_1.z.object({
    data: zod_1.z.any().array(),
});
const JobpacClaimsResponseSchema = zod_1.z.object({
    data: zod_1.z.any().array(),
});
