"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectsResponseSchema = exports.ProjectResponseSchema = void 0;
const contract_type_1 = require("../types/contract.type");
const organisation_type_1 = require("../types/organisation.type");
const project_type_1 = require("../types/project.type");
exports.ProjectResponseSchema = project_type_1.ProjectTypeSchema.extend({
    organisation: organisation_type_1.OrganisationTypeSchema.nullable().optional(),
    contracts: contract_type_1.ContractTypeSchema.array().nullable().optional(),
});
exports.ProjectsResponseSchema = exports.ProjectResponseSchema.array();
