import { BookCopy, DollarSign, Hourglass, PiggyBank, Receipt, Stamp } from "lucide-react";
import { GraphicSummary } from "./GraphicSummary";
import { Section } from "../Section";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { EligibleInvoices } from "../eligible-invoices/EligibleInvoices";
import { RequestedInvoices } from "./RequestedInvoices";
import { useSearchParams } from "react-router-dom";
import { useContextStore } from "@/stores/context";
import {
  useQueryEligibleInvoicesNonAdmin,
  useQueryInvoicesSummaryNonAdmin,
} from "../../utils/query";
import React, { useEffect, useState } from "react";
import { formatCurrency } from "@progresspay-next/shared";
import { useInvoiceActionStore } from "@/stores/invoiceAction";
import { useDashboardFilterStore } from "@/stores/dashboardFilter";

export const ChangeTabValueContext = React.createContext(
  (newValue: string) => {}
);

type DashboardProps = {};

export const Dashboard = (props: DashboardProps) => {
  const dashboardFilterStore = useDashboardFilterStore();
  const { data: summary } = useQueryInvoicesSummaryNonAdmin(dashboardFilterStore.getFilterQuery());

  let [searchParams, setSearchParams] = useSearchParams();
  const queryKey = "tab";
  const contextStore = useContextStore();
  const isShowingTotalDiscountSummary =
    contextStore?.me?.is_gc || contextStore?.me?.is_system_admin;
  const isShowingTotalProgrespayFeeSummary = contextStore?.me?.is_system_admin;
  const isShowingEligibleInvoices = Boolean(
    contextStore?.me && !contextStore?.me.is_gc
  );
  const eligibleInvoicesQuery = useQueryEligibleInvoicesNonAdmin({
    enabled: isShowingEligibleInvoices,
    staleTime: 1000 * 60 * 5, // 5 min stale time
  });
  const [tabValue, setTabValue] = useState<string>(
    searchParams.get(queryKey) ? searchParams.get(queryKey)! : "requested"
  );
  useEffect(() => {
    const query = new URLSearchParams(searchParams);
    query.set(queryKey, tabValue);
    setSearchParams(query);
  }, [tabValue, setSearchParams, queryKey, searchParams]);
  const isEmbedded = !!contextStore?.isEmbedded;
  const { clearAll } = useInvoiceActionStore();
  useEffect(() => {
    clearAll();
  }, [tabValue, clearAll]);

  return (
    <div className="mt-8">
      <div className="flex flex-row flex-wrap justify-between gap-8">
        {isShowingEligibleInvoices ? (
          <div
            className="flex-1 flex-grow cursor-pointer"
            onClick={() => setTabValue("eligible")}
          >
            <GraphicSummary
              heading={eligibleInvoicesQuery.data?.length ? `Eligible (${eligibleInvoicesQuery.data?.length})` : `Eligible`}
              content={
                eligibleInvoicesQuery.data?.length
                  ? eligibleInvoicesQuery.data.reduce(
                      (sum, el) => sum + Number(el.amountIncludingGST),
                      0
                    )
                  : 0
              }
              isCurrency
              icon={<Receipt />}
              iconColour="pink"
              isHighlighted={tabValue === "eligible"}
              isLoading={eligibleInvoicesQuery.isLoading}
            ></GraphicSummary>
          </div>
        ) : null}

        <div
          className="flex-1 flex-grow cursor-pointer"
          onClick={() => setTabValue("requested")}
        >
          <GraphicSummary
            heading={summary?.requested?.count ? `In Progress (${summary?.requested?.count})` : `In Progress`}
            content={summary?.requested?.total_invoice_amount || 0}
            icon={<Stamp />}
            isCurrency
            iconColour="yellow"
            isHighlighted={tabValue === "requested"}
            isLoading={summary === undefined}
          ></GraphicSummary>
        </div>

        <div
          className="flex-1 flex-grow cursor-pointer"
          onClick={() => setTabValue("approved")}
        >
          <GraphicSummary
            heading={summary?.approved?.count ? `Completed (${summary?.approved?.count})`:`Completed`}
            content={summary?.approved?.total_invoice_amount || 0}
            icon={<Hourglass />}
            isCurrency
            iconColour="green"
            isHighlighted={tabValue === "approved"}
            isLoading={summary === undefined}
          ></GraphicSummary>
        </div>

        {isShowingTotalDiscountSummary ? (
          <div className="flex-1 flex-grow">
            <GraphicSummary
              heading={`Discount Secured`}
              content={summary?.approved?.total_discount_amount || 0}
              isCurrency
              icon={<PiggyBank />}
              iconColour="blue"
              isLoading={summary === undefined}
            ></GraphicSummary>
          </div>
        ) : null}

        {isShowingTotalProgrespayFeeSummary ? (
          <div className="flex-1 flex-grow">
            <GraphicSummary
              heading={`ProgressPay Fee`}
              content={summary?.approved?.total_progresspay_fee || 0}
              isCurrency
              icon={<DollarSign />}
              iconColour="pink"
              isLoading={summary === undefined}
            ></GraphicSummary>
          </div>
        ) : null}

        <div className="flex-auto w-full">
          <ChangeTabValueContext.Provider value={setTabValue}>
            <Tabs
              value={tabValue}
              className="w-full"
              onValueChange={(e) => {
                setTabValue(e);
              }}
            >
              <TabsList className="px-1 bg-gray-200">
                {isShowingEligibleInvoices ? (
                  <TabsTrigger value="eligible">Eligible</TabsTrigger>
                ) : null}
                <TabsTrigger value="requested">In Progress</TabsTrigger>
                <TabsTrigger value="approved">Completed</TabsTrigger>
              </TabsList>
              {isShowingEligibleInvoices ? (
                <TabsContent value="eligible">
                  <Section
                    noBorder={isEmbedded}
                    noPadding={isEmbedded}
                    children={<EligibleInvoices />}
                  ></Section>
                </TabsContent>
              ) : null}
              <TabsContent value="requested">
                <Section
                  noBorder={isEmbedded}
                  noPadding={isEmbedded}
                  children={<RequestedInvoices requestedOnly applyDashboardFilter/>}
                ></Section>
              </TabsContent>
              <TabsContent value="approved">
                <Section
                  noBorder={isEmbedded}
                  noPadding={isEmbedded}
                  children={<RequestedInvoices approvedOnly applyDashboardFilter/>}
                ></Section>
              </TabsContent>
            </Tabs>
          </ChangeTabValueContext.Provider>
        </div>
      </div>
    </div>
  );
};
