"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvoiceStatusTypes = void 0;
var InvoiceStatusTypes;
(function (InvoiceStatusTypes) {
    InvoiceStatusTypes["CREATED"] = "CREATED";
    InvoiceStatusTypes["ELIGIBLE"] = "ELIGIBLE";
    InvoiceStatusTypes["INELIGIBLE"] = "INELIGIBLE";
    InvoiceStatusTypes["INTENDED"] = "INTENDED";
    InvoiceStatusTypes["OFFERED"] = "OFFERED";
    InvoiceStatusTypes["AVAILABLE"] = "AVAILABLE";
    InvoiceStatusTypes["REQUESTED"] = "REQUESTED";
    InvoiceStatusTypes["APPROVED"] = "APPROVED";
    InvoiceStatusTypes["REJECTED"] = "REJECTED";
    InvoiceStatusTypes["CANCELLED"] = "CANCELLED";
    InvoiceStatusTypes["PAID"] = "PAID";
})(InvoiceStatusTypes || (exports.InvoiceStatusTypes = InvoiceStatusTypes = {}));
