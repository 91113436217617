import { CloudArrowDownIcon } from '@heroicons/react/24/outline';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { CSVLink } from "react-csv";

interface TableCSVExportProps<T> {
  records: T[],
  columns: ColumnsType<T>
}

export const TableCSVExport: <T,>(props:TableCSVExportProps<T>) => JSX.Element | null = ({
  records,
  columns
}) => {
  let csvData = [columns.map(c => String(c.title))];
  for (const record of records) {
    csvData.push(columns.map(
      (c, index) => {
        const dataIndex = (c as ColumnType<typeof record>).dataIndex;
        let value = null;
        if (dataIndex) {
          value = record;
          for (const di of dataIndex as string[]) {
            value = (value as Record<string,any>)[di];
          }
        }
        if (c.render) {
          return c.render(value, record, index) as string;
        }
        return String(value);
      })
    )
  }

  return (
    <CSVLink data={csvData} title="CSV Export" filename={"earlyPaymentRequests.csv"}>
      <CloudArrowDownIcon className='w-6 h-6'/>
    </CSVLink>
  );
}
