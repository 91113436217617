import {
  TypeCurrency
} from "./TypeCurrency";
import {
  TypeInput
} from "./TypeInput";
import {
  TypeRadio
} from "./TypeRadio";
import {
  TypeCheckbox
} from "./TypeCheckbox"

export const Filter = {
  TypeCurrency,
  TypeInput,
  TypeRadio,
  TypeCheckbox,
}