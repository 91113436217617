"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityTypeSchema = void 0;
const zod_1 = require("zod");
const activityEntityType_1 = require("../enums/activityEntityType");
const activityVerb_1 = require("../enums/activityVerb");
const EntityTypeSchema = zod_1.z.nativeEnum(activityEntityType_1.ActivityEntityType);
const VerbSchema = zod_1.z.nativeEnum(activityVerb_1.ActivityVerb);
const MandrillEventSchema = zod_1.z.object({
    id: zod_1.z.string(),
    _id: zod_1.z.string(),
    created_at: zod_1.z.string(),
    event: zod_1.z.string().nullable(),
    msg: zod_1.z.any(),
    ts: zod_1.z.number().nullable(),
    url: zod_1.z.string().nullable(),
    user_agent: zod_1.z.string().nullable(),
    user_agent_parsed: zod_1.z.string().nullable(),
    type: zod_1.z.string().nullable(),
    action: zod_1.z.string().nullable(),
    entry: zod_1.z.any(),
    reject: zod_1.z.any(),
    diag: zod_1.z.string().nullable(),
});
exports.ActivityTypeSchema = zod_1.z.object({
    id: zod_1.z.string(),
    user_id: zod_1.z.string(),
    entity_id: zod_1.z.any(),
    entity_type: EntityTypeSchema,
    verb: VerbSchema,
    ip_address: zod_1.z.string().nullable().optional(),
    metadata: zod_1.z.any().nullable().optional(),
    created_at: zod_1.z.string(),
    mandrill_events: zod_1.z.array(MandrillEventSchema).optional(),
});
