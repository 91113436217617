import { UserType, UsersResponse } from "@progresspay-next/dtos";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  App,
  Drawer,
  Popconfirm,
  Space,
  Table,
  Tag
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import {
  PageTitleSlotType,
  useInternalLayoutContext
} from "../../components/layouts/InternalLayout";
import { PageHeader } from "../../components/PageHeader";
import { getApi } from "../../utils/api";
import { useColumnSearchProps } from "../../utils/tables";
import { UsersForm } from "./UsersForm";

interface UsersListProps {}

type DataType = UserType;

type DataIndex = keyof DataType;

const routes = [
  {
    path: "/home",
    breadcrumbName: "Home",
  },
  {
    path: "/admin/users",
    breadcrumbName: "Users",
  },
];

export const UsersListTable = ({
  loading,
  users,
}: {
  loading: boolean;
  users: UsersResponse | undefined;
}) => {
  const { message } = App.useApp();
  const [editTargetId, setEditTargetId] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const api = getApi();
  const queryClient = useQueryClient();
  const [previousUrl, setPreviousUrl] = useState<string | null>(null);

  const disableUser = useMutation({
    mutationFn: (id: string) => {
      return api.updateUsers({
        id,
        disabled_at: new Date().toISOString(),
      });
    },
    onSuccess: () => {
      message.success("The user has been disabled successfully");
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });

  const enableUser = useMutation({
    mutationFn: (id: string) => {
      return api.updateUsers({
        id,
        disabled_at: null,
      });
    },
    onSuccess: () => {
      message.success("The user has been enabled successfully");
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });

  const handleCreateNew = () => {
    setEditTargetId(null);
    setIsOpen(true);
  };
  const handleDrawerClose = () => {
    setIsOpen(false);
  };
  const handleEditExisting = (id: string) => () => {
    setEditTargetId(id);
    setIsOpen(true);
  };
  const handleDisableUser = (id: string) => () => {
    disableUser.mutate(id);
  };

  const columns: ColumnsType<DataType> = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      sorter: (a, b) =>
        String(a.first_name).localeCompare(String(b.first_name)),
      sortDirections: ["descend"],
      ...useColumnSearchProps<DataType>("first_name"),
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      sorter: (a, b) => String(a.last_name).localeCompare(String(b.last_name)),
      sortDirections: ["descend"],
      ...useColumnSearchProps<DataType>("last_name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => String(a.email).localeCompare(String(b.email)),
      sortDirections: ["descend"],
      ...useColumnSearchProps<DataType>("email"),
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      sorter: (a, b) => String(a.mobile).localeCompare(String(b.mobile)),
      sortDirections: ["descend"],
      ...useColumnSearchProps<DataType>("mobile"),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      sorter: (a, b) => String(a.phone).localeCompare(String(b.phone)),
      sortDirections: ["descend"],
      ...useColumnSearchProps<DataType>("phone"),
    },
    {
      title: "Organisation",
      dataIndex: ["organisation", "name"],
      key: "orgname",
      sorter: (a, b) =>
        (a.organisation?.name ? a.organisation.name : "").localeCompare(
          b.organisation?.name ? b.organisation.name : ""
        ),
      sortDirections: ["descend"],
    },
    {
      title: "Org Admin",
      key: "is_org_admin",
      dataIndex: ["is_org_admin"],
      render: (_, { is_org_admin }) => (
        <Tag color={is_org_admin ? "green" : "red"}>
          {is_org_admin ? "Y" : "N"}
        </Tag>
      ),
      filters: [
        {
          text: "Y",
          value: "Y",
        },
        {
          text: "N",
          value: "N",
        },
      ],
      onFilter: (value, record) =>
        value == "Y" ? record.is_org_admin : !record.is_org_admin,
    },
    {
      title: "Fin Admin",
      key: "is_fin_admin",
      dataIndex: ["is_org_admin"],
      render: (_, { is_fin_admin }) => (
        <Tag color={is_fin_admin ? "green" : "red"}>
          {is_fin_admin ? "Y" : "N"}
        </Tag>
      ),
      filters: [
        {
          text: "Y",
          value: "Y",
        },
        {
          text: "N",
          value: "N",
        },
      ],
      onFilter: (value, record) =>
        value == "Y" ? record.is_fin_admin : !record.is_fin_admin,
    },
    {
      title: "Disabled",
      key: "disabled_at",
      dataIndex: "disabled_at",
      render: (_, { disabled_at }) => (
        <Tag color={disabled_at ? "red" : "green"}>
          {disabled_at ? "Y" : "N"}
        </Tag>
      ),
      filters: [
        {
          text: "Y",
          value: "Y",
        },
        {
          text: "N",
          value: "N",
        },
      ],
      onFilter: (value, record) =>
        value == "Y" ? !!record.disabled_at : !record.disabled_at,
    },
    {
      title: "PAS ID",
      key: "pasId",
      dataIndex: "pas_id",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) =>
        record.disabled_at ? null : (
          <Space size="middle">
            <a onClick={handleEditExisting(record.id)}>Edit</a>
            <Popconfirm
              title={`Are you sure to disable this user?`}
              onConfirm={handleDisableUser(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <a href="#">Disable</a>
            </Popconfirm>
          </Space>
        ),
    },
  ];
  return (
    <>
      <Table
        size="small"
        columns={columns}
        loading={loading}
        rowKey="id"
        dataSource={users}
        pagination={{
          pageSize: 25,
        }}
      />
      <Drawer
        title={editTargetId ? "Edit" : "Create"}
        placement="right"
        size={"large"}
        open={isOpen}
        maskClosable={false}
        onClose={handleDrawerClose}
      >
        {isOpen ? (
          <UsersForm
            context="drawer"
            id={editTargetId}
            onActionSuccess={() => {
              setIsOpen(false);
            }}
          />
        ) : null}
      </Drawer>
    </>
  );
};

export const UsersList: (props: UsersListProps) => JSX.Element | null = () => {
const setPageTitleSlotProps = useInternalLayoutContext()?.setPageTitleSlotProps;
  useEffect(() => {
    setPageTitleSlotProps && setPageTitleSlotProps({
      type: PageTitleSlotType.SimplePageTitle,
      props: {
        title: "Users",
      },
    });
  }, [setPageTitleSlotProps]);
  const api = getApi();
  const users = useQuery({
    queryKey: ["users"],
    queryFn: api.findUsers,
  });
  return (
    <>
      <PageHeader routes={routes}></PageHeader>
      <UsersListTable users={users.data} loading={users.isLoading} />
    </>
  );
};
