import { cn } from "@/utils/ui";

export const InvoiceStatusTag = (props: { status: string }) => {
  return (
    <span
      className={cn(
        `leading-none inline-block rounded-lg border border-gray-600 bg-gray-100 px-4 py-[6px] capitalize text-gray-600`,
        props.status == "requested" &&
          `border-yellow-600 bg-yellow-100 text-yellow-600`,
        props.status == "intended" &&
          `border-purple-600 bg-purple-100 text-purple-600`,
        props.status == "approved" &&
          `border-green-600 bg-green-100 text-green-600`,
        props.status == "declined" && `border-red-600 bg-red-100 text-red-600`,
        props.status == "ineligible" &&
          `border-gray-600 bg-gray-100 text-gray-600`,
        props.status == "paid" && `border-blue-600 bg-blue-100 text-blue-600`
      )}
    >
      {props.status}
    </span>
  );
};
