import { ActivitiesResponse, InvoiceResponse, InvoicesSummaryResponse, InvoicesResponse, EligibleInvoicesResponse, EligibleInvoiceResponse, DiscountType, RequestEarlyPaymentOnEligibleInvoiceRequest, InvoicesSummaryRequest } from '@progresspay-next/dtos';
import { CreateInvoiceRequest, UpdateInvoicesRequest, InvoicesRequest } from '@progresspay-next/dtos';
import Request from './_request';
const { _get, _post, _put, _delete } = Request;

export const findInvoices = (query?: InvoicesRequest) => {
  return _get<InvoicesResponse>(
    `/admin/invoices`,
    query
  )
}

export const findInvoicesNonAdmin = (query?: InvoicesRequest) => {
  return _get<InvoicesResponse>(
    `/invoices`,
    query
  )
}

export const invoicesSummaryNonAdmin = (query: InvoicesSummaryRequest) => {
  return _get<InvoicesSummaryResponse>(
    `/invoices/summary`,
    query,
  )
}

export const findInvoice = (id: string|number) => {
  return _get<InvoicesResponse>(
    `/admin/invoices/${id}`
  )
}

export const findInvoiceActivities = (id: string) => {
  return _get<ActivitiesResponse>(
    `/invoices/${id}/activities`
  )
}

export const findInvoiceNonAdmin = (id: string) => {
  return _get<InvoiceResponse>(
    `/invoices/${id}`
  )
}

export const importInvoice = (importInvoiceDTO: CreateInvoiceRequest) => {
  return _post<InvoiceResponse>(
    `/admin/invoices/import`,
    importInvoiceDTO
  )
}

export const updateInvoice = (updateInvoiceDTO: UpdateInvoicesRequest) => {
  return _put<InvoiceResponse>(
    `/admin/invoices/${updateInvoiceDTO.id}`,
    updateInvoiceDTO
  )
}

export const offerEarlyPayment = (id: string) => {
  return _put<InvoiceResponse>(
    `/admin/invoices/${id}/offer`,
    {}
  )
}

export const requestEarlyPayment = (updateEarlyPaymentDTO: UpdateInvoicesRequest) => {
  return _put<InvoiceResponse>(
    `/invoices/${updateEarlyPaymentDTO.id}/request`,
    updateEarlyPaymentDTO
  )
}

export const updateEarlyPayment = (updateEarlyPaymentDTO: UpdateInvoicesRequest) => {
  return _put<InvoiceResponse>(
    `/invoices/${updateEarlyPaymentDTO.id}`,
    updateEarlyPaymentDTO
  )
}

export const confirmEarlyPayment = (updateEarlyPaymentDTO: UpdateInvoicesRequest) => {
  return _put<InvoiceResponse>(
    `/invoices/${updateEarlyPaymentDTO.id}/confirm`,
    updateEarlyPaymentDTO
  )
}


export const approveEarlyPayment = (updateEarlyPaymentDTO: UpdateInvoicesRequest) => {
  return _put<InvoiceResponse>(
    `/invoices/${updateEarlyPaymentDTO.id}/approve`,
    updateEarlyPaymentDTO
  )
}

export const cancelEarlyPayment = (updateEarlyPaymentDTO: UpdateInvoicesRequest) => {
  return _put<InvoiceResponse>(
    `/invoices/${updateEarlyPaymentDTO.id}/cancel`,
    updateEarlyPaymentDTO
  )
}

export const rejectEarlyPayment = (updateEarlyPaymentDTO: UpdateInvoicesRequest) => {
  return _put<InvoiceResponse>(
    `/invoices/${updateEarlyPaymentDTO.id}/reject`,
    updateEarlyPaymentDTO
  )
}

export const expireInvoice = (id: string) => {
  return _put<InvoiceResponse>(
    `/admin/invoices/${id}/expire`, { id }
  )
}

// integrations

export const updatePaymentDate = (id: string) => {
  return _put<InvoiceResponse>(
    `/integrations/jobpac/invoices/${id}/update-payment-date`, { id }
  )
}

export const createAPTransaction = (dto: {id: string; invoiceOrCreditFlag: string;}) => {
  return _put<InvoiceResponse>(
    `/integrations/jobpac/invoices/${dto.id}/create-ap-transaction`, dto
  )
}

export const createNewPMWorksheet = (id: string) => {
  return _put<InvoiceResponse>(
    `/integrations/jobpac/invoices/${id}/create-pm-worksheet`, { id }
  )
}

export const findEligibleInvoicesNonAdmin = () => {
  return _get<EligibleInvoicesResponse>(
    `/integrations/eligible`
  )
}

export const requestEarlyPaymentOnEligibleInvoice = (dto: RequestEarlyPaymentOnEligibleInvoiceRequest) => {
  return _post<any>(
    `/integrations/eligible`, dto
  )
}

export const findEligibleInvoiceNonAdmin = (id: string) => {
  return _get<EligibleInvoiceResponse>(
    `/integrations/eligible/${id}`
  )
}
