"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrganisationTypeSchema = exports.DiscountsWidgetDisplayType = void 0;
const zod_1 = require("zod");
const address_type_1 = require("./address.type");
const integration_type_1 = require("./integration.type");
var DiscountsWidgetDisplayType;
(function (DiscountsWidgetDisplayType) {
    DiscountsWidgetDisplayType["FIXED_RATE"] = "FIXED_RATE";
    DiscountsWidgetDisplayType["FLEXIBLE_RATE"] = "FLEXIBLE_RATE";
    DiscountsWidgetDisplayType["PRO_RATA_RATE"] = "PRO_RATA_RATE";
})(DiscountsWidgetDisplayType || (exports.DiscountsWidgetDisplayType = DiscountsWidgetDisplayType = {}));
const DiscountsWidgetDisplaySettingSchema = zod_1.z.object({
    payrun_offset_days: zod_1.z.number().nullish(),
    payrun_dropdown_type: zod_1.z.union([zod_1.z.literal(`list`), zod_1.z.literal(`calendar`)]).nullish()
}).nullish();
const DiscountSchema = zod_1.z.object({
    payrun_date: zod_1.z.string(),
    discount: zod_1.z.number().nullable(),
});
const DiscountsSchema = zod_1.z.object({
    payment_runs: zod_1.z.array(DiscountSchema).nullish(),
    dynamic_discounts: zod_1.z.array(zod_1.z.object({
        number_of_days_early: zod_1.z.number(),
        discount: zod_1.z.number(),
    })).nullish(),
    display_type: zod_1.z.nativeEnum(DiscountsWidgetDisplayType), // Controls the layout
    display_settings: DiscountsWidgetDisplaySettingSchema.nullish() // Controls the customisation
});
const PASIntegrationSchema = zod_1.z.object({
    type: zod_1.z.enum([integration_type_1.IntegrationType.GCPAY, integration_type_1.IntegrationType.PAYAPPS]),
    iframe: zod_1.z.boolean().optional(),
});
const ERPIntegrationSchema = zod_1.z.object({
    type: zod_1.z.enum([integration_type_1.IntegrationType.JOBPAC, integration_type_1.IntegrationType.VISTA]),
    url: zod_1.z.string().optional(),
    username: zod_1.z.string().optional(),
    password: zod_1.z.string().optional(),
    environment: zod_1.z.string().optional(),
    dynamic: zod_1.z.array(zod_1.z.object({
        id: zod_1.z.string(),
        label: zod_1.z.string(),
        value: zod_1.z.any(),
        type: zod_1.z.enum(['text'])
    })).optional(),
});
const IntegrationSchema = zod_1.z.object({
    pas: PASIntegrationSchema.nullish(),
    erp: ERPIntegrationSchema.nullish(),
});
const SettingsSchema = zod_1.z.object({
    pp_fee: zod_1.z.number().optional(),
    facility_limit: zod_1.z.number().optional(),
});
const MetadataSchema = zod_1.z.object({
    data: zod_1.z.any()
});
const baseOrganisationTypeSchema = zod_1.z.object({
    id: zod_1.z.string(),
    erp_id: zod_1.z.string().nullable(),
    erp_id_2: zod_1.z.string().nullable(),
    pas_id: zod_1.z.string().nullable(),
    name: zod_1.z.string().nullable(),
    abn: zod_1.z.string().nullable(),
    acn: zod_1.z.string().nullable(),
    discounts: DiscountsSchema.nullable(),
    address: address_type_1.AddressSchema.nullable(),
    integrations: IntegrationSchema.nullable().optional(),
    created_at: zod_1.z.string().nullable(),
    updated_at: zod_1.z.string().nullable(),
    parent_id: zod_1.z.string().nullable(),
    logo_attachment_id: zod_1.z.string().nullable(),
    settings: SettingsSchema.nullable(),
    type: zod_1.z.string(),
    erp_system: zod_1.z.string().nullable(),
    pas_system: zod_1.z.string().nullable(),
    metadata: MetadataSchema.nullable(),
});
exports.OrganisationTypeSchema = baseOrganisationTypeSchema.extend({
    parent: baseOrganisationTypeSchema.nullable().optional(),
    children: baseOrganisationTypeSchema.array().nullable().optional()
});
