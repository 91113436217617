import { Checkbox } from "antd";

export const TableConfig = ({
  allColumns,
  hidingColumns,
  toggleColumn,
  alwaysShowingColumns,
}: {
  allColumns: string[];
  alwaysShowingColumns: string[];
  hidingColumns: string[];
  toggleColumn: (col: string) => void;
}) => {
  return (
    <div>
      {allColumns.map((c) => {
        const isChecked = !hidingColumns.includes(c);
        return (
          <div key={c}>
            <Checkbox
              checked={isChecked}
              disabled={alwaysShowingColumns.includes(c)}
              onChange={() => {
                toggleColumn(c);
              }}
            >
              {c}
            </Checkbox>
          </div>
        );
      })}
    </div>
  );
};
