import Request from './_request';
import { ForgotPasswordResponse, LoginResponse, ResetPasswordResponse, ValidateInviteTokenRequest, ValidateInviteTokenResponse } from '@progresspay-next/dtos';
import { ForgotPasswordRequest, LoginRequest, ResetPasswordRequest } from '@progresspay-next/dtos';
const { _get, _post, _put, _delete } = Request;


export const authUser = (authUserDTO: LoginRequest) => {
  return _put<LoginResponse>(
    `/auth/login`,
    authUserDTO
  );
};

// Initiate reset password process
export const forgotPassword = (forgotPasswordDTO:ForgotPasswordRequest) => {
  return _put<ForgotPasswordResponse>(
    `/auth/reset`,
    forgotPasswordDTO
  )
}
// Reset password
export const resetPassword = (resetPasswordDTO:ResetPasswordRequest) => {
  return _put<ResetPasswordResponse>(
    `/auth/reset-password`,
    resetPasswordDTO
  )
}
// Decode invite user token
export const validateInviteToken = (token:string): Promise<ValidateInviteTokenResponse> => {
  const validateInviteTokenRequest: ValidateInviteTokenRequest = {
    token,
  }
  return _put<ValidateInviteTokenResponse>(`auth/validate-invite`, validateInviteTokenRequest)
}
