"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectTypeSchema = void 0;
const zod_1 = require("zod");
const address_type_1 = require("./address.type");
const integration_type_1 = require("./integration.type");
exports.ProjectTypeSchema = zod_1.z.object({
    id: zod_1.z.string(),
    name: zod_1.z.string().nullable(),
    erp_id: zod_1.z.string().nullable(),
    erp_id_2: zod_1.z.string().nullable(),
    pas_id: zod_1.z.union([zod_1.z.string(), zod_1.z.number()]).nullable(),
    organisation_id: zod_1.z.string().nullable(),
    source: zod_1.z.nativeEnum(integration_type_1.IntegrationType).nullable(),
    metadata: zod_1.z.any().nullable(),
    address: address_type_1.AddressSchema.nullable(),
    created_at: zod_1.z.string().nullable(),
    updated_at: zod_1.z.string().nullable(),
    disabled_at: zod_1.z.string().nullable(),
});
