import { FilterOutlined } from "@ant-design/icons"
import { Button, Popover } from "antd"
import { FilterData, Filters } from "."

type Props = {
  filters: FilterData[],
}
export const ButtonWithDropdown = ({
  filters,
}: Props) => {
  return (
    <Popover
      placement="bottomLeft"
      content={
        <div className="min-w-[550px]">
          <Filters.DropdownPanel filters={filters}/>
        </div>
      }
      title="Filters"
      trigger="click"
      >
      <Button icon={<FilterOutlined />}>
        <div className="ml-2 inline-flex flex-row flex-nowrap items-center">
          <span>Filter</span>
          <span className="ml-2 flex h-4 w-4 items-center justify-center rounded-full bg-black text-xs text-white">
            {filters.filter(f => f.value).length}
          </span>
        </div>
      </Button>
    </Popover>
  )
}