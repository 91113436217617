import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { MeResponse } from "@progresspay-next/dtos";
interface ContextState {
  isEmbedded: boolean;
  setIsEmbedded: (isEmbedded: boolean) => void;
  
  me: MeResponse|null;
  setMe: (newMe: MeResponse) => void;
}
export const useContextStore = create<ContextState>()(
  devtools(
    (set, get) => ({
      isEmbedded: false,
      setIsEmbedded: (isEmbedded: boolean) => {
        if (get().isEmbedded != isEmbedded) {
          set((state) => ({ isEmbedded }))
        }
      },
      me: null,
      setMe: (newMe: MeResponse) => {
        if (JSON.stringify(get().me) != JSON.stringify(newMe)) {
          set(state => ({me: newMe}))
        }
      }
    })
  )
);
