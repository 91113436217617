import { CheckCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { ContractType, ImportExternalProjectRequest, ProjectType, ProjectsResponse } from "@progresspay-next/dtos";
import { useMutation, useQueries, useQueryClient } from "@tanstack/react-query";
import { App, Button, Col, Modal, Row, Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import _ from "lodash";
import { useEffect, useState } from "react";
import invariant from "tiny-invariant";
import { getApi } from "../../utils/api";
import {
  queryKey,
  useQueryContracts,
  useQueryJobpacProjects,
  useQueryOrganisationById,
  useQueryProjectsNonAdmin,
} from "../../utils/query";
import { useColumnSearchProps } from "../../utils/tables";
import { ContractsTableFactory } from "../contracts/ContractsList";

interface OrganisationsFormProjectsTabProps {
  id: number | string;
}


const tableRowKey = (record: ProjectType | ContractType) =>
  `${record.id} - ${record.erp_id}`;

const ExternalResourcesModal = ({
    title = '',
    isModalOpen,
    modalContent,
    afterClose,
  }: {
    title?: string;
    isModalOpen:boolean;
    modalContent:JSX.Element | null;
    afterClose: () => void;
  }) => {
  return (
    <Modal title={title} open={isModalOpen} onCancel={afterClose} width={1000} footer={null}>
      {modalContent}
    </Modal>
  )
}

export const OrganisationsFormProjectsTab: (
  props: OrganisationsFormProjectsTabProps
) => JSX.Element | null = ({ id }) => {
  const { message } = App.useApp();
  const api = getApi();

  const [expandedRowKeys, setExpandedRowKey] = useState<string[]>([]);
  const queryClient = useQueryClient();

  const organisationQuery = useQueryOrganisationById(id);

  const organisationProjectsQuery = useQueryProjectsNonAdmin({
    organisation_id: String(id),
  }, {
  })

  /**
   * GC and SC have different ways to retrieve projects
   * GC:
   *    The projects is a combination of internal projects and external projects
   * SC:
   *    The projects is from all the "project" from contracts that belongs to this SC
   */
  const isSC =
    organisationQuery.isSuccess && organisationQuery.data.type == "SC";
  const contractsBySC = useQueryContracts(
    {
      sc_organisation_id: (organisationQuery.data?.id),
    },
    {
      enabled: isSC,
    }
  );
  const scContracts = contractsBySC.data || [];

  const isGC =
    organisationQuery.isSuccess && organisationQuery.data.type == "GC";
  const orgProjects = organisationProjectsQuery.data || [];
  // externalProjects may have projects already imported (those id is not null)
  const [externalProjects, setExternalProjects] = useState<ProjectType[]>([]);
  const [externalContracts, setExternalContracts] = useState<
    ContractType[]
  >([]);
  const [internalContracts, setInternalContracts] = useState<
    ContractType[]
  >([]);
  const [projectScanningId, setProjectScanningId] = useState<string|number|null>(null);
  const selectedOrgProjects = orgProjects.filter(
    (pro) => pro.id == projectScanningId
  );
  const [isFetchingExternalProjects, setIsFetchingExternalProjects] =
    useState<boolean>(false);
  const [recentlyImportedContractIds, setRecentlyImportedContractIds] =
    useState<(string|number)[]>([]);
  const [recentImportedProjectIds, setRecentlyImportedProjectIds] = useState<(string|number)[]>([]);
  const [isImportingContractIds, setIsImportingContractIds] = useState<(string|number)[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isContractsModalOpen, setIsContractsModalOpen] = useState<boolean>(false);
  const [contractModalTargetProject, setContractModalTargetProject] = useState<ProjectType|null>(null);

  const externalProjectsByOrgQuery = useQueryJobpacProjects(
    organisationQuery.data?.erp_id as string,
    {
      enabled: Boolean(
        isGC && isFetchingExternalProjects && organisationQuery.data.erp_id
      ),
    }
  );
  useEffect(() => {
    const data = externalProjectsByOrgQuery.data;
    if (data) {
      if (data.length) {
        setExternalProjects(data);
      } else {
        message.warning(
          `No external projects has been found under this organisation`
        );
      }
      setIsFetchingExternalProjects(false);

      if (data.length == 0) {
        message.warning(`No external projects could be found.`);
      } else {
        if (data.filter((project: ProjectType) => !project.id).length == 0) {
          message.warning(`All external projects have been imported.`)
        } else {
          setIsModalOpen(true);
        }
      }
    }
  }, [externalProjectsByOrgQuery.data, message])

  const contractsForOrgQuery = useQueryContracts(
    { organisation_id: String(id) },
    {
      enabled: isGC,
    }
  );
  useEffect(() => {
    const data = contractsForOrgQuery.data;
    if (data) {
      setInternalContracts([
        ...data,
        ...internalContracts.filter(
          (ic) =>
            data.findIndex(
              (c: ContractType) => c.id == ic.id
            ) === -1
        ),
      ]);
    }
  }, [contractsForOrgQuery.data, message]) // eslint-disable-line react-hooks/exhaustive-deps

  const importProject = useMutation(
    {
      mutationFn: (payload: ProjectType) => {
        return api.importOrgProject(payload as ImportExternalProjectRequest);
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries({ queryKey: ["organisations"]});
        queryClient.invalidateQueries({ queryKey: ["organisation", { id: id }]});
        queryClient.invalidateQueries({ queryKey: ["jobpacProjects"]});
        message.success("The project has been imported successfully!");

        const newRecentImportedProjectIds = [...recentImportedProjectIds];
        newRecentImportedProjectIds.push(data.erp_id as string);
        setRecentlyImportedProjectIds(newRecentImportedProjectIds);

        // Relace project in the external container
        const newExternalProjects = [...externalProjects];
        newExternalProjects.splice(newExternalProjects.findIndex(ep => ep.erp_id == data.erp_id), 1, data);
        setExternalProjects(
          newExternalProjects
        );
      },
    }
  );

  const importContract = useMutation(
    {
      mutationFn: (payload: ContractType) => {
        return api.importOrgContract(payload as any);
      },
      onSuccess: (importedContract: ContractType) => {
        queryClient.invalidateQueries({ queryKey: ["contracts"]});
        message.success("The contract has been imported successfully!");

        // Remove the one from external to internal
        const index = externalContracts.findIndex(ec => ec.erp_id == importedContract.erp_id)
        if (index != -1) {
          const newExternalContracts = [...externalContracts];
          newExternalContracts.splice(index, 1, importedContract);
          setExternalContracts(
            newExternalContracts
          );
        }

        const recentIds = new Set(recentlyImportedContractIds);
        recentIds.add(importedContract.erp_id as string);
        setRecentlyImportedContractIds([...recentIds]);
      },
      onError: (error: Error) => {
        message.error(error.message);
      },
      onMutate: (payload) => {
        const newIsImportingContractIds = [...isImportingContractIds];
        newIsImportingContractIds.push(payload.erp_id as string);
        setIsImportingContractIds(newIsImportingContractIds);
      },
      onSettled: (data, error, payload) => {
        const index = isImportingContractIds.findIndex((id) => id == payload.erp_id);
        if (index != -1) {
          const newIsImportingContractIds = [...isImportingContractIds];
          newIsImportingContractIds.splice(
            index,
            1
          );
          setIsImportingContractIds(newIsImportingContractIds);
        }
      }
    },
  
  );

  const handleImportProject = (dto: ProjectType) => {
    importProject.mutate(dto);
  };

  const contractsForProjects = useQueries({
    queries: selectedOrgProjects.map((pro) => {
      return {
        queryKey: queryKey.contracts({ project_id: pro.id }),
        queryFn: () => {
          invariant(
            organisationQuery.data?.erp_id,
            "Organisation should have an ERP ID"
          );
          invariant(
            pro.erp_id,
            "Selected project should have an ERP ID"
          );
          // Refetching both internal and external contracts here to get more updated result, although internal contracts were previously fetched within the organisation
          return Promise.all([
            api.getJobpacContracts(
              pro.source === "PAYAPPS" && pro.erp_id_2 ? pro.erp_id_2 : organisationQuery.data?.erp_id, // use workid if source is payapps
              pro.erp_id
            ),
            api.findOrgContracts({
              project_id: pro.id,
              organisation_id: String(id),
            }),
          ]);
        },
         onSuccess: (data: [ContractType[], ContractType[]]) => {
          const [externalResponse, internalResponse] = data;

          setExternalContracts([
            ...externalResponse,
            ...externalContracts.filter(
              (ec) =>
                !externalResponse.find((c) => c.erp_id == ec.erp_id)
            ),
          ]);

          setInternalContracts([
            ...internalResponse,
            ...internalContracts.filter(
              (ic) => !internalResponse.find((c) => c.id == ic.id)
            ),
          ]);

          if (externalResponse.length == 0) {
            message.warning(`No external contracts could be found.`);
          } else {
            if (externalResponse.filter(contract => !contract.id).length == 0) {
              message.warning(`All external contracts have been imported.`)
            } else {
              setIsContractsModalOpen(true);
            }
          }
          setContractModalTargetProject(pro);

          const rowKey = tableRowKey(pro);
          if (expandedRowKeys.indexOf(rowKey) == -1) {
            setExpandedRowKey([rowKey, ...expandedRowKeys]);
          }

          setProjectScanningId(null);
        },
      };
    }),
  });

  const handleScanContractsForProjectId = (projectId: string) => {
    queryClient.invalidateQueries({ queryKey: queryKey.contracts({ project_id: projectId })});
    setProjectScanningId(projectId);
  };

  const columns: ColumnsType<ProjectType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => String(a.id).localeCompare(String(b.id)),
    },
    {
      title: "ERP ID",
      dataIndex: "erp_id",
      key: "erp_id",
    },
    {
      title: "ERP ID 2",
      dataIndex: "erp_id_2",
      key: "erp_id_2",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => String(a.name).localeCompare(String(b.name)),
      sortDirections: ["descend"],
      ...useColumnSearchProps<ProjectType>("name"),
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
    },
    {
      title: "",
      key: "action",
      align: "right",
      render: (text, record) => {
        // No action for SC
        if (isSC) {
          return null;
        }
        return record.id ? (
          <>
            <Button
              shape='round'
              type="link"
              size="small"
              icon={<SearchOutlined />}
              onClick={() => {
                handleScanContractsForProjectId(record.id);
              }}
              loading={record.id == projectScanningId}
              disabled={!!projectScanningId}
            >
              Scan Contracts
            </Button>
            {
              recentImportedProjectIds.indexOf(record.erp_id as string) != -1 ? (
                <Tag icon={<CheckCircleOutlined />} color="success">
                  Just Imported
                </Tag>
              ) : null
            }
          </>
        ) : (
          <Button
            shape='round'
            size={"small"}
            type="primary"
            onClick={() => {
              handleImportProject(record);
            }}
          >
            Import
          </Button>
        );
      },
    },
  ];
  const columnsWithoutScanAction = () => {
    let newColumns = _.cloneDeep(columns);
    newColumns[3].render = (text, record) => {
      // No action for SC
      if (isSC) {
        return null;
      }
      return record.id ? (
        <>
          {
            recentImportedProjectIds.indexOf(record.erp_id as string) != -1 ? (
              <Tag icon={<CheckCircleOutlined />} color="success">
                Just Imported
              </Tag>
            ) : null
          }
        </>
      ) : (
        <Button
          shape='round'
          size={"small"}
          type="primary"
          onClick={() => {
            handleImportProject(record);
          }}
        >
          Import
        </Button>
      );
    };
    return newColumns;
  }

  const uniqueProjectsBySC = () => {
    const projects = scContracts
      .map((c) => c.project)
      .filter((p): p is ProjectType => !!p);
    return [...new Map(projects.map((p) => [p.id, p])).values()];
  };

  const getTableDataSource = () => {
    // For SC, the projects are coming from contracts -> projects
    if (isSC) {
      return uniqueProjectsBySC();
    }
    // For GC, the projects are the combination of external projects and the projects from the organisation endpoint
    if (isGC) {
      const rencentlyImported = externalProjects.filter(project => recentImportedProjectIds.indexOf(project.erp_id as string) != -1)
      return [...rencentlyImported, ...orgProjects.filter(
        pro => rencentlyImported.filter(ri => ri.id == pro.id).length == 0
      )];
    }
    return [];
  };


  const handleImportContract = (record: ContractType) => {
    const dto = {
      ...record,
      organisation_id: String(id),
    };
    importContract.mutate(dto);
  };

  const contractsActionRender = (record: ContractType) => {
    if (isGC) {
      return record.id ? (
        recentlyImportedContractIds.indexOf(record.erp_id as string) != -1 ? (
          <Tag icon={<CheckCircleOutlined />} color="success">
            Just Imported
          </Tag>
        ) : null
      ) : (
        <Button
          shape='round'
          loading={!!isImportingContractIds.find(id => id == record.erp_id)}
          size={"small"}
          type="primary"
          onClick={() => handleImportContract(record)}
        >
          Import
        </Button>
      );
    }
    return null;
  };

  const contractsDataSource = (record: ProjectType) => {
    let dataSource: ContractType[] = [];
    if (isGC) {
      const externalContractsForThisProject = externalContracts.filter(
        (ec) =>
          ec.project_id == record.id
      );
      const interalContractsForThisProject = internalContracts.filter(
        (ic) => ic.project_id == record.id &&
        recentlyImportedContractIds.indexOf(ic.erp_id as string) == -1 &&
        externalContractsForThisProject.findIndex(ec => ec.erp_id == ic.erp_id) == -1
      );
      dataSource = [
        ...externalContractsForThisProject,
        ...interalContractsForThisProject,
      ];
    } else if (isSC) {
      dataSource = scContracts.filter((scc) => scc.project_id == record.id);
    }
    return dataSource;
  }

  const externalContractsModalRender = (target:ProjectType) => {
    const all = contractsDataSource(target);
    
    const dataSource = all.filter(contract => !contract.id || recentlyImportedContractIds.indexOf(contract.erp_id as string) != -1 )
    return (
      <ContractsTableFactory
          contracts={dataSource}
          isLoading={false}
          actionColumnRender={contractsActionRender}
          tableExtraProps={{
            rowClassName: (contractRecord: ContractType) =>
              contractRecord.id
                ? "internalContractsRow"
                : "externalContractsRow",
            rowKey: tableRowKey,
          }}
        />
    )
  }

  const expandedRowRender = (record: ProjectType) => {
    const all = contractsDataSource(record);
    const dataSource = all.filter(c => !!c.id);

    return (
      <div className="table-expanded-container--indented">
        <ContractsTableFactory
          contracts={dataSource}
          isLoading={false}
          actionColumnRender={contractsActionRender}
          tableExtraProps={{
            rowClassName: (contractRecord: ContractType) =>
              contractRecord.id
                ? "internalContractsRow"
                : "externalContractsRow",
            rowKey: tableRowKey,
            title: () => <h4 style={{ fontWeight: "bold" }}>Contracts:</h4>,
          }}
        />
      </div>
    );
  };

  const rowExpandable = (record: ProjectType) => {
    return !!contractsDataSource(record).length;
  };

  const externalProjectsModalRender = () => {
    const externalDataSource = externalProjects.filter(project => !project.id || recentImportedProjectIds.indexOf(project.erp_id as string) != -1);
    return (
      <Table
        size="small"
        columns={columnsWithoutScanAction()}
        rowKey={tableRowKey}
        rowClassName={(record) => (record.id ? "internalRow" : "externalRow")}
        dataSource={externalDataSource}
        pagination={false}
      />
    )
  }

  return (
    <div>
      <Row justify="space-between">
        <Col></Col>
        <Col>
          {isGC ? (
            <Button
              shape='round'
              type="primary"
              loading={isFetchingExternalProjects}
              onClick={() => {
                setIsFetchingExternalProjects(true);
                queryClient.invalidateQueries({ queryKey: ["jobpacProjects"]});
              }}
              disabled={!organisationQuery.data.erp_id}
              title={`ERP ID for this organisation is missing. Maybe it's not an imported organisation?`}
            >
              Scan External Projects
            </Button>
          ) : null}
        </Col>
      </Row>
      <hr />
      <Table
        size="small"
        columns={columns}
        loading={organisationQuery.isLoading || isFetchingExternalProjects}
        rowKey={tableRowKey}
        rowClassName={(record) => (record.id ? "internalRow" : "externalRow")}
        dataSource={getTableDataSource()}
        pagination={false}
        expandable={{
          rowExpandable,
          expandedRowRender,
          expandedRowKeys,
          onExpand: (expanded, record) => {
            // Toggle local state
            const thisKey = tableRowKey(record);
            setExpandedRowKey(_.xor(expandedRowKeys, [thisKey]));
          },
        }}
      />

      <ExternalResourcesModal title={`External Projects for ${organisationQuery.data?.name}`} modalContent={ externalProjectsModalRender() } isModalOpen={isModalOpen} afterClose={()=> { setIsModalOpen(false);} }/>

      <ExternalResourcesModal title={`External Contracts for ${organisationQuery.data?.name}`} modalContent={ contractModalTargetProject ? externalContractsModalRender(contractModalTargetProject) : null } isModalOpen={isContractsModalOpen} afterClose={()=> { setIsContractsModalOpen(false);} }/>
    </div>
  );
};
