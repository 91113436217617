import { EarlyPaymentRequestHeaderRow, InvoiceEarlyPayment } from "@/pages/invoices/invoiceEarlyPayment";
import { queryKey } from "@/utils/query";
import { InvoiceResponse } from "@progresspay-next/dtos";
import { useQueryClient } from "@tanstack/react-query";
import { Drawer } from "antd";
import { ChevronRightIcon } from "lucide-react";
import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button } from "../ui/button";

export const DrawerContext = createContext<{
  setFooterNode: (node: React.ReactNode) => void;
}>({
  setFooterNode: () => {},
});

export const EarlyPaymentRequestDrawerWithButton = (props: {
  invoice: InvoiceResponse;
}) => {
  const [isInvoiceDrawerOpen, setIsInvoiceDrawerOpen] =
    useState<boolean>(false);
  const { invoice } = props;
  const queryClient = useQueryClient();
  const location = useLocation();
  const [footerNode, setFooterNode] = useState<React.ReactNode>(null);

  // Check if drawer should be open
  const searchParams = new URLSearchParams(location.search);
  const targetInvoiceId = searchParams.get("showEarlyPayment");
  useEffect(() => {
    if (targetInvoiceId == invoice.id) {
      setIsInvoiceDrawerOpen(true);
    }
  }, [invoice.id, targetInvoiceId]);

  const handleViewClick = () => {
    setIsInvoiceDrawerOpen(true);
    if (invoice.id)
      queryClient.invalidateQueries({ queryKey: queryKey.invoiceById(invoice.id)});
  };
  const handleDrawerClose = () => {
    setIsInvoiceDrawerOpen(false);
  };

  return (
    <>
      <Button
        variant={'ghost'}
        onClick={() => handleViewClick()}
      >
        <ChevronRightIcon className="h-4 w-4 stroke-[4]" />
      </Button>
      <Drawer
        title={<EarlyPaymentRequestHeaderRow invoice={invoice} />}
        maskClosable={false}
        width={"900px"}
        onClose={handleDrawerClose}
        open={isInvoiceDrawerOpen}
        footer={footerNode}
      >
        <DrawerContext.Provider value={{ setFooterNode }}>
          <InvoiceEarlyPayment invoice={invoice} />
        </DrawerContext.Provider>
      </Drawer>
    </>
  );
};
