import { useContextStore } from "@/stores/context";
import { CloudArrowDownIcon } from "@heroicons/react/24/outline";
import { InvoiceResponse, InvoiceType } from "@progresspay-next/dtos";
import { formatCurrency, invoiceHelper, momentjsFormat } from "@progresspay-next/shared";
import {
  AgGridEvent,
  ColDef,
  ColGroupDef,
  FilterChangedEvent,
  GetRowIdFunc,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { ChevronRightIcon, CogIcon, Download, MenuIcon } from "lucide-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Pills } from "../ag-grid-filters/Pills";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { EarlyPaymentRequestDrawerWithButton } from "./EarlyPaymentRequestDrawerWithButton";
import { InvoiceStatusTag } from "./InvoiceStatusTag";
import moment from "moment";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import { useRememberFilterModel } from "@/utils/ag-grid";
import { isOriginalDueDateApplicable } from "@progresspay-next/shared/src/utils/invoice.helper";
import { InvoicesSummaryChartModal } from "./InvoicesSummaryChartModal";

type IOnInvoicesEvent = (
  eventName: "IS_EXPIRED_INCLUDED_CHANGED",
  payload: boolean
) => void;
interface InvoicesTableProps {
  invoices: InvoiceType[];
  isGC: boolean;
  isSysAdmin: boolean;
  initialIsExpiredIncluded?: boolean;
  onInvoicesEvent?: IOnInvoicesEvent;
}

const findEntityOrganisationByInvoice = (invoice: InvoiceResponse) => {
  const childOrganisations = invoice.contract?.organisation?.children;
  const entityOrganisationId = invoice.contract?.project?.erp_id_2;
  if (Array.isArray(childOrganisations) && entityOrganisationId) {
    return childOrganisations.find(o => o.erp_id_2 == entityOrganisationId);
  }
  return null;
}

const TableMenu = ({
  initialIsExpiredIncluded,
  onInvoicesEvent,
}: {
  initialIsExpiredIncluded: boolean;
  onInvoicesEvent: IOnInvoicesEvent
}) => {
  const [isExpiredIncluded, setIsExpiredIncluded] = useState<string>(
    initialIsExpiredIncluded ? "1" : "0"
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost">
          <CogIcon />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>Expired Invoices</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuRadioGroup
          value={isExpiredIncluded}
          onValueChange={(v: any) => {
            setIsExpiredIncluded(v);
            onInvoicesEvent("IS_EXPIRED_INCLUDED_CHANGED", v === "1")
          }}
        >
          <DropdownMenuRadioItem value="0">Excluded</DropdownMenuRadioItem>
          <DropdownMenuRadioItem value="1">Included</DropdownMenuRadioItem>
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};


const ActionsCellRenderer = (props: ICellRendererParams) => {
  const record = props.data;
  const { isEmbedded } = useContextStore();
  return record.id ? (
    <>
      {isEmbedded ? (
        <Button variant={"ghost"}>
          <Link className="p-2" to={`/embed/invoices/${record.id}`}>
            <ChevronRightIcon className="h-4 w-4 stroke-[4]" />
          </Link>
        </Button>
      ) : (
        <EarlyPaymentRequestDrawerWithButton invoice={record} />
      )}
    </>
  ) : null;
};

const InvoiceStatusCellRenderer = (props: ICellRendererParams) => {
  return <InvoiceStatusTag status={props.data.invoice_status.toLowerCase()} />;
};
export const InvoicesTable = ({
  invoices,
  isGC,
  isSysAdmin,
  initialIsExpiredIncluded = false,
  onInvoicesEvent = () => {}
}: InvoicesTableProps) => {
  const agGridRef = useRef<AgGridReact>(null);
  const [agGridApi, setAgGridApi] = useState<GridApi>();
  const [rowData, setRowData] = useState(invoices);
  useEffect(() => setRowData(invoices), [invoices]);

  const gcIds = [...new Set(invoices.map((a) => a.contract?.organisation?.id))];

  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState<
    (ColDef<InvoiceType> | ColGroupDef<InvoiceType>)[]
  >([
    {
      field: "created_at",
      headerName: "Requested At",
      type: [`dateCol`, `withDateFilterCol`],
    },
    {
      field: "invoice_status",
      headerName: "Status",
      pinned: "left",
      type: ["withTextFilterCol"],
      cellRenderer: InvoiceStatusCellRenderer,
      comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
        const sortMap: Record<string, number> = {
          ELIGIBLE: 11,
          INELIGIBLE: 10,
          INTENDED: 20,
          AVAILABLE: 30,
          REQUESTED: 40,
          APPROVED: 50,
          REJECTED: 51,
          CANCELLED: 52,
          PAID: 60,
        };
        return (
          (sortMap[valueA] ? sortMap[valueA] : 0) -
          (sortMap[valueB] ? sortMap[valueB] : 0)
        );
      },
    },
    {
      headerName: "Contract Information",
      children: [
        {
          field: "contract.organisation.name",
          headerName: "General Contractor",
          hide: gcIds.length == 1 && !isSysAdmin,
          columnGroupShow: isGC ? "open" : undefined,
          type: ["withTextFilterCol"],
        },
        {
          field: "contract.sc_organisation.name",
          headerName: "Subcontractor",
          type: ["gcOnlyCol", "withTextFilterCol"],
          columnGroupShow: isGC ? undefined : "open",
        },
        {
          field: "contract.project.name",
          headerName: "Project",
          columnGroupShow: "open",
          type: ["withTextFilterCol"],
        },
        {
          field: "contract.name",
          headerName: "Contract",
          columnGroupShow: "open",
          type: ["withTextFilterCol"],
        },
        {
          headerName: "Entity Organisation",
          columnGroupShow: "open",
          type: ["withTextFilterCol"],
          valueGetter: ({ getValue, data }) => {
            if (!data) {
              return "";
            }
            const entity = findEntityOrganisationByInvoice(data);
            return entity ? entity.name : "";
          }
        },
        {
          headerName: "Entity ABN",
          columnGroupShow: "open",
          type: ["withTextFilterCol"],
          valueGetter: ({ getValue, data }) => {
            if (!data) {
              return "";
            }
            const entity = findEntityOrganisationByInvoice(data);
            return entity ? entity.abn : "";
          }
        },
      ],
    },
    {
      headerName: "Invoice Details",
      children: [
        {
          field: "external_data.claim_number",
          headerName: "Claim #",
          columnGroupShow: "open",
          type: ["withTextFilterCol"],
        },
        {
          headerName: "Original Due Date",
          valueGetter: ({ getValue, data }) => {
            if (!data) {
              return "";
            }
            return invoiceHelper.isOriginalDueDateApplicable(data)
              ? moment(data.original_payment_due_date).format(momentjsFormat.date)
              : "Standard terms"; // workaround for several things
          },
          filterValueGetter: ({
            data
          }: ValueGetterParams) => {
            if (!data) {
              return null;
            }
            return invoiceHelper.isOriginalDueDateApplicable(data)
              ? moment(data.original_payment_due_date).toDate()
              : null;
          },
          type: [`withDateFilterCol`],
        },
        {
          field: "external_data.net_claim_incl_tax",
          headerName: "Invoice Amount",
          type: ["currencyCol", "numericColumn"],
        },
        {
          field: "external_data.submitted_at",
          headerName: "Submitted Date",
          type: [`dateCol`, `withDateFilterCol`],
        },
        {
          field: "external_data.approved_at",
          headerName: "Approved Date",
          type: [`dateCol`, `withDateFilterCol`],
        },
      ],
    },
    {
      headerName: "Early Payment Details",
      children: [
        {
          field: "revised_payment_due_date",
          headerName: "Revised Due Date",
          type: [`dateCol`, "withDateFilterCol"],
        },
        {
          field: "calculator.discountAmountInclTax",
          headerName: "Discount Amount",
          type: ["currencyCol", "numericColumn"],
        },
        {
          field: "calculator.discountAmountPercentage",
          headerName: "Discount %",
          type: ["percentageCol", "numericColumn"],
        },
        {
          field: "calculator.progressPayFeeInclTaxAmount",
          headerName: "Fee",
          type: ["gcOnlyCol", "currencyCol", "numericColumn"],
          columnGroupShow: "open",
        },
        {
          field: "calculator.progressPayFeePercentage",
          headerName: "Fee %",
          type: ["gcOnlyCol", "percentageCol", "numericColumn"],
          columnGroupShow: "open",
        },
        {
          headerName: "Revenue",
          valueGetter: (params) => {
            return params.data && params.data.calculator
              ? params.data.calculator?.discountAmountInclTax -
                  params.data.calculator?.progressPayFeeInclTaxAmount
              : null;
          },
          type: ["gcOnlyCol", "currencyCol", "numericColumn"],
          columnGroupShow: "open",
        },
        {
          field: "approved_at",
          headerName: "Approved Date",
          type: [`dateCol`, `withDateFilterCol`],
        },
      ],
    },
    {
      field: "external_data.pas_ref",
      headerName: "Invoice #",
      type: ["withTextFilterCol"],
      pinned: "left",
    },
    {
      field: "created_at",
      headerName: "Requested At",
      type: [`dateCol`, `withDateFilterCol`],
      pinned: "left",
    },
    {
      field: "id",
      headerName: "ID",
      type: ["withTextFilterCol"],
      hide: true,
    },
    {
      colId: "actions",
      headerName: "",
      cellRenderer: ActionsCellRenderer,
      flex: 1,
      minWidth: 100,
      maxWidth: 100,
      cellStyle: { textAlign: "center" },
      resizable: false,
      pinned: "right",
    },
  ]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
    }),
    []
  );

  const customColTypes = useMemo(
    () => ({
      currencyCol: {
        valueFormatter: (params: ValueFormatterParams<InvoiceType>) => {
          if (params.value === null || params.value === undefined) {
            return "";
          }
          return formatCurrency(params.value);
        },
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: [
            "equals",
            "notEqual",
            "lessThan",
            "lessThanOrEqual",
            "greaterThan",
            "greaterThanOrEqual",
            "inRange",
          ],
          buttons: ["reset"],
        },
      },
      dateTimeCol: {
        valueFormatter: (params: ValueFormatterParams<InvoiceType>) => {
          if (params.value === null || params.value === undefined || params.value === "") {
            return "";
          }
          return moment(params.value).format(momentjsFormat.dateTime);
        },
      },
      dateCol: {
        valueFormatter: (params: ValueFormatterParams<InvoiceType>) => {
          if (params.value === null || params.value === undefined || params.value === "") {
            return "";
          }
          return moment(params.value).format(momentjsFormat.date);
        },
      },
      percentageCol: {
        valueFormatter: (params: ValueFormatterParams<InvoiceType>) => {
          if (params.value === null || params.value === undefined) {
            return "";
          }
          return `${params.value.toFixed(2)}%`;
        },
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: [
            "equals",
            "notEqual",
            "lessThan",
            "lessThanOrEqual",
            "greaterThan",
            "greaterThanOrEqual",
            "inRange",
          ],
          buttons: ["reset"],
        },
      },
      withTextFilterCol: {
        filter: true,
        filterParams: {
          filterOptions: [
            "contains",
            "notContains",
            "equals",
            "notEqual",
            "blank",
            "notBlank",
          ],
          buttons: ["reset"],
        },
      },
      withDateFilterCol: {
        filter: 'agDateColumnFilter',
        filterParams: {
          buttons: ["reset"],
          comparator: (filterLocalDateAtMidnight: Date, cellValue:any) => {
            const dateAsString = cellValue;

            if (dateAsString == null) {
                return 0;
            }

            const cellDate = moment(dateAsString)

            // Now that both parameters are Date objects, we can compare
            if (cellDate.isBefore(moment(filterLocalDateAtMidnight), 'days')) {
                return -1;
            } else if (cellDate.isAfter(moment(filterLocalDateAtMidnight), 'days')) {
                return 1;
            }
            return 0;
          }
        }
      },
      gcOnlyCol: {
        hide: !isGC && !isSysAdmin,
      },
      scOnlyCol: {
        hide: isGC && !isSysAdmin,
      },
    }),
    [isGC, isSysAdmin]
  );
  const { getFilterModel, setFilterModel } = useRememberFilterModel("invoices");

  const onGridReady = useCallback(
    (e: GridReadyEvent) => {
      const allCols = e.api.getColumns();
      if (allCols) {
        e.api.autoSizeColumns(
          allCols.filter((c) => c.getColId() !== "actions")
        );
      }
      // Resume filters in the localstorage
      if (getFilterModel()) {
        e.api.setFilterModel(getFilterModel());
      }

      setAgGridApi(e.api);
    },
    [getFilterModel]
  );

  const [filterPills, setFilterPills] = useState<
    { key: string; text: string }[]
  >([]);

  const onFilterChanged = useCallback(
    (e: FilterChangedEvent) => {
      const filterModel = e.api.getFilterModel();
      const all = [];
      for (let colKey in filterModel) {
        const col = e.columnApi.getColumn(colKey);
        if (col) {
          all.push({
            key: col.getColId(),
            text: `Filter: ${col.getColDef().headerName || colKey}`,
          });
        }
      }
      setFilterPills(all);
      // Store filters in the localstorage
      setFilterModel(filterModel);
    },
    [setFilterModel]
  );

  const onFilterRemoved = (key: string) => {
    const gridApi = agGridRef.current!.api;
    const currentModel = gridApi.getFilterModel();
    delete currentModel[key];
    gridApi.setFilterModel(currentModel);
  };

  const getRowId = useMemo<GetRowIdFunc>(() => {
    return (params: GetRowIdParams<InvoiceType>) => params.data.id;
  }, []);

  return (
    <div>
      <div className="mb-2 flex flex-row flex-nowrap justify-between items-center">
        <div className="flex-1">
          <Input
            className="max-w-[400px]"
            placeholder="Quick search..."
            onChange={(e) => {
              agGridRef.current?.api.setQuickFilter(e.target.value);
            }}
          ></Input>
        </div>
        <div className="flex-0">
          { agGridApi && <InvoicesSummaryChartModal agGridApi={agGridApi}/>}
          <Button
            variant={"ghost"}
            onClick={() => {
              agGridRef.current?.api.exportDataAsCsv({
                allColumns: true,
              });
            }}
          >
            <Download className="w-6 h-6" />
          </Button>
          <TableMenu initialIsExpiredIncluded={initialIsExpiredIncluded} onInvoicesEvent={onInvoicesEvent}/>
        </div>
      </div>
      <div>
        <Pills filters={filterPills} onFilterRemoved={onFilterRemoved}></Pills>
      </div>
      <div className="ag-theme-alpine w-full h-[70vh]">
        <AgGridReact
          ref={agGridRef}
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          columnTypes={customColTypes}
          onGridReady={onGridReady}
          onFilterChanged={onFilterChanged}
          suppressColumnVirtualisation // This is to resize cols not in viewport yet (with a performance cost)
          getRowId={getRowId}
        />
      </div>
    </div>
  );
};
