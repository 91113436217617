"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DecodeInviteTokenRequestSchema = exports.ValidateInviteTokenRequestSchema = exports.ForgotPasswordRequestSchema = exports.ResetPasswordRequestSchema = exports.LoginRequestSchema = void 0;
const zod_1 = require("zod");
exports.LoginRequestSchema = zod_1.z.object({
    email: zod_1.z.string(),
    password: zod_1.z.string(),
});
exports.ResetPasswordRequestSchema = zod_1.z.object({
    email: zod_1.z.string(),
    password: zod_1.z.string(),
    confirmPassword: zod_1.z.string(),
    token: zod_1.z.string(),
});
exports.ForgotPasswordRequestSchema = zod_1.z.object({
    email: zod_1.z.string(),
});
exports.ValidateInviteTokenRequestSchema = zod_1.z.object({
    token: zod_1.z.string()
});
exports.DecodeInviteTokenRequestSchema = zod_1.z.object({
    token: zod_1.z.string()
});
