import { DecodeInviteTokenRequest } from '@progresspay-next/dtos';
import Request from './_request';
const { _get, _post, _put, _delete } = Request;

interface DecodeInviteTokenResponse {
  invoice_id?: string,
  pas_id?: string,
  iframe: boolean,
  user: {
    access_token: string,
  }
}

export const decodeInviteToken = (token:string): Promise<DecodeInviteTokenResponse> => {
  const request: DecodeInviteTokenRequest = {
    token,
  }
  return _put<DecodeInviteTokenResponse>(`auth/token`, request)
}
