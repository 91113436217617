import { formatDate, momentjsFormat } from "@progresspay-next/shared";
import moment from "moment";
import { create } from "zustand";

const convertFilterToQuery = (filter: string) => {
  if (filter.match("fy\\d{4}q\\d")) {
    const [year, quarter] = filter.match(/\d+/g)!.map(Number);
    const startDate = moment().set("year", quarter > 2 ? year: year - 1).set("quarter", quarter > 2 ? quarter - 2 : quarter + 2).startOf("quarter");
    const endDate = startDate.clone().add(1, "quarter").startOf("quarter");
    return {
      created_at_from: formatDate(startDate, momentjsFormat.dateDB),
      created_at_to: formatDate(endDate, momentjsFormat.dateDB),
    };
  }
  return {};
}

type DashboardFilterStoreType = {
  filter: string;
  setFilter: (filter: string) => void;
  getFilterQuery: () => any;
};

export const useDashboardFilterStore = create<DashboardFilterStoreType>(
  (set, get) => ({
    filter: "none",
    setFilter: (filter: string) => set({ filter }),
    getFilterQuery: () => convertFilterToQuery(get().filter),
  })
);
