import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import { useGlobalStore } from '../../stores/global';
import { getApi } from '../../utils/api';

interface AdminGuardProp {
}

export const AdminGuard: (props:AdminGuardProp) => JSX.Element|null = () => {
  const api = getApi();
  const store = useGlobalStore();
  const navigate = useNavigate();
  const me = useQuery({
    queryKey: ['me'],
    queryFn: api.me,
  });
  useEffect(() => {
    if (me.data) {
      store.setIsSystemAdmin(me.data.is_system_admin);
      if (!me.data.is_system_admin) {
        navigate('/home');
      }
    }
  }, [me.data]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
}

export const withAdminGuard = (component: React.ReactNode): React.ReactNode => {
  return <>
    <AdminGuard></AdminGuard>
    {component}
  </>
}
