import Request from './_request';
import { FindUsersRequest, UserAcceptTermsRequest, UserResponse, UserSignUpResponse, UsersResponse } from '@progresspay-next/dtos';
import { UpdateUserRequest, UserInviteRequest, UserSignUpRequest } from '@progresspay-next/dtos';
import { MeResponse } from '@progresspay-next/dtos';
const { _get, _post, _put } = Request;

export const createUser = (createUserDTO: UserSignUpRequest) => {
  return _post<UserSignUpResponse>(`/auth/signup`, createUserDTO);
}

export const me = () => {
  return _get<MeResponse>(`/auth/me`);
}

export const inviteUser = (inviteUserDTO: UserInviteRequest) => {
  return _post<UserResponse>(`/users/invite`, inviteUserDTO);
}

export const updateUser = (updateUserDTO: UpdateUserRequest) => {
  return _put<UserResponse>(`/users/${updateUserDTO.id}`, updateUserDTO);
}

export const findUsers = () => {
  return _get<UsersResponse>(`/admin/users`);
}

export const findUsersNonAdmin = (query: FindUsersRequest) => {
  return _get<UsersResponse>(`/users`, query);
}

export const findUser = (id: number) => {
  return _get<UserResponse>(`/admin/users/${id}`);
}
export const findUserNonAdmin = (id: number|string) => {
  return _get<UserResponse>(`/users/${id}`);
}

export const updateUsers = (payload: UpdateUserRequest) => {
  if (payload.is_org_admin !== undefined) {
    payload.is_org_admin = Boolean(payload.is_org_admin);
  }

  return _put<UserResponse>(`/admin/users/${payload.id}`, payload);
}

export const acceptTerms = (id: string) => {
  return _put<UserResponse>(`/users/${id}/accept-terms`, {
    id,
    terms_accepted: true
  });
}
