import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGlobalStore } from "../stores/global";
import { getApi } from "../utils/api";

export const Invite = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [isChecking, setIsChecking] = useState<boolean>(true);
  const store = useGlobalStore();
  const token = searchParams.get("token");
  const source = searchParams.get("source");
  if (!token) {
    setIsChecking(false);
  }
  const [errorMessage, setErrorMessage] = useState<string>(
    `Sorry, we have trouble process this invite.`
  );
  const navigate = useNavigate();

  const api = getApi();
  const { mutate: decodeToken } = useMutation(
    {
      mutationFn: () => api.decodeInviteToken(token ? token : ""),
      onSuccess: (data) => {
        const invoiceId = data.invoice_id;
        const token = data.user?.access_token;
        const pasId = data.pas_id;
        if (token) {
          store.userLogin(token);
        }

        if (invoiceId) {
          navigate(
            data.iframe
              ? `/embed/invoices/${invoiceId}`
              : `/invoices/${invoiceId}`
          );
          return;
        }

        if (pasId) {
          navigate(
            data.iframe
              ? `/embed/eligible-invoices/modal/${pasId}`
              : `/home`
          );
          return;
        }

        navigate(
          data.iframe
            ? "/embed/dashboard"
            : "/home"
        );

        setIsChecking(false);
      },
      onSettled: (data) => {},
      onError: (error: Error) => {
        setErrorMessage(error.message);
        setIsChecking(false);
      },
    }
  );

  useEffect(() => {
    decodeToken();
  }, [decodeToken]);
  return (
    <>
      {isChecking ? (
        // This is to simulate the same bg color in Payapp. Consider creating a dedicated layout for this.
        <div className="z-50 fixed inset-0 bg-[#F3F5F7]"></div>
      ) : null}
    </>
  );
};
