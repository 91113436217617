"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityEntityType = void 0;
var ActivityEntityType;
(function (ActivityEntityType) {
    ActivityEntityType["organisations"] = "OrganisationActivity";
    ActivityEntityType["projects"] = "ProjectActivity";
    ActivityEntityType["contracts"] = "ContractActivity";
    ActivityEntityType["invoices"] = "InvoiceActivity";
})(ActivityEntityType || (exports.ActivityEntityType = ActivityEntityType = {}));
