import { ExternalLayout } from '../components/layouts/ExternalLayout';
import React, { useState } from 'react';
import { App, Button, Checkbox, Form, Input, Layout, Space, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useGlobalStore } from '../stores/global';
import { getApi } from '../utils/api';
import LogoSVG from '../components/LogoSVG';
import { CheckCircleOutlined, RightOutlined, RollbackOutlined } from '@ant-design/icons';
import { ForgotPasswordRequest } from '@progresspay-next/dtos';

interface ForgotPasswordProps {

}

export const ForgotPassword: (props:ForgotPasswordProps) => JSX.Element | null = () => {
  const { message } = App.useApp();
  const store = useGlobalStore();
  const navigate = useNavigate();
  const api = getApi();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const forgotPassword = useMutation({
    mutationFn: (payload:ForgotPasswordRequest) => {
      return api.forgotPassword(payload);
    },
    onSuccess: (data, variables, context) => {
      // TODO: resend / success mesage
      message.success("Your will receive a reset password link in your email");
      setIsSuccess(true);
    }
  })

  const onFinish = (values: any) => {
    forgotPassword.mutate(values);
  };


    return (
      <>
        <div className="mb-8 flex items-center gap-4">
          <span className="text-3xl font-bold">Forgot Password?</span>
        </div>
        <Form
          className='w-full'
          name="basic"
          onFinish={onFinish}
          autoComplete="off"
          layout='vertical'
        >

          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please enter your email!" },
              { type: "email", message: "Please enter a valid emaill!" },
            ]}
          >
            <Input/>
          </Form.Item>

          {forgotPassword.isError ? (
            <Form.Item>
              <Typography.Paragraph type={"danger"}>
                An error occurred: {(forgotPassword.error as Error).message}
              </Typography.Paragraph>
            </Form.Item>
          ) : null}
          { isSuccess ?  (<div>
              🎉 Please check your email for the reset link.
            </div>) : null}
          <div className='flex gap-2 mt-8 items-center'>
            { isSuccess ? null : (<Button size="large" loading={forgotPassword.isPending} disabled={isSuccess} type="primary" htmlType="submit">
              Submit
            </Button>) }
            <Link to="/">
              <Button size="large" htmlType="button">Back</Button>
            </Link>
          </div>
        </Form>
      </>
    );
}