import autoAnimate from "@formkit/auto-animate";
import { Layout, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Outlet } from "react-router-dom";
import LogoSVG from "../LogoSVG";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { faker } from "@faker-js/faker";

const { Header, Footer, Sider, Content } = Layout;
const { Title, Paragraph, Text, Link } = Typography;

interface ExternalLayoutProps {
  children?: React.ReactElement;
}
const CarouselCard = ({ content = "", name = "", company = "" }) => {
  return (
    <div className="rounded-3xl bg-[#0571CB] p-6">
      <p className="mb-8 text-sm">{content}</p>
      <div className="flex flex-row flex-nowrap items-center gap-4">
        <div className="h-[60px] w-[60px] flex-none rounded-lg bg-white"></div>
        <div className="flex-auto">
          <div className="text-base font-semibold">{name}</div>
          <div className="text-xs">{company}</div>
        </div>
      </div>
    </div>
  );
};
const testimonials = Array.from(Array(3).keys()).map(() => ({
  name: faker.name.fullName(),
  company: faker.company.name(),
  content: faker.lorem.paragraph(),
}));
const Carousel = () => {
  const parent = useRef(null);
  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const [index, setIndex] = useState(0);
  useEffect(() => {
    let loop = setInterval(() => setIndex((index + 1) % 3), 5000);
    return () => {
      clearInterval(loop);
    };
  });

  return (
    <div
      ref={parent}
      className="min-h-[400px] flex flex-nowrap flex-col justify-end"
    >
      <CarouselCard
        key={index}
        name={testimonials[index].name}
        content={testimonials[index].content}
        company={testimonials[index].company}
      ></CarouselCard>
      <div className="my-6 flex flex-row flex-nowrap justify-center gap-2">
        {Array.from(Array(3).keys()).map((item, itemIndex) => (
          <div
            key={itemIndex}
            className={`h-1 ${
              itemIndex == index ? `w-6 opacity-100` : `w-4 opacity-20`
            } rounded-full bg-white`}
          ></div>
        ))}
      </div>
    </div>
  );
};
const Aside = () => {
  return (
    <div
      style={{
        backgroundImage: `radial-gradient(38.06% 34.84% at 37.73% 18.28%, rgba(72, 208, 253, 0.4) 0%, rgba(72, 208, 253, 0) 100%), radial-gradient(99.84% 99.84% at -9.39% 97.66%, rgba(2, 86, 145, 0.25) 0%, rgba(5, 135, 164, 0) 100%)`,
      }}
      className="group relative w-2/5 flex-none self-stretch rounded-3xl bg-[#108EE9] text-white "
    >
      <div
        className="absolute inset-0
        bg-[radial-gradient(rgba(255,255,255,0.1)_8%,transparent_8%)]
        bg-[0%_0%]
        opacity-0
        group-hover:bg-[10%_1%] group-hover:opacity-100"
        style={{
          backgroundSize: `5vmin 5vmin`,
          transition: `background-position 350ms ease, opacity 350ms ease`,
        }}
      ></div>
      <div className="absolute flex h-full flex-col flex-nowrap justify-between p-10">
        <LogoSVG fill="currentColor" size={48} />

        <div>
          <h1 className="mb-10 text-4xl font-bold">
            Start your journey with us.
          </h1>
          <p className="text-xl">
            Empower supply chains with the best early payment system.
          </p>
        </div>

        <Carousel />
      </div>
    </div>
  );
};
export type ExternalLayoutOutletContext = [
  (b:boolean) => void,
  (b:boolean) => void,
]

export const ExternalLayout: (
  props: ExternalLayoutProps
) => JSX.Element | null = ({ children }) => {
  const parent = useRef(null);
  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);
  const [isCurtainUp, setIsCurtainUp] = useState<boolean>(false);
  const [isBarebone, setIsBarebone] = useState(false);
  const context:ExternalLayoutOutletContext = [setIsCurtainUp, setIsBarebone]

  const content = children ? (
    children
  ) : (
    <Outlet context={context} />
  );
  return (
    <div className="min-h-screen">
      {isCurtainUp ? <div className="z-50 fixed inset-0 bg-white"></div> : null}
      {isBarebone ? (
        content
      ) : (
        <div className="flex h-screen flex-row items-center gap-6 p-6">
          <Aside />
          <div className="flex-auto">
            <div className="mx-auto max-w-[614px]">{content}</div>
          </div>
        </div>
      )}
    </div>
  );
};
