"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserAcceptTermsRequestSchema = exports.FindUsersRequestSchema = exports.UpdateUserRequestSchema = exports.UserInviteRequestSchema = exports.UserSignUpRequestSchema = void 0;
const zod_1 = require("zod");
const user_type_1 = require("../types/user.type");
exports.UserSignUpRequestSchema = user_type_1.UserTypeSchema.partial().extend({
    password: zod_1.z.string(),
    organisation_id: zod_1.z.string().nullable().optional()
});
exports.UserInviteRequestSchema = user_type_1.UserTypeSchema.partial().extend({
    organisation_id: zod_1.z.string()
});
exports.UpdateUserRequestSchema = user_type_1.UserTypeSchema.partial();
exports.FindUsersRequestSchema = zod_1.z.object({
    organisation_id: zod_1.z.string().nullish(),
    first_name: zod_1.z.string().nullish(),
    last_name: zod_1.z.string().nullish(),
    email: zod_1.z.string().nullish(),
});
exports.UserAcceptTermsRequestSchema = zod_1.z.object({
    id: zod_1.z.string(),
    terms_accepted: zod_1.z.boolean(),
});
