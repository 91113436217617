import { Dashboard } from "@/components/dashboard/Dashboard";
import { EmbeddedMenu } from "@/components/embedded/EmbeddedMenu";
import { InvoiceType } from "@progresspay-next/dtos";
import { useEffect, useMemo, useState } from "react";
import {
  PageTitleSlotType,
  useInternalLayoutContext,
} from "../components/layouts/InternalLayout";
import { useContextStore } from "../stores/context";
import { useQueryInvoicesNonAdmin } from "../utils/query";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { FilterIcon } from "lucide-react";
import { useDashboardFilterStore } from "@/stores/dashboardFilter";
import moment from "moment";
import { formatDate } from "@progresspay-next/shared";
import { cn } from "@/utils/ui";

interface HomeProps {
  isEmbedded?: boolean;
}

const getStartEndOfFinancialYearQuarter = (year: number, quarter: number) => {
  const from = moment().year(year).month(6).format("YYYY-MM-DD");
  const to = "";
  return {
    from,
    to,
  };
};
const convertMomentToFinancialYearQuarter = (moment: moment.Moment) => {
  const year = moment.year();
  const month = moment.month();
  const quarter = moment.quarter();
  return [month > 5 ? year + 1 : year, month > 5 ? quarter - 2 : quarter + 2];
};
// eg. Financial Year 25: 2024-07-01 to 2025-06-30
const getFinancialYearQuarterOptions = (organisationCreatedAt: string) => {
  const options = [];
  if (organisationCreatedAt) {
    const organisationCreatedAtMoment = moment(organisationCreatedAt);
    const organisationCreatedYear = organisationCreatedAtMoment.year();
    const organisationCreatedMonth = organisationCreatedAtMoment.month();
    const now = moment();
    const nowYear = now.year();
    const nowMonth = now.month();
    const totalQuarters =
      Math.floor(
        ((nowYear - organisationCreatedYear) * 12 +
          (nowMonth - organisationCreatedMonth)) /
          3
      ) + 1;
    for (let i = 0; i < totalQuarters; i++) {
      const targetDate = now.clone().subtract(i, "quarter");
      const [fyYear, fyQuarter] =
        convertMomentToFinancialYearQuarter(targetDate);
      const fy = `FY${`${fyYear-1}`.substring(2)}/${`${fyYear}`.substring(2)}`;
      options.push({
        label: `${fy} Q${fyQuarter}`,
        value: `fy${fyYear}q${fyQuarter}`,
        from: targetDate.clone().startOf("quarter"),
        to: targetDate.clone().add(1, "quarter").startOf("quarter"),
      });
    }
  }
  return options;
};
type PageTitleProps = {
  isSysAdmin: boolean;
  financialYearQuarterOptions: ReturnType<
    typeof getFinancialYearQuarterOptions
  >;
  defaultDashboardFilter: string;
  filterOnly?: boolean;
};
const getDefaultDashboardFilter = (
  isGC: boolean,
  financialYearQuarterOptions: ReturnType<typeof getFinancialYearQuarterOptions>
) => {
  if (isGC && financialYearQuarterOptions.length > 0) {
    return financialYearQuarterOptions[0].value;
  }
  return "all";
};

export const PageTitle = ({
  isSysAdmin,
  financialYearQuarterOptions,
  defaultDashboardFilter,
  filterOnly = false,
}: PageTitleProps) => {
  const { filter: dashboarFilter, setFilter: setDashboardFilter } =
    useDashboardFilterStore();
  useEffect(() => {
    // The filter is only applicable for system admin for now.
    if (isSysAdmin) {
      setDashboardFilter(defaultDashboardFilter);
    }
  }, [defaultDashboardFilter, isSysAdmin, setDashboardFilter]);

  return (
    <div
      className={cn(`flex flex-row flex-nowrap items-center justify-between`)}
    >
      <div className="text-4xl">{!filterOnly && `Early Payment Dashboard`}</div>
      <div className={cn(!isSysAdmin && `hidden`)}>
        <div className="text-gray-500 text-xs mb-1">Showing</div>
        <Select
          value={dashboarFilter}
          onValueChange={(v) => {
            setDashboardFilter(v);
          }}
        >
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Select a filter" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="none">All</SelectItem>
            <SelectSeparator />
            <SelectGroup>
              <SelectLabel>Financial Year</SelectLabel>
              {financialYearQuarterOptions.map((option) => {
                return (
                  <SelectItem key={option.value} value={option.value}>
                    {option.label}
                  </SelectItem>
                );
              })}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
    </div>
  );
};

export const Home: (props: HomeProps) => JSX.Element | null = ({
  isEmbedded = false,
}) => {
  const contextStore = useContextStore();
  contextStore.setIsEmbedded(isEmbedded);
  const isLoading = !contextStore.me;
  const setPageTitleSlotProps =
    useInternalLayoutContext()?.setPageTitleSlotProps;
  const organisationCreatedAt = contextStore.me?.organisation?.created_at;
  const financialYearQuarterOptions = useMemo(() => {
    return getFinancialYearQuarterOptions(organisationCreatedAt);
  }, [organisationCreatedAt]);
  useEffect(() => {
    if (!isEmbedded && contextStore.me) {
      setPageTitleSlotProps &&
        setPageTitleSlotProps({
          type: PageTitleSlotType.Dashboard,
          props: {
            financialYearQuarterOptions,
            defaultDashboardFilter: getDefaultDashboardFilter(
              contextStore.me.is_gc,
              financialYearQuarterOptions
            ),
            isSysAdmin: contextStore.me.is_system_admin,
          },
        });
    }
  }, [
    contextStore.me,
    financialYearQuarterOptions,
    isEmbedded,
    setPageTitleSlotProps,
  ]);

  return (
    contextStore.me && (
      <div>
        <div>
          {isEmbedded && (
            <PageTitle
              financialYearQuarterOptions={financialYearQuarterOptions}
              defaultDashboardFilter={getDefaultDashboardFilter(
                contextStore.me.is_gc,
                financialYearQuarterOptions
              )}
              filterOnly={true}
              isSysAdmin={contextStore.me.is_system_admin}
            />
          )}
          <Dashboard></Dashboard>
        </div>
      </div>
    )
  );
};
