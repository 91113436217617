import {
  BuildOutlined,
  DollarCircleOutlined,
  DownOutlined,
  FileTextOutlined,
  HomeOutlined,
  LogoutOutlined,
  MoneyCollectOutlined,
  TeamOutlined,
  ToolOutlined,
  UserOutlined,
} from "@ant-design/icons";
import autoAnimate from "@formkit/auto-animate";
import { DropdownMenuLabel } from "@radix-ui/react-dropdown-menu";
import {
  App,
  Avatar,
  ConfigProvider,
  Layout,
  Menu,
  MenuProps,
  Modal,
} from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { useContextStore } from "../../stores/context";
import { useGlobalStore } from "../../stores/global";
import { AuthenticatedGuard } from "../guards/AuthenticatedGuard";
import { EULAModal } from "../misc/EULAModal";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { PageTitle as PageTitleDashboard } from "@/pages/Home";
import { flushSync } from "react-dom";
const { Header, Content, Footer, Sider } = Layout;

interface InternalLayoutProps {
  children?: React.ReactElement;
}

type MenuItem = Required<MenuProps>["items"][number];
const getItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  theme?: "light" | "dark"
): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
    theme,
  } as MenuItem;
};

export type InteralLayoutOutletContext = {
  setPageTitleSlotProps: (props: PageTitleSlotProps) => void;
};

type PageTitleSlotProps = {
  type: string;
  props: any;
};
export enum PageTitleSlotType {
  SimplePageTitle = "SimplePageTitle",
  Dashboard = "Dashboard",
}

const EmptyPageTitle = () => {
  return null;
};

const SimplePageTitle = ({ title }: { title: string }) => {
  return (
    <div className="flex flex-row flex-nowrap items-center justify-between">
      <div className="text-4xl">{title}</div>
    </div>
  );
};

const PageTitleSlot = (props: PageTitleSlotProps) => {
  switch (props.type) {
    case PageTitleSlotType.SimplePageTitle:
      return <SimplePageTitle {...props.props} />;
    case PageTitleSlotType.Dashboard:
      return <PageTitleDashboard {...props.props} />;
    default:
      return <EmptyPageTitle />;
  }
};
const useLogout = () => {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const store = useGlobalStore();
  return useCallback(() => {
    store.userLogout();
    message.success(`You have logged out!`);
    // Walkaround solution for convert async navigate to sync;
    document.startViewTransition(() => {
      flushSync(() => navigate("/"));
    });
  }, [message, navigate, store]);
};

export const InternalLayout: (
  props: InternalLayoutProps
) => JSX.Element | null = ({ children }) => {
  const { message } = App.useApp();
  const store = useGlobalStore();
  const contextStore = useContextStore();
  const navigate = useNavigate();
  const [pageTitle, setPageTitle] = useState<React.ReactNode>(null);
  const location = useLocation();
  const [pageTitleSlotProps, setPageTitleSlotProps] =
    useState<PageTitleSlotProps>();
  const logout = useLogout();

  const handleMenuClick = ({ key, keyPath, domEvent }: any) => {
    if (key == "logout") {
      Modal.confirm({
        title: "Do you want to logout?",
        icon: <LogoutOutlined />,
        content: "You are about to logout from ProgressPay",
        onOk() {
          logout();
        },
      });
    }
    if (key.indexOf("/") === 0) {
      navigate(key);
    }
  };

  const centerNavItems = [
    getItem("Home", "/home", <HomeOutlined />),
    getItem("Reports", "/reports", <FileTextOutlined />),
  ];

  if (!contextStore.me?.is_system_admin && contextStore.me?.is_gc) {
    centerNavItems.push(
      getItem("Supply Chains", "/supply-chains", <BuildOutlined />)
    );
  }
  if (store.isAdmin) {
    centerNavItems.push(
      getItem(
        "Admin",
        "admin",
        <ToolOutlined />,
        [
          getItem(`Organisations`, "/admin/organisations", <TeamOutlined />),
          getItem(`Users`, "/admin/users", <UserOutlined />),
          getItem("Contracts", "/admin/contracts", <MoneyCollectOutlined />),
          getItem("Invoices", "/admin/invoices", <DollarCircleOutlined />),
        ],
        "dark"
      )
    );
  }

  const parent = useRef(null);
  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  return (
    <>
      <AuthenticatedGuard />

      {/* <EULAModal></EULAModal> */}

      <Layout style={{ minHeight: "100vh" }}>
        <Header
          className="top-header"
          style={{
            position: "fixed",
            zIndex: 1,
            width: "100%",
          }}
        >
          <div className="flex flex-row flex-nowrap items-center">
            <div className="logo flex-initial">
              <Link title="home" to="/home">
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.4678 14.4678V25.4763H18.082H19.972C22.9894 25.4763 25.4763 22.9895 25.4763 19.9721C25.4763 16.9546 22.9894 14.4678 19.972 14.4678H14.4678ZM14.4678 32.738V39.9997H36.9996C38.6565 39.9997 39.9997 38.6565 39.9997 36.9996C39.9997 29.4844 39.9997 21.989 39.9997 14.4678H31.5111C32.3069 16.1257 32.7711 18.0157 32.7711 19.9721C32.7711 27.0016 27.0347 32.738 20.0052 32.738H18.1152H14.4678Z"
                    fill="#0180D7"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.23792 25.5319V7.2379H25.5319V0H2C0.895432 0 0 0.895431 0 2V25.5319H7.23792Z"
                    fill="#48D0FD"
                  />
                </svg>
              </Link>
            </div>
            <ConfigProvider
              theme={{
                token: {
                  colorBgContainer: `transparent`,
                  colorText: `white`,
                },
              }}
            >
              <Menu
                className="flex-1 justify-center border-0"
                theme="light"
                selectedKeys={[location.pathname]}
                mode="horizontal"
                items={centerNavItems}
                onClick={handleMenuClick}
              />
            </ConfigProvider>

            <div className="text-white flex items-center">
              <Avatar
                size={32}
                className={`bg-white text-primary mr-2`}
                icon={<UserOutlined />}
              />
              <span>Hello {contextStore.me?.first_last}!</span>
            </div>

            <DropdownMenu>
              <DropdownMenuTrigger>
                <DownOutlined className="ml-4 text-white" />
              </DropdownMenuTrigger>
              <DropdownMenuContent align={"end"}>
                <DropdownMenuLabel className={"px-2 pt-2 pb-2"}>
                  <div>
                    <div className="text-primary font-bold">
                      {contextStore.me?.first_last}
                    </div>
                    <div className="text-gray-500 text-sm text-right">
                      {contextStore.me?.organisation?.name}
                    </div>
                  </div>
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  onSelect={() => {
                    navigate("/my-profile");
                  }}
                >
                  My Profile
                </DropdownMenuItem>
                {contextStore.me?.is_org_admin ||
                contextStore.me?.is_system_admin ? (
                  <DropdownMenuItem
                    onSelect={() => {
                      navigate("/my-organisation");
                    }}
                  >
                    My Organisation
                  </DropdownMenuItem>
                ) : null}
                <DropdownMenuItem onSelect={logout}>Logout</DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </Header>
        <Content className="my-16 px-12">
          <div className="mt-8 mb-4">
            {pageTitleSlotProps && <PageTitleSlot {...pageTitleSlotProps} />}
          </div>
          <div ref={parent}>
            {children ? (
              children
            ) : (
              <Outlet
                context={
                  { setPageTitleSlotProps } satisfies InteralLayoutOutletContext
                }
              />
            )}
          </div>
        </Content>
      </Layout>
    </>
  );
};

export const useInternalLayoutContext = () => {
  return useOutletContext<InteralLayoutOutletContext>();
};
