"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContractTypeSchema = void 0;
const zod_1 = require("zod");
const organisation_type_1 = require("./organisation.type");
exports.ContractTypeSchema = zod_1.z.object({
    id: zod_1.z.string(),
    erp_id: zod_1.z.string().nullable(),
    pas_id: zod_1.z.string().nullable(),
    organisation_id: zod_1.z.string().nullable(),
    organisation: organisation_type_1.OrganisationTypeSchema.optional().nullable(),
    sc_organisation_id: zod_1.z.string().nullable(),
    sc_organisation: organisation_type_1.OrganisationTypeSchema.optional().nullable(),
    project_id: zod_1.z.string(),
    project: zod_1.z.any().optional().nullable(),
    name: zod_1.z.string().nullable(),
    source: zod_1.z.any().nullable(),
    metadata: zod_1.z.any().nullable(),
    created_at: zod_1.z.string().nullable(),
    updated_at: zod_1.z.string().nullable(),
    disabled_at: zod_1.z.string().nullable(),
});
