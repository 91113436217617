import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { getApi } from "@/utils/api";
import { queryKey } from "@/utils/query";
import { cn } from "@/utils/ui";
import { InvoiceResponse } from "@progresspay-next/dtos";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CheckCircle, Loader2, XCircle } from "lucide-react";
import { useEffect, useState } from "react";
export const JobpacIntegrationAutomationModal = ({
  open,
  onOpenChange,
  invoice,
}: {
  open: boolean;
  onOpenChange: (v: boolean) => void;
  invoice: InvoiceResponse;
}) => {
  const [step, setStep] = useState<number>(0);
  const [errorStep, setErrorStep] = useState<number>();

  const steps = [
    {
      title: "Update Invoice Payment Due Date in JOBPAC",
    },
    {
      title: "Create Automated Discount Records in JOBPAC",
    },
    {
      title: "Create ProgressPay Fee Invoice",
    },
    {
      title: "Add Project Manager Worksheet (PMW) record in JOBPAC",
    },
  ];

  const queryClient = useQueryClient();
  const api = getApi();

  const updatePaymentDate = useMutation({
    mutationFn: (payload: Partial<InvoiceResponse>) => {
      return api.updatePaymentDate(invoice?.id);
    },
    onSuccess: (data) => {
      setStep(1);
    },
    onError: () => {
      setErrorStep(0);
      queryClient.invalidateQueries({ queryKey: queryKey.invoices()});
      queryClient.invalidateQueries({ queryKey: queryKey.invoiceActivities(invoice.id)});
    },
  });

  const createAPTransactionC = useMutation({
    mutationFn: (payload: any) => {
      return api.createAPTransaction(payload);
    },
    onSuccess: (data) => {
      setStep(2);
    },
    onError: () => {
      setErrorStep(1);
      queryClient.invalidateQueries({ queryKey: queryKey.invoices()});
      queryClient.invalidateQueries({ queryKey: queryKey.invoiceActivities(invoice.id)});
    },
  });

  const createAPTransactionI = useMutation({
    mutationFn: (payload: any) => {
      return api.createAPTransaction(payload);
    },
    onSuccess: (data) => {
      setStep(3);
    },
    onError: () => {
      setErrorStep(2);
      queryClient.invalidateQueries({ queryKey: queryKey.invoices()});
      queryClient.invalidateQueries({ queryKey: queryKey.invoiceActivities(invoice.id)});
    },
  });

  const createNewPMWorksheet = useMutation({
    mutationFn: (payload: any) => {
      return api.createNewPMWorksheet(payload.id);
    },
    onSuccess: (data) => {
      setStep(4);
      queryClient.invalidateQueries({ queryKey: queryKey.invoices()});
    },
    onError: () => {
      setErrorStep(3);
      queryClient.invalidateQueries({ queryKey: queryKey.invoices()});
      queryClient.invalidateQueries({ queryKey: queryKey.invoiceActivities(invoice.id)});
    },
  });

  const { mutate: updatePaymentDateMutate } = updatePaymentDate;
  const { mutate: createAPTransactionCMutate } = createAPTransactionC;
  const { mutate: createAPTransactionIMutate } = createAPTransactionI;
  const { mutate: createNewPMWorksheetMutate } = createNewPMWorksheet;

  useEffect(() => {
    if (open) {
      if (step == 0) {
        updatePaymentDateMutate({ id: invoice.id });
      } else if (step == 1) {
        createAPTransactionCMutate({ id: invoice.id, invoiceOrCreditFlag: "C" });
      } else if (step == 2) {
        createAPTransactionIMutate({ id: invoice.id, invoiceOrCreditFlag: "I" });
      } else if (step == 3) {
        createNewPMWorksheetMutate({ id: invoice.id });
      }
    }
  }, [step, open, invoice.id, updatePaymentDateMutate, createAPTransactionCMutate, createAPTransactionIMutate, createNewPMWorksheetMutate]);

  return (
    <Dialog
      open={open}
      onOpenChange={(v) => {
        if (v) {
          // reset
          setStep(0);
          setErrorStep(undefined);
        }
        onOpenChange(v);
      }}
    >
      <DialogContent onInteractOutside={e => e.preventDefault()}>
        <DialogHeader>
          <DialogTitle className="text-xl">
            JOBPAC Integration in Progress
          </DialogTitle>
        </DialogHeader>
        <div>
          {steps.map((s, i) => {
            return (
              <div
                key={s.title}
                className="flex flex-row flex-nowrap py-2 gap-2 items-center"
              >
                <span className="min-w-[24px]">
                  {step == i ? (
                    errorStep === i ? (
                      <XCircle className="text-red-400"></XCircle>
                    ) : (
                      <Loader2 className="animate-spin"></Loader2>
                    )
                  ) : null}
                  {step > i ? (
                    <CheckCircle className="text-green-400"></CheckCircle>
                  ) : null}
                </span>
                <span className={cn(step < i && `text-gray-200`)}>
                  {s.title}
                </span>
              </div>
            );
          })}
        </div>
      </DialogContent>
    </Dialog>
  );
};
