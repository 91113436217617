import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { message } from "antd";
import { FindContractsRequest, FindUsersRequest, InvoicesRequest, InvoicesSummaryRequest, QueryProjectsRequest } from "@progresspay-next/dtos";
import { UserType } from "@progresspay-next/dtos";
import { getApi } from "./api";

interface OverrideUseQueryOptions {
  
}

const api = getApi();

export const queryKey = {
  organisationById: (id: number | string) => ["organisations", { id }],
  organisations: () => ["organisations"],
  contracts: (query: FindContractsRequest) => ["contracts", query],
  invoiceById: (id: number | string) => ["invoices", { id }, { isAdmin: true }],
  invoiceByIdNonAdmin: (id: number | string) => ["invoices", { id }, { isAdmin: false }],
  jobpacInvoices: (
    organisationExternalId: string | number,
    contractExternalId: string | number
  ) => ["jobpacInvoices", `${organisationExternalId}`, `${contractExternalId}`],
  jobpacProjects: (WorkId: string | number) => ["jobpacProjects", `${WorkId}`],
  jobpacOrganisations: (organisatonId: string | number) => [
    "jobpacOrganisations",
    `${organisatonId}`,
  ],
  invoicesNonAdmin: (query: InvoicesRequest = {}) => ["invoices", { isAdmin: false, ...query }],
  invoices: (query: InvoicesRequest = {}) => ["invoices", {...query}],
  invoiceActivities: (invoiceId: string) => ["activities", { invoiceId }],
  userByIdNonAdmin: (id: number | string) => ["users", { id }, { isAdmin: false}],
  usersNonAdmin: (query: FindUsersRequest) => ["users", query, { isAdmin: false}],
  exclusionsByOrganisationId: (id:number|string) => ["exclusions", {organisationId: id}],
  projectsNonAdmin: (query: QueryProjectsRequest) => ["projects", { isAdmin: false }, query],
  subcontractorsNonAdmin: () => ["subcontractors", { isAdmin: false }],
  invoicesSummaryNonAdmin: (query: InvoicesSummaryRequest) => ["invoices", "summary", query],
  findEligibleInvoicesNonAdmin: () => ["eligibleInvoices"],
  findEligibleInvoiceNonAdmin: (id: string) => ["eligibleInvoice", { id }],
};

const modifyConfig = (config: Omit<UseQueryOptions, 'queryKey'>):OverrideUseQueryOptions => {
  // This is to by pass the TS Generic inferrence trickyness
  return config as OverrideUseQueryOptions;;
}

export const useQueryOrganisationById = (
  id: number | string,
  useQueryOptions: Omit<UseQueryOptions, 'queryKey'> = {}
) => {
  const queryFn = () => {
    return api.findOrganisation(id);
  };
  const config = modifyConfig(useQueryOptions);
  return useQuery({
    ...config,
    queryFn,
    queryKey: queryKey.organisationById(id),
  });
};

export const useQueryOrganisations = () => {
  return useQuery({
    queryKey:queryKey.organisations(), 
    queryFn:() => api.findOrganisations()
  });
};

export const useQueryContracts = (
  query: FindContractsRequest,
  useQueryOptions: Omit<UseQueryOptions, 'queryKey'> = {}
) => {
  const queryFn = () => {
    return api.findOrgContracts(query);
  };
  const config = modifyConfig(useQueryOptions);
  return useQuery({
    ...config,
    queryFn,
    queryKey: queryKey.contracts(query),
  });
};

export const useQueryJobpacProjects = (
  WorkId: string | number,
  useQueryOptions: Omit<UseQueryOptions, 'queryKey'> = {}
) => {
  const queryFn = () => {
    return api.getJobpacProjects(WorkId);
  };
  const config = modifyConfig(useQueryOptions);
  return useQuery({
    ...config,
    queryFn,
    queryKey: queryKey.jobpacProjects(WorkId),
  });
};

export const useQueryJobpacOrganisations = (
  organisationId: string | number,
  useQueryOptions: Omit<UseQueryOptions, 'queryKey'> = {}
) => {
  const queryFn = () => {
    return api.getJobpacOrganisations(organisationId);
  };
  const config = modifyConfig(useQueryOptions);
  return useQuery({
    ...config,
    queryFn,
    queryKey: queryKey.jobpacOrganisations(organisationId),
  });
};

export const useQueryInvoiceById = (
  invoiceId: number|string,
  useQueryOptions: Omit<UseQueryOptions, 'queryKey'> = {}
) => {
  const queryFn = () => {
    return api.findInvoice(invoiceId);
  };
  const config = modifyConfig(useQueryOptions);
  return useQuery({
    ...config,
    queryFn,
    queryKey: queryKey.invoiceById(invoiceId),
  });
};

export const useQueryInvoiceByIdNonAdmin = (
  invoiceId: string,
  useQueryOptions: Omit<UseQueryOptions, 'queryKey'> = {}
) => {
  const queryFn = () => {
    return api.findInvoiceNonAdmin(invoiceId);
  };
  const config = modifyConfig(useQueryOptions);
  return useQuery({
    ...config,
    queryFn,
    queryKey: queryKey.invoiceByIdNonAdmin(invoiceId),
  });
};


export const useQueryInvoiceActivities = (
  invoiceId: string,
  useQueryOptions: Omit<UseQueryOptions, 'queryKey'> = {}
) => {
  const queryFn = () => {
    return api.findInvoiceActivities(invoiceId);
  };
  const config = modifyConfig(useQueryOptions);
  return useQuery({
    ...config,
    queryFn,
    queryKey: queryKey.invoiceActivities(invoiceId),
  });
};

export const useQueryInvoices = (query: InvoicesRequest = {}, useQueryOptions: Omit<UseQueryOptions, 'queryKey'> = {}) => {
  const queryFn = () => {
    return api.findInvoices(query);
  };
  const config = modifyConfig(useQueryOptions);
  return useQuery({
    ...config,
    queryFn,
    queryKey: queryKey.invoices(query),
  });
};

export const useQueryInvoicesSummaryNonAdmin = (
  query: InvoicesSummaryRequest = {},
  useQueryOptions: Omit<UseQueryOptions, 'queryKey'> = {}
) => {
  const config = modifyConfig(useQueryOptions);
  return useQuery({
    ...config,
    queryFn: () => api.invoicesSummaryNonAdmin(query),
    queryKey: queryKey.invoicesSummaryNonAdmin(query),
  });
};

export const useQueryEligibleInvoicesNonAdmin = (
  useQueryOptions: Omit<UseQueryOptions, 'queryKey'> = {}
) => {
  const config = modifyConfig(useQueryOptions);
  return useQuery({
    ...config,
    queryFn: () => api.findEligibleInvoicesNonAdmin(),
    queryKey: queryKey.findEligibleInvoicesNonAdmin(),
  });
};

export const useQueryEligibleInvoiceByIdNonAdmin = (
  id: string,
  useQueryOptions: Omit<UseQueryOptions, 'queryKey'> = {}
) => {
  const queryFn = () => {
    return api.findEligibleInvoiceNonAdmin(id);
  };
  const config = modifyConfig(useQueryOptions);
  return useQuery({
    ...config,
    queryFn,
    queryKey: queryKey.findEligibleInvoiceNonAdmin(id),
  });
};

export const useQueryInvoicesNonAdmin = (query: InvoicesRequest = {}, useQueryOptions: Omit<UseQueryOptions, 'queryKey'> = {}) => {
  const queryFn = () => {
    return api.findInvoicesNonAdmin(query);
  };
  const config = modifyConfig(useQueryOptions);
  return useQuery({
    ...config,
    queryFn,
    queryKey: queryKey.invoicesNonAdmin(query),
  });
};

export const useQueryProjectsNonAdmin = (query: QueryProjectsRequest, useQueryOptions: Omit<UseQueryOptions, 'queryKey'> = {}) => {
  const queryFn = () => {
    return api.findProjectsNonAdmin(query);
  };
  const config = modifyConfig(useQueryOptions);
  return useQuery({
    ...config,
    queryFn,
    queryKey: queryKey.projectsNonAdmin(query),
  });
};

export const useQuerySubcontractorsNonAdmin = (useQueryOptions: Omit<UseQueryOptions, 'queryKey'> = {}) => {
  const queryFn = () => {
    return api.findOrgSubcontractors();
  };
  const config = modifyConfig(useQueryOptions);
  return useQuery({
    ...config,
    queryFn,
    queryKey: queryKey.subcontractorsNonAdmin(),
  });
};

export const useQueryUserNonAdmin = (userId: string, useQueryOptions: Omit<UseQueryOptions, "queryKey" | "queryFn" | "select">) => {
  const queryFn = () => {
    return api.findUserNonAdmin(userId);
  };
  const config = modifyConfig(useQueryOptions)
  return useQuery({
    ...config,
    queryFn,
    queryKey: queryKey.userByIdNonAdmin(userId),
  });
};
export const useQueryUsersNonAdmin = (query: FindUsersRequest, useQueryOptions: Omit<UseQueryOptions, "queryKey" | "queryFn" | "select">) => {
  const queryFn = () => {
    return api.findUsersNonAdmin(query);
  };
  const config = modifyConfig(useQueryOptions)
  return useQuery({
    ...config,
    queryFn,
    queryKey: queryKey.usersNonAdmin(query),
  });
};

export const useQueryExclusionsByOrganisationId = (id: string, useQueryOptions: Omit<UseQueryOptions, 'queryKey'> = {}) => {
  const queryFn = () => {
    return api.findExclusionsByOrganisationId(id);
  };
  const config = modifyConfig(useQueryOptions)
  return useQuery({
    ...config,
    queryFn,
    queryKey: queryKey.exclusionsByOrganisationId(id),
  });
}
