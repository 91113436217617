import Request from './_request';
import { OrganisationResponse, OrganisationsResponse } from '@progresspay-next/dtos';
import { ProjectResponse } from '@progresspay-next/dtos';
import { ContractResponse, ContractsResponse } from '@progresspay-next/dtos';
import { CreateOrganisationRequest, FindOrganisationsRequest, UpdateOrganisationsRequest } from '@progresspay-next/dtos';
import { FindContractsRequest } from '@progresspay-next/dtos';
import { ImportExternalContractRequest, ImportExternalProjectRequest } from '@progresspay-next/dtos';
const { _get, _post, _put, _delete } = Request;

export interface ImportOrgProjectDTO {
  organisation_id: string;
  erp_id: string;
  name: string;
  data?: any;
}

export const createOrganisation = (createOrganisationDTO: CreateOrganisationRequest) => {
  return _post<OrganisationResponse>(
    `/admin/organisations`,
    createOrganisationDTO
  )
}

export const updateOrganisation = (updateOrganisationDTO: UpdateOrganisationsRequest) => {
  return _put<OrganisationResponse>(
    `/admin/organisations/${updateOrganisationDTO.id}`,
    updateOrganisationDTO
  )
}

export const updateOrganisationNonAdmin = (updateOrganisationDTO: UpdateOrganisationsRequest) => {
  return _put<OrganisationResponse>(
    `/organisations/${updateOrganisationDTO.id}`,
    updateOrganisationDTO
  )
}

export const findOrganisation = (id:number|string) => {
  return _get<OrganisationResponse>(
    `/admin/organisations/${id}`,
  )
}

export const findOrganisations = (findOrganisationsQuery: FindOrganisationsRequest = {}) => {
  return _get<OrganisationsResponse>(
    `/admin/organisations`,
    findOrganisationsQuery
  )
}

export const deleteOrganisation = (id: number|string) => {
  return _delete<OrganisationResponse>(
    `/admin/organisations/${id}`,
  )
}

export const importOrgProject = (importOrgProjectDTO: ImportExternalProjectRequest) => {
  return _post<ProjectResponse>(
    `/projects/import`,
    importOrgProjectDTO
  )
}

export const importOrgContract = (importOrgContractDTO: ImportExternalContractRequest) => {
  return _post<ContractResponse>(
    `/admin/integrations/jobpac/contracts/import`,
    importOrgContractDTO
  )
}

export const findOrgContracts = (findOrgContractsDTO: FindContractsRequest = {}) => {
  return _get<ContractsResponse>(
    `/admin/contracts`,
    findOrgContractsDTO
  )
}

export const findOrgSubcontractors = () => {
  return _get<any>(
    `/organisations/subcontractors`,
  )
}
