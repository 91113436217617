import { Button, Checkbox, Form, Input, Layout, message, Space, Spin, Typography } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useGlobalStore } from '../stores/global';
import { getApi } from '../utils/api';
import { ResetPasswordRequest, ValidateInviteTokenResponse } from '@progresspay-next/dtos';
import { useEffect, useState } from 'react';
import { useForm } from 'antd/es/form/Form';
import { cn } from '@/utils/ui';

interface EULAProps {
  isEmbed?: boolean
}

export const EULA: (props:EULAProps) => JSX.Element | null = (
  props
) => {
  const {isEmbed = false} = props
    return <>
      <div id="eula-container" className={
        cn(
          `prose`,
          isEmbed ? `max-w-max`:`max-h-[80vh] overflow-y-scroll pr-10`
        )
      }>
        <h1>EULA Agreement</h1>
        <h2>Acceptance of Terms</h2>
        <ol>
          <li>By accessing the Website or using the Service, you accept and agree to be bound by these terms.</li>
          <li>These terms are subject to change without prior written notice at any time, at ProgressPay’s sole discretion. The End User’s use of the services after any change in these Terms posted on the Website will be taken as acceptance of and agreement to those changes.</li>
          <li>ProgressPay simply provides a marketplace where you can seek early payment of your invoices.</li>
        </ol>
        <h2>License</h2>
        <ol start={4}>
          <li>
            Provided you comply with these terms, you will have a non-exclusive, royalty-free and non-transferable license to access and use the Service but: 
            <ol>
              <li>not to permit any third party to access the Service;</li>
              <li>not to create any derivative works based on the Service;</li>
            </ol>
          </li>
        </ol>
        <h2>Acceptance of Terms</h2>
        <ol>
          <li>By accessing the Website or using the Service, you accept and agree to be bound by these terms.</li>
          <li>These terms are subject to change without prior written notice at any time, at ProgressPay’s sole discretion. The End User’s use of the services after any change in these Terms posted on the Website will be taken as acceptance of and agreement to those changes.</li>
          <li>ProgressPay simply provides a marketplace where you can seek early payment of your invoices.</li>
        </ol>
        <h2>Acceptance of Terms</h2>
        <ol>
          <li>By accessing the Website or using the Service, you accept and agree to be bound by these terms.</li>
          <li>These terms are subject to change without prior written notice at any time, at ProgressPay’s sole discretion. The End User’s use of the services after any change in these Terms posted on the Website will be taken as acceptance of and agreement to those changes.</li>
          <li>ProgressPay simply provides a marketplace where you can seek early payment of your invoices.</li>
        </ol>
        <h2>Acceptance of Terms</h2>
        <ol>
          <li>By accessing the Website or using the Service, you accept and agree to be bound by these terms.</li>
          <li>These terms are subject to change without prior written notice at any time, at ProgressPay’s sole discretion. The End User’s use of the services after any change in these Terms posted on the Website will be taken as acceptance of and agreement to those changes.</li>
          <li>ProgressPay simply provides a marketplace where you can seek early payment of your invoices.</li>
        </ol>
        <h2>Acceptance of Terms</h2>
        <ol>
          <li>By accessing the Website or using the Service, you accept and agree to be bound by these terms.</li>
          <li>These terms are subject to change without prior written notice at any time, at ProgressPay’s sole discretion. The End User’s use of the services after any change in these Terms posted on the Website will be taken as acceptance of and agreement to those changes.</li>
          <li>ProgressPay simply provides a marketplace where you can seek early payment of your invoices.</li>
        </ol>
        <h2>Acceptance of Terms</h2>
        <ol>
          <li>By accessing the Website or using the Service, you accept and agree to be bound by these terms.</li>
          <li>These terms are subject to change without prior written notice at any time, at ProgressPay’s sole discretion. The End User’s use of the services after any change in these Terms posted on the Website will be taken as acceptance of and agreement to those changes.</li>
          <li>ProgressPay simply provides a marketplace where you can seek early payment of your invoices.</li>
        </ol>
        <h2>Acceptance of Terms</h2>
        <ol>
          <li>By accessing the Website or using the Service, you accept and agree to be bound by these terms.</li>
          <li>These terms are subject to change without prior written notice at any time, at ProgressPay’s sole discretion. The End User’s use of the services after any change in these Terms posted on the Website will be taken as acceptance of and agreement to those changes.</li>
          <li>ProgressPay simply provides a marketplace where you can seek early payment of your invoices.</li>
        </ol>
      </div>
    </>
}
