import { useState } from "react"

export const useLocalUrlChange = (): [
  (url: string) => void,
  () => void,
] => {
  const [previousUrl, setPreviousUrl] = useState<string|null>(null)

  const to = (url:string) => {
    setPreviousUrl(window.location.href);
    window.history.replaceState({
      isLocal: true
    }, '', url);
  }

  const back = () => {
    const { isLocal = false } = window.history.state;
    if (isLocal) {
      window.history.replaceState(null, '', previousUrl);
    }
  }
  return [to, back]
}