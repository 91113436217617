"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FindContractsRequestSchema = exports.UpdateContractsRequestSchema = exports.CreateContractRequestSchema = void 0;
const zod_1 = require("zod");
const contract_type_1 = require("../types/contract.type");
exports.CreateContractRequestSchema = contract_type_1.ContractTypeSchema.partial();
exports.UpdateContractsRequestSchema = contract_type_1.ContractTypeSchema.partial();
exports.FindContractsRequestSchema = zod_1.z.object({
    project_id: zod_1.z.string().nullable(),
    organisation_id: zod_1.z.string().nullable(),
    erp_id: zod_1.z.string().nullable(),
    sc_organisation_id: zod_1.z.string().nullable(),
    name: zod_1.z.string().nullable(),
}).partial();
