import { random } from "lodash";
import React, { useState } from "react";
import { ButtonWithDropdown } from "./ButtonWithDropdown";
import { DropdownPanel } from "./DropdownPanel";
import { TypeCheckboxFilterData } from "./Filter/TypeCheckbox";
import { TypeCurrencyFilterData } from "./Filter/TypeCurrency";
import { TypeInputFilterData } from "./Filter/TypeInput";
import { TypeRadioFilterData } from "./Filter/TypeRadio";
import { Pills } from "./Pills";

export enum FilterType {
  Currency,
  Radio,
  Input,
  Checkbox,
}

export type FilterData = TypeCurrencyFilterData | TypeInputFilterData | TypeRadioFilterData | TypeCheckboxFilterData;

export interface BaseFilterData<ValueType, ConfigType> {
  type: FilterType;
  key: string;
  heading: string;
  name:string;
  value:ValueType|null;
  config: ConfigType;
  onChange: (filter: {
    name: string,
    value: ValueType|null
  }) => void,
  format: (filter: {
    name: string,
    value: ValueType|null
  }) => React.ReactNode | null
}

export const Filters = {
  ButtonWithDropdown: ButtonWithDropdown,
  DropdownPanel: DropdownPanel,
  Pills: Pills
}
