"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExclusionTypeSchema = void 0;
const zod_1 = require("zod");
const exclusionEntityType_1 = require("../enums/exclusionEntityType");
exports.ExclusionTypeSchema = zod_1.z.object({
    id: zod_1.z.string(),
    organisation_id: zod_1.z.string(),
    erp_id: zod_1.z.string().nullable(),
    pas_id: zod_1.z.string().nullable(),
    entity_type: zod_1.z.nativeEnum(exclusionEntityType_1.ExclusionEntityType),
});
