import autoAnimate from "@formkit/auto-animate";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef } from "react";
import type { FilterData } from "./index";

type PillsProp = {
  filters: FilterData[];
};
export const Pills = ({ filters }: PillsProp) => {
  const filterPillsContainer = useRef(null);
  useEffect(() => {
    filterPillsContainer.current && autoAnimate(filterPillsContainer.current);
  });

  const removeFilter = (f: FilterData) => {
    f.onChange({
      name: f.name,
      value: null
    })
  };

  return (
    <div
      className="flex flex-row flex-wrap gap-4 pb-2"
      ref={filterPillsContainer}
    >
      {filters.map((f) => {
        let show = !!f.value;
        return show ? (
          <div
            key={f.key}
            className="pointer-events-none flex min-w-max flex-row flex-nowrap items-center rounded-full bg-black px-4 py-1 text-xs text-white ring-black ring-offset-2 hover:ring-1"
          >
            <span>
              {f.format({
                name: f.name,
                value: f.value as any,
              })}
            </span>
            <XMarkIcon
              className="pointer-events-auto ml-1 h-4 w-4 transform-gpu cursor-pointer hover:scale-110"
              onClick={() => removeFilter(f)}
            />
          </div>
        ) : null;
      })}
    </div>
  );
};
