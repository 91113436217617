import { cn } from "@/utils/ui";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { ArrowUp } from "lucide-react";

interface TermsCheckboxProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  termsTextRawHtml: string;
  isShowingError: boolean;
  setIsShowingTermsError: (isShowingTermsError: boolean) => void;
}

export const TermsCheckbox = ({
  checked,
  onChange,
  termsTextRawHtml,
  isShowingError,
  setIsShowingTermsError,
}: TermsCheckboxProps) => {
  const [tipContainerRef] = useAutoAnimate();

  return (
    <div
      className={cn(
        "m-2", // To avoid ring overflow hidden issue
        isShowingError && "ring ring-red-600 ring-offset-4"
      )}
    >
      <label className="flex gap-2 items-start">
        <input
          className="mt-1"
          type="checkbox"
          name="terms"
          checked={checked}
          onChange={(e) => {
            if (e.target.checked) {
              setIsShowingTermsError(false);
            }
            onChange(e);
          }}
        />
        <span
          className="[&>a]:text-blue-500"
          dangerouslySetInnerHTML={{ __html: termsTextRawHtml }}
        ></span>
      </label>
      <div ref={tipContainerRef}>
        {isShowingError && (
          <div className="text-red-600 flex flex-row gap-1 items-center">
            <ArrowUp className="h-4 w-4" />
            <span>You'll need to agree to the above to continue.</span>
          </div>
        )}
      </div>
    </div>
  );
};
