import { invoice } from '@/components/invoices/tests/DiscountWidget.mock';
import Request from './_request';
import { JobpacClaimsResponse, JobpacContractsResponse, JobpacOrganisationsResponse, JobpacProjectsResponse, JobpacSubcontractorsResponse } from '@progresspay-next/dtos';
import { ContractsResponse } from '@progresspay-next/dtos';
import { OrganisationsResponse } from '@progresspay-next/dtos';
import { ProjectsResponse } from '@progresspay-next/dtos';
import { InvoicesResponse } from '@progresspay-next/dtos';
const { _get, _post, _put, _delete } = Request;

// Get external contracts
export const getSubcontractsForAJobListing = (WorkId: string|number, Job:string|number) => {
  return _get<JobpacContractsResponse>(
    `admin/integrations/jobpac/SubcontractsForAJobListing`,
    {
      WorkId,
      Job
    }
  );
}
// Get external contracts from jobpac, those that have been imported will have id
export const getJobpacContracts = (WorkId: string|number, Job:string|number) => {
  return _get<ContractsResponse>(
    `admin/integrations/jobpac/contracts`,
    {
      WorkId,
      Job,
    }
  )
}

// Get external child organisations
export const getWorkIdListing = () => {
  return _get<JobpacOrganisationsResponse>(
    `integrations/jobpac/WorkIdListing`
  );
}

export const getJobpacOrganisations = (parentOrgId: string|number) => {
  return _get<OrganisationsResponse>(
    `admin/integrations/jobpac/organisations`,
    {
      parent_id: parentOrgId
    }
  );
}

// Get external projects
export const getJobListing = (WorkId: string|null = null) => {
  return _get<JobpacProjectsResponse>(
    `integrations/jobpac/JobListing`,
    {
      WorkId,
    }
  );
}

// Get external projects from jobpac, those that have been imported will have id
export const getJobpacProjects = (WorkId: number|string|null) => {
  return _get<ProjectsResponse>(
    `admin/integrations/jobpac/projects`,
    {
      WorkId,
    }
  )
}

// Get external sc information under a contract
export const getCreditorListing = (WorkId: string|number, Creditor: string|number) => {
  return _get<JobpacSubcontractorsResponse>(
    `admin/integrations/jobpac/CreditorListing`,
    {
      WorkId,
      Creditor
    }
  );
}

// Get external claims (in our term - invoices)
export const getSubcontractClaimHeaderListing = (WorkId: string|number, Order: string|number) => {
  return _get<JobpacClaimsResponse>(
    `admin/integrations/jobpac/SubcontractClaimHeaderListing`,
    {
      WorkId,
      Order,
    }
  )
}

// Get external claims (in our term - invoices) from jobpac, those that have been imported will have id
export const getJobpacInvoices = (WorkId: string|number, Order: string|number) => {
  return _get<InvoicesResponse>(
    `admin/integrations/jobpac/invoices`,
    {
      WorkId,
      Order,
    }
  )
}

export const getJobpacInvoiceTransactionData = (invoiceId: string) => {
  return _get<any>(
    `/admin/integrations/jobpac/invoices/${invoiceId}/transaction-data`,
  )
}

