import React from "react";

interface LabelAndValuePairsProps {
  data: {
    label: any;
    value: any;
  }[];
}

const LabelAndValuePairs: React.FC<LabelAndValuePairsProps> = ({ data }) => {
  return (
    <div className="mb-6 flex flex-row gap-6">
      <table className="w-full text-sm">
        <tbody>
          {
            data.map(({ label, value }) => {
              return <tr key={`${label}_${value}`}>
                <td className="py-2 pr-2 text-gray-500">{label}</td>
                <td className="py-2">{value}</td>
              </tr>
            })
          }
        </tbody>
      </table>
    </div>
  );
};

export default LabelAndValuePairs;
