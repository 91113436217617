import { FilterData, FilterType } from "."
import { StyledAccordion } from "../StyledAccordion"
import { Filter } from "./Filter"
import { TypeCheckboxFilterData } from "./Filter/TypeCheckbox"
import { TypeCurrencyFilterData } from "./Filter/TypeCurrency"
import { TypeInputFilterData } from "./Filter/TypeInput"
import { TypeRadioFilterData } from "./Filter/TypeRadio"

type Props = {
  filters: FilterData[],
}
const renderFilter = (f: FilterData) => {
  if (f.type == FilterType.Currency) {
    return <Filter.TypeCurrency filter={f as TypeCurrencyFilterData}/>
  } else if (f.type == FilterType.Input) {
    return <Filter.TypeInput filter={f as TypeInputFilterData}/>
  } else if (f.type == FilterType.Radio) {
    return <Filter.TypeRadio filter={f as TypeRadioFilterData}/>
  } else if (f.type == FilterType.Checkbox) {
    return <Filter.TypeCheckbox filter={f as TypeCheckboxFilterData}/>
  }
}
export const DropdownPanel = ({
  filters,
}: Props) => {
  return (<StyledAccordion 
      defaultKey={undefined}
      type="multiple"
      items={filters.map(f => {
        return {
          content:renderFilter(f),
          heading:f.heading,
          key: f.key
        }
      })}
    />)
}