import { QueryCache, QueryClient, useQueryClient } from "@tanstack/react-query"
import { App } from "antd";

const isError = (err: unknown): err is Error => err instanceof Error;

export const useGetQueryClientWithDefault = () => {
  const { message } = App.useApp();
  const queryCache = new QueryCache({
    onError(error) {
      if (isError(error)) {
        message.error(error.message)
      } else {
        message.error("The request was not successful.");
      }
    },
  });
  const queryClient = new QueryClient({
    queryCache
  })
  queryClient.setDefaultOptions({
    mutations: {
      onError(error, variables, context) {
        if (isError(error)) {
          message.error(error.message);
        } else {
          message.error("The operation was not successful.");
        }
      },
    },
  })
  return queryClient
}
