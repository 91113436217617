import { Checkbox, Radio } from "antd";
import React from "react";
import { BaseFilterData } from "..";

interface TypeRadioProps {
  filter: TypeRadioFilterData
}

export const TypeRadio: (
  props: TypeRadioProps
) => JSX.Element | null = ({
  filter
}) => {
  return (
    <div>
      {
        filter.config.options.map(option => (
          <div className="my-2" key={option.value}>
            <Radio onClick={() => {
              filter.onChange({
                name: filter.name,
                value: option.value,
              })
            }} checked={option.value == filter.value}>{option.label}</Radio>
          </div>
        ))
      }
    </div>
  );
};

export type TypeRadioValue = string|number;
export interface TypeRadioConfig {
  options: {
    label: string,
    value: string|number
  }[]
}
export type TypeRadioFilterData = BaseFilterData<TypeRadioValue, TypeRadioConfig>;