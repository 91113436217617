import { ExternalLayoutOutletContext } from "@/components/layouts/ExternalLayout";
import { FastForward } from "lucide-react";
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

interface AboutProps {}

export const About: (props: AboutProps) => JSX.Element | null = (props) => {
  const [setIsCurtainUp, setIsBarebone] =
    useOutletContext<ExternalLayoutOutletContext>();
  useEffect(() => {
    setIsBarebone(true);
  }, [setIsBarebone]);

  return (
    <div className="py-4 px-10 flex flex-row flex-nowrap gap-10 min-h-screen">
      <div className="flex-1 flex flex-col flex-nowrap justify-between">
        <div className="py-8"><img src="/images/payapps-early-payment.svg"/></div>
        <div className="text-base text-gray-500 animate-in fade-in duration-1000">
          <h1 className="text-6xl font-bold mb-8 text-black leading-normal">
            Speed up<br></br>your cashflow
          </h1>
          <p className="mb-4">
            Accelerate your cash flow by requesting early payment from your
            participating head contractors in exchange for a discount on your
            invoice.
          </p>

          <p className="mb-4">
            When you are working with a head contractor offering early payment,
            you will find your eligible invoices here, and will be able to request
            your preferred payment date and associated discount.
          </p>

          <p className="mb-4">
            There is no credit check or lengthy paperwork; setting up your account
            takes 2 minutes!
          </p>

          <p className="mb-4">
            You will be notified by email when a head contractor you are working
            with joins the program.
          </p>
        </div>
        <div className="mt-8">
          <img src="/images/power-by-pp.svg"></img>
        </div>
      </div>
      <div className="flex-1 bg-[url(/images/payapps-bg.jpg)] rounded-3xl relative">
        <img src="/images/pp-iphone.2.png" className="absolute bottom-0 -right-10 animate-in duration-500 fade-in slide-in-from-bottom-1/4"/>
        <img src="/images/pp-iphone.1.png" className="absolute top-0 -left-10 animate-in duration-500 fade-in slide-in-from-top-1/4"/>
      </div>
    </div>
  );
};
