import { CheckCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { useMutation, useQueries, useQueryClient } from "@tanstack/react-query";
import { App, Button, Modal, Tag } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import invariant from "tiny-invariant";
import { ContractResponse, ContractsResponse } from "@progresspay-next/dtos";
import { ContractType } from "@progresspay-next/dtos";
import { InvoiceResponse } from "@progresspay-next/dtos";
import { getApi } from "../../utils/api";
import {
  queryKey,
  useQueryContracts,
  useQueryOrganisationById,
} from "../../utils/query";
import { ContractsTableFactory } from "../contracts/ContractsList";
import { InvoicesTableFactory } from "@/components/invoices/InvoicesTableAntD";

interface OrganisationsFormContractsTabProps {
  id: number | string;
  updateTotalCount?: (total: number) => void;
}

interface GenericInvoiceTyInvoiceType extends InvoiceResponse {}

const claimKey = (claim: any) => {
  return `${claim?.erp_id} - ${claim?.pas_id}`;
};
const contractsTableRowKey = (record: ContractType) =>
  `${record.id} - ${record.erp_id}`;

const invoicesTableRowKey = (record: GenericInvoiceTyInvoiceType) =>
  claimKey(record);

const ExternalClaimsModal = ({
  title = "",
  isModalOpen,
  modalContent,
  afterClose,
}: {
  title: string;
  isModalOpen: boolean;
  modalContent: JSX.Element | null;
  afterClose: () => void;
}) => {
  return (
    <Modal
      title={title}
      open={isModalOpen}
      onCancel={afterClose}
      width={1000}
      footer={null}
    >
      {modalContent}
    </Modal>
  );
};

export const OrganisationsFormContractsTab: (
  props: OrganisationsFormContractsTabProps
) => JSX.Element | null = ({ id, updateTotalCount }) => {
  const { message } = App.useApp();
  const organisationQuery = useQueryOrganisationById(id);
  let organisationExternalId: string | null = null;
  if (organisationQuery.isSuccess) {
    organisationExternalId = organisationQuery.data.erp_id;
  }

  const [expandedRowKeys, setExpandedRowKey] = useState<string[]>([]);
  const queryClient = useQueryClient();

  const api = getApi();
  // Depending on whether the organistion is SC or GC, the logic to retrieve contracts / invoices are different
  const isSC =
    organisationQuery.isSuccess && organisationQuery.data.type == "SC";
  const contractsBySCQuery = useQueryContracts(
    {
      sc_organisation_id: (organisationQuery.data?.id),
    },
    {
      enabled: isSC,
    }
  );
  const scContracts = contractsBySCQuery.data || [];

  const isGC =
    organisationQuery.isSuccess && organisationQuery.data.type == "GC";
  const [selectedContractIds, setSelectedContractIds] = useState<
    (string | number)[]
  >([]);
  const [recentlyImportedInvoiceIds, setRecentlyImportedInvoiceIds] = useState<
    (string | number)[]
  >([]);
  const [isImportingInvoiceKeys, setIsImportingInvoiceKeys] = useState<
    (string | number)[]
  >([]);
  const [externalInvoices, setExternalInvoices] = useState<
    GenericInvoiceTyInvoiceType[]
  >([]);
  const contractsQuery = useQueryContracts(
    { organisation_id: String(id) },
    {
      enabled: isGC,
    }
  );
  const internalContracts = contractsQuery.data || [];
  const allInternalInvoices = internalContracts
    .map((contract) => {
      return contract.invoices as InvoiceResponse[];
    })
    .flat();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalTarget, setModalTarget] = useState<ContractType | null>(null);

  // Update the badge count
  useEffect(() => {
    if (updateTotalCount && contractsQuery.isSuccess) {
      updateTotalCount(contractsQuery.data.length);
    }
  }, [contractsQuery.data, contractsQuery.isSuccess, updateTotalCount]);

  const findContractById = (id: string | number) => {
    return internalContracts.find((c) => c.id == id);
  };

  const externalClaims = useQueries({
    queries: selectedContractIds.map((contractId) => {
      const contract = findContractById(contractId);
      invariant(
        !!organisationExternalId,
        "Organisation ERP ID should not be empty"
      );
      invariant(
        !!contract?.erp_id,
        "Contract ERP ID should not be empty"
      );
      return {
        queryKey: queryKey.jobpacInvoices(
          organisationExternalId,
          contract?.erp_id
        ),
        queryFn: () => {
          invariant(
            !!organisationExternalId,
            "Organisation ERP ID should not be empty"
          );
          return api.getJobpacInvoices(
            organisationExternalId,
            contract?.erp_id as string
          );
        },
        onSuccess: (
          data: Awaited<ReturnType<typeof api.getJobpacInvoices>>
        ) => {
          const newSelectedContractIds = [...selectedContractIds]
          newSelectedContractIds.splice(
            newSelectedContractIds.indexOf(contractId),
            1
          );
          setSelectedContractIds(newSelectedContractIds);

          setExternalInvoices([
            ...externalInvoices.filter(
              (invoice) =>
                !data.find(
                  (c) =>
                    claimKey(c) == claimKey(invoice)
                )
            ),
            ...data,
          ]);

          const rowKey = contractsTableRowKey(contract as ContractType);
          if (expandedRowKeys.indexOf(rowKey) == -1) {
            setExpandedRowKey([rowKey, ...expandedRowKeys]);
          }

          if (data.length == 0) {
            message.warning(`No external invoices could be found.`);
          } else {
            if (data.filter(invoice => !invoice.id).length == 0) {
              message.warning(`All external invoices have been imported.`)
            } else {
              setIsModalOpen(true);
            }
          }
          setModalTarget(contract);
        }
      };
    }),
  });

  const importInvoice = useMutation(
    {
      mutationFn: (payload: GenericInvoiceTyInvoiceType) => {
        return api.importInvoice(payload);
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries({ queryKey: ["contracts"]});
        message.success("The invoice has been imported successfully!");

        // Remove the one from external to internal
        const index = externalInvoices.findIndex(
          (ei) => claimKey(ei) == claimKey(data)
        );
        if (index != -1) {
          const newExternalInvoices = [...externalInvoices];
          newExternalInvoices.splice(index, 1, data);
          setExternalInvoices(newExternalInvoices);
        }

        const recentIds = new Set(recentlyImportedInvoiceIds);
        recentIds.add(data.id as string);
        setRecentlyImportedInvoiceIds([...recentIds]);
      },
    }
  );

  const handleScanExternalClaimsForContract = (record: ContractType) => {
    const ids = new Set(selectedContractIds);
    if (record.id) {
      ids.add(record.id);
    }
    setSelectedContractIds([...ids]);
  };

  const contractActionRender = (record: ContractType) => {
    // SC: No action
    if (isSC) {
      return null;
    }
    // GC:
    //   External: N/A
    //   Internal: Scan
    return record.id ? (
      <Button
        shape='round'
        type="link"
        size="small"
        icon={<SearchOutlined />}
        loading={selectedContractIds.indexOf(record.id) !== -1}
        onClick={() => {
          handleScanExternalClaimsForContract(record);
        }}
        disabled={selectedContractIds.length > 0}
      >
        Scan External Claims
      </Button>
    ) : null;
  };

  const handleImportInvoice = (record: GenericInvoiceTyInvoiceType) => {
    importInvoice.mutate(record);
  };

  const invoicesActionRender = (record: GenericInvoiceTyInvoiceType) => {
    // Highlight those recently imported
    // "Import" button for those external ones
    return record.id ? (
      recentlyImportedInvoiceIds.indexOf(record.id as string) != -1 ? (
        <Tag icon={<CheckCircleOutlined />} color="success">
          Just Imported
        </Tag>
      ) : null
    ) : (
      <Button
        shape='round'
        loading={
          !!isImportingInvoiceKeys.find(
            (id) => id == claimKey(record)
          )
        }
        size={"small"}
        type="primary"
        onClick={() => handleImportInvoice(record)}
      >
        Import
      </Button>
    );
  };

  // GC and SC have different data source
  const internalInvoicesDataSource = (record: ContractType) => {
    let dataSource:InvoiceResponse[] = [];
    if (isGC) {
      const externalRecentlyImported = externalInvoices.filter(
        (ec) =>
          ec.contract_id == record.id &&
          recentlyImportedInvoiceIds.indexOf(ec.id as string) != -1
      );
      const interalInvoicesForThisProject = allInternalInvoices.filter(
        (ic) =>
          ic.contract_id == record.id &&
          !externalRecentlyImported.find(
            (ei) => claimKey(ei) == claimKey(ic)
          )
      );
      dataSource = [
        ...externalRecentlyImported,
        ...interalInvoicesForThisProject,
      ];
    } else if (isSC) {
      const invoices = scContracts.find((scc) => scc.id == record.id)?.invoices;
      dataSource = invoices ? invoices : [];
    }
    return dataSource;
  };

  const expandedRowRender = (record: ContractType) => {
    const dataSource = internalInvoicesDataSource(record);

    return (
      <div className="table-expanded-container--indented">
        <InvoicesTableFactory
          invoices={dataSource}
          isLoading={false}
          actionColumnRender={invoicesActionRender}
          tableExtraProps={{
            rowClassName: (invoice:InvoiceResponse) =>
              invoice.id ? "internalInvoicesRow" : "externalInvoicesRow",
            rowKey: invoicesTableRowKey,
            title: () => <h4 style={{ fontWeight: "bold" }}>Invoices:</h4>,
          }}
        />
      </div>
    );
  };

  const externalModalRender = (record: ContractType) => {
    let dataSource:InvoiceResponse[] = [];
    if (isGC) {
      dataSource = externalInvoices.filter(
        (ei) => ei.contract_id == record.id && !ei.id
      );
    }

    return (
      <div>
        <InvoicesTableFactory
          invoices={dataSource}
          isLoading={false}
          actionColumnRender={invoicesActionRender}
          tableExtraProps={{
            rowClassName: (invoice:InvoiceResponse) =>
              invoice.id ? "internalInvoicesRow" : "externalInvoicesRow",
            rowKey: invoicesTableRowKey,
          }}
        />
      </div>
    );
  };

  // GC and SC have different data source
  const getContractsSource = () => {
    if (isGC) {
      return internalContracts;
    }
    if (isSC) {
      return scContracts;
    }
    return [];
  };

  const rowExpandable = (record: ContractType) => {
    const dataSource = internalInvoicesDataSource(record);
    return dataSource.length;
  };

  const isLoading =
    organisationQuery.isLoading ||
    (isGC && contractsQuery.isLoading) ||
    (isSC && contractsBySCQuery.isLoading);
  return (
    <div>
      <ContractsTableFactory
        contracts={getContractsSource()}
        isLoading={isLoading}
        actionColumnRender={contractActionRender}
        tableExtraProps={{
          rowKey: contractsTableRowKey,
          pagination: false,
          expandable: {
            rowExpandable,
            expandedRowRender,
            expandedRowKeys,
            onExpand: (expanded:any, record:any) => {
              // Toggle local state
              const thisKey = contractsTableRowKey(record);
              setExpandedRowKey(_.xor(expandedRowKeys, [thisKey]));
            },
          },
        }}
      />
      <ExternalClaimsModal
        title={`External Invoices for ${organisationQuery.data?.name}`}
        modalContent={modalTarget ? externalModalRender(modalTarget) : null}
        isModalOpen={isModalOpen}
        afterClose={() => {
          setIsModalOpen(false);
        }}
      />
    </div>
  );
};
