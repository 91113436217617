import { ProjectType, QueryProjectsRequest } from '@progresspay-next/dtos';
import Request from './_request';
import { CreateProjectRequest } from '@progresspay-next/dtos';
import { ProjectResponse } from '@progresspay-next/dtos';
const { _get, _post, _put, _delete } = Request;


export const findProjectsNonAdmin = (query?: QueryProjectsRequest) => {
  return _get<ProjectType[]>(
    `/projects`,
    query
  )
}

export const enableProjectNonAdmin = (id: number) => {
  return _put<ProjectType[]>(
    `/projects/${id}/enable`,
    {

    }
  )
}

export const disableProjectNonAdmin = (id: number) => {
  return _put<ProjectType[]>(
    `/projects/${id}/disable`,
    {

    }
  )
}

export const importProjectNonAdmin = (project: CreateProjectRequest) => {
  return _post<ProjectResponse>(
    `/projects/import`,
    project
  )
}