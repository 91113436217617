import { DiscountsType } from "@progresspay-next/dtos";
import { dynamicDiscountCalculator } from "@progresspay-next/shared";
import { AgChartsReact } from "ag-charts-react";
import moment from "moment";
import { useEffect, useState } from "react";

type ProRataDiscountsChartProp = {
  dynamicDiscounts: DiscountsType["dynamic_discounts"];
};
const getData = (dynamicDiscountsConfig: DiscountsType["dynamic_discounts"]) => {
  const data = [];
  const originalDay = moment();
  // Show 5 data points after the maximum one
  let maxDaysEarlyDefined = dynamicDiscountsConfig!.reduce((acc, curr) => {
    return curr.number_of_days_early > acc ? curr.number_of_days_early : acc;
  }, 0);
  for (let i = 0; i < 5 + maxDaysEarlyDefined; i++) {
    const discount = dynamicDiscountCalculator(
      originalDay.format("YYYY-MM-DD"),
      originalDay.subtract(i, "days").format("YYYY-MM-DD"),
      dynamicDiscountsConfig!
    );
    data.push({
      number_of_days_early: i,
      discount: discount.discount,
    });
  }
  return data;
}
export const ProRataDiscountsChart
 = (props: ProRataDiscountsChartProp) => {
  // Chart Options: Control & configure the chart
  const [chartOptions, setChartOptions] = useState({});
  useEffect(() => {
    if (Array.isArray(props.dynamicDiscounts)) {
      const options = {
        data: getData(props.dynamicDiscounts),
        series: [
          {
            type: "line",
            xKey: "number_of_days_early",
            xName: "Days Early",
            yKey: "discount",
            yName: "Early Payment Discount (%)",
            tooltip: {
              renderer: function (params: any) {
                return {
                  title: `${params.datum.number_of_days_early} days early`,
                  content: `${params.datum.discount}% discount`,
                };
              }
            },
            marker: {
              formatter: function (params: any) {
                return {
                  fill: props.dynamicDiscounts!.find(d => d.number_of_days_early == params.datum.number_of_days_early) ? 'salmon' : params.fill,
                  size: props.dynamicDiscounts!.find(d => d.number_of_days_early == params.datum.number_of_days_early) ? 12 : params.size
                };
              }
            }
          },
        ],
        axes: [
          {
            title: {
              text: "Early Payment Discount (%)",
            },
            type: "number",
            position: "left",
            label: {
              formatter: function (params: any) {
                return params.value + "%";
              },
            },
          },
          {
            title: {
              text: "Number of Days Early",
            },
            type: "number",
            position: "bottom",
            tick: {
              interval: 7,
            },
          },
        ],
      };
      setChartOptions(options);
    }
  }, [props.dynamicDiscounts]);

  return (
    // AgCharsReact component with options passed as prop
    <AgChartsReact options={chartOptions} />
  );
};
