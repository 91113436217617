import { EmbeddedMenu } from "@/components/embedded/EmbeddedMenu";
import { InvoicesTable } from "@/components/invoices/InvoicesTable";
import { InvoiceType } from "@progresspay-next/dtos";
import { useEffect, useState } from "react";
import { Section } from "../components/Section";
import {
  PageTitleSlotType,
  useInternalLayoutContext
} from "../components/layouts/InternalLayout";
import { useContextStore } from "../stores/context";
import { useQueryInvoicesNonAdmin } from "../utils/query";

interface ReportsProps {
  isEmbedded?: boolean;
}

const EarlyPaymentEmbedTitle = () => {
  return (
    <div className="mb-6 flex flex-row flex-nowrap justify-between items-center">
      <div className=" text-3xl">Early Payment Reports</div>
      <div className="">
        <EmbeddedMenu />
      </div>
    </div>
  );
};
export const Reports: (props: ReportsProps) => JSX.Element | null = ({
  isEmbedded = false,
}) => {
  const [isShowingExpired, setIsShowingExpired] = useState<boolean>(false);
  const invoicesQuery = useQueryInvoicesNonAdmin({
    include_expired: isShowingExpired,
  });
  const contextStore = useContextStore();
  contextStore.setIsEmbedded(isEmbedded);
  const isLoading = !contextStore.me || invoicesQuery.isLoading;

const setPageTitleSlotProps = useInternalLayoutContext()?.setPageTitleSlotProps;
  useEffect(() => {
    if (!isEmbedded) {
      setPageTitleSlotProps && setPageTitleSlotProps({
        type: PageTitleSlotType.SimplePageTitle,
        props: {
          title: "Early Payment Reports",
        },
      });
    }
  }, [setPageTitleSlotProps, isEmbedded]);

  return (
    <div>
      {isEmbedded && <EarlyPaymentEmbedTitle />}
      <div>
        <Section
          title={isEmbedded ? undefined : `Invoices`}
          noPadding={isEmbedded}
          noBorder
        >
          {isLoading ? null : (
            <InvoicesTable
              invoices={invoicesQuery.data as InvoiceType[]}
              isGC={
                (contextStore.me as NonNullable<typeof contextStore.me>).is_gc
              }
              isSysAdmin={
                (contextStore.me as NonNullable<typeof contextStore.me>)
                  .is_system_admin
              }
              initialIsExpiredIncluded={isShowingExpired}
              onInvoicesEvent={(eventName, payload) => {
                if (eventName === "IS_EXPIRED_INCLUDED_CHANGED") {
                  setIsShowingExpired(payload);
                }
              }}
            />
          )}
        </Section>
      </div>
    </div>
  );
};
