import { InvoiceType } from "@progresspay-next/dtos";
import { DataSection } from "./DataSection";
import { useEffect, useRef } from "react";
import autoAnimate from "@formkit/auto-animate";
import { AttachmentLink } from "../AttachmentLink";
import { DocumentIcon, PaperClipIcon } from "@heroicons/react/24/outline";
import { FileText } from "lucide-react";

type AttachmentType = {
  name: string;
  url?: string;
  attachmentId?: string;
};

export const DocumentsSection = ({
  attachments
}: { attachments: AttachmentType[] }) => {
  const parent = useRef(null);
  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  return (
    <div className="rounded-lg bg-white p-6">
      <div className="mb-6 text-xl font-bold">Documents</div>
      <div className="flex flex-wrap justify-between">
        <div className="text-sm w-full" ref={parent}>
          {attachments.map((a) => (
            <div
              className="border-t text-gray-400 first:border-t-0"
              key={`${a.url}-${a.name}`}
            >
              {a.url ? (
                <a
                  href={a.url}
                  target="_blank"
                  className="nowrap flex flex-row gap-2 py-3 items-center" rel="noreferrer"
                >
                  <FileText className="h-4 w-4 text-black" />
                  {a.name}
                </a>
              ) : (
                <AttachmentLink
                  id={a.attachmentId as string}
                  className="nowrap flex cursor-pointer flex-row gap-2 py-3 items-center"
                >
                  <FileText className="h-4 w-4 text-black" />
                  {a.name}
                </AttachmentLink>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
