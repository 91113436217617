import { InvoiceResponse } from "@progresspay-next/dtos"
import { DataSection } from "./DataSection"
import { formatCurrency } from "@progresspay-next/shared";
import moment from "moment";
import { useContextStore } from "@/stores/context";

export const EarlyPaymentRequestSummary = ({
  invoice
}:{
  invoice: InvoiceResponse
}) => {
  const contextStore = useContextStore();
  const isGC = contextStore?.me?.is_gc;
  if (!invoice.calculator) {
    return null
  }
  const data: [string,string][] = [
    [`New Invoice Value`, formatCurrency(invoice.calculator.revisedAmountInclTax)],
    [isGC ? `Total Discount Secured` : `Total Discount`, formatCurrency(invoice.calculator.discountAmountInclTax)],
    [`New Due Date`, moment(invoice.calculator.revisedDueDate).format(`Do MMM YYYY`)],
    [`Discount Rate`, invoice.calculator.discountAmountPercentage ? `${invoice.calculator.discountAmountPercentage.toFixed(2)}%`:`-%`],
  ];

  return <DataSection data={data} title="Request Details" />
}
