"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FindExclusionsRequestSchema = exports.CreateExclusionRequestSchema = void 0;
const zod_1 = require("zod");
const exclusion_type_1 = require("../types/exclusion.type");
exports.CreateExclusionRequestSchema = exclusion_type_1.ExclusionTypeSchema.omit({
    id: true
}).partial();
exports.FindExclusionsRequestSchema = zod_1.z.object({
    organisation_id: zod_1.z.string()
});
