import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { cn } from "@/utils/ui";
import { ComponentProps } from "react";

interface StyledAccordionItem {
  key: string;
  content: React.ReactNode;
  heading: React.ReactNode;
}
type StyledAccordionProps = {
  type: ComponentProps<typeof Accordion>['type'];
  defaultKey: ComponentProps<typeof Accordion>['defaultValue'];
  items: StyledAccordionItem[];
}
export const StyledAccordion = ({
  type,
  items,
  defaultKey,
}: StyledAccordionProps) => {
  return (
    <Accordion
      defaultValue={defaultKey as any}
      type={type}
      className="rounded border border-gray-300"
    >
      {items.map((item, i) => {
        return (
          <AccordionItem
            key={item.key}
            value={item.key}
            className={cn(
              i !== items.length - 1 ? `border-b border-gray-300` : ``
            )}
          >
            <AccordionTrigger className="bg-gray-200 p-4">
              {item.heading}
            </AccordionTrigger>
            <AccordionContent>
              <div className="px-4 pt-4">{item.content}</div>
            </AccordionContent>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};
