export default function LogoSVG({
  fill = 'white',
  size = 32,
}: {
  fill?: string;
  size?: number;
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5746 11.5745V20.3813H14.466H15.978C18.3919 20.3813 20.3814 18.3918 20.3814 15.9779C20.3814 13.564 18.3919 11.5745 15.978 11.5745H11.5746ZM11.5746 26.1906V32H32.0001C32.0001 25.1826 32.0001 18.3918 32.0001 11.5745H25.2092C25.8459 12.9008 26.2173 14.4128 26.2173 15.9779C26.2173 21.6015 21.6282 26.1906 16.0045 26.1906H14.4925H11.5746Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.79034 20.4255V5.79032H20.4255V0H0V20.4255H5.79034Z"
        fill={fill}
      />
    </svg>
  )
}