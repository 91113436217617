import { Checkbox } from "antd";
import React from "react";
import { BaseFilterData } from "..";

interface TypeCheckboxProps {
  filter: TypeCheckboxFilterData
}

const toggle = (ele: any, arr: any[]) => {
  if (arr.includes(ele)) {
    return arr.filter(a => a != ele)
  }
  return [...arr, ele]
}

export const TypeCheckbox: (
  props: TypeCheckboxProps
) => JSX.Element | null = ({
  filter
}) => {
  return (
    <div>
      {
        filter.config.options.map(option => (
          <div className="my-2" key={option.value}>
            <Checkbox onClick={() => {
              filter.onChange({
                name: filter.name,
                value: toggle(option.value, filter.value ? filter.value: []),
              })
            }} checked={ filter.value ? filter.value.includes(option.value) : false }>{option.label}</Checkbox>
          </div>
        ))
      }
    </div>
  );
};

export type TypeCheckboxValue = (string|number)[];
export interface TypeCheckboxConfig {
  options: {
    label: string,
    value: string|number
  }[]
}
export type TypeCheckboxFilterData = BaseFilterData<TypeCheckboxValue, TypeCheckboxConfig>;