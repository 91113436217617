import { UpdateUserRequest } from "@progresspay-next/dtos";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  App,
  Button,
  Col,
  Form,
  Input,
  Row,
  Spin,
  Tabs,
  TabsProps,
} from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  PageTitleSlotType,
  useInternalLayoutContext
} from "../components/layouts/InternalLayout";
import { getApi } from "../utils/api";
import { useQueryUserNonAdmin } from "../utils/query";

interface ProfileProps {}

const UpdateUserForm = () => {
  const { message } = App.useApp();
  const api = getApi();
  const me = useQuery({
    queryKey: ["me"],
    queryFn: api.me,
  });
  const queryClient = useQueryClient();
  const meDetails = useQueryUserNonAdmin(me.data?.id as string, {
    enabled: me.isSuccess,
  });

  const navigate = useNavigate();

  const updateUser = useMutation({
    mutationFn: (user: UpdateUserRequest) => {
      return api.updateUser(user);
    },
    onSuccess: (data, variables, context) => {
      message.success("Your account has been updated successfully.");
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });

  const onFinish = (values: any) => {
    updateUser.mutate(values);
  };
  return (
    <div>
      {me.isSuccess && meDetails.isSuccess ? (
        <Form
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{
            id: me.data.id,
            first_name: meDetails.data?.first_name,
            last_name: meDetails.data?.last_name,
            email: meDetails.data?.email,
            mobile: meDetails.data?.mobile,
          }}
        >
          <Form.Item noStyle name="id" id="userId">
            <Input type="hidden" />
          </Form.Item>

          <Form.Item label="Email" name="email">
            <Input readOnly disabled />
          </Form.Item>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="First Name" name="first_name">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Last Name" name="last_name">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label="mobile" name="mobile">
            <Input />
          </Form.Item>

          <Form.Item>
            <Button shape="round" type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Spin />
      )}
    </div>
  );
};

const items: TabsProps["items"] = [
  {
    key: "user-basic",
    label: `Basic User Info`,
    children: <UpdateUserForm />,
  },
];

export const Profile: (props: ProfileProps) => JSX.Element | null = () => {
const setPageTitleSlotProps = useInternalLayoutContext()?.setPageTitleSlotProps;
  useEffect(() => {
    setPageTitleSlotProps && setPageTitleSlotProps({
      type: PageTitleSlotType.SimplePageTitle,
      props: {
        title: "Profile",
      },
    });
  }, [setPageTitleSlotProps]);
  return (
    <div>
      <Tabs defaultActiveKey="user" items={items} />
    </div>
  );
};
