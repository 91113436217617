import { AppConfigContext } from "antd/es/app/context";
import { useContext } from "react";

export const useSetMessageDefault = () => {
  const appConfigContext = useContext(AppConfigContext);
  if (!appConfigContext.message) {
    appConfigContext.message = {};
  }
  appConfigContext.message.top = 64;
  
}