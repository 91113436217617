import * as React from "react"
import { Calendar as CalendarIcon } from "lucide-react"

import { cn } from "@/utils/ui"
import { Button } from "@/components/ui/button"
import { Calendar } from "@/components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"
import { CalendarProps } from "@/components/ui/calendar"
import moment from "moment"

interface CalendarDatePickerProp {
  value?: Date,
  onChange?: (newValue: Date|undefined) => void,
  fromDate?: Date,
  toDate?: Date,
  disabled?: CalendarProps["disabled"]
}
export function CalendarDatePicker(prop: CalendarDatePickerProp) {
  const { value, onChange, fromDate, toDate, disabled } = prop;
  const [date, setDate] = React.useState<Date|undefined>(value)
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-full justify-start text-left font-normal",
            !date && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? moment(date).format(`Do MMM YYYY`) : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={(v) => {
            setDate(v);
            onChange && onChange(v);
          }}
          initialFocus
          fromDate={fromDate}
          toDate={toDate}
          disabled={disabled}
        />
      </PopoverContent>
    </Popover>
  )
}
