"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isOperationPending = exports.isOperationSuccess = void 0;
// Status precedence should be as follows:
// Success > Pending > Failed > N/A
const isOperationSuccess = (activities, verb) => {
    return (activities.some((activity) => activity.verb === verb) ||
        activities.some((activity) => { var _a, _b; return activity.verb === `${verb}_FAILED` && ((_b = (_a = activity.metadata) === null || _a === void 0 ? void 0 : _a.error) === null || _b === void 0 ? void 0 : _b.message) === `This record already exists.`; }));
};
exports.isOperationSuccess = isOperationSuccess;
const isOperationPending = (activities, verb) => {
    const isSuccessful = (0, exports.isOperationSuccess)(activities, verb);
    return isSuccessful ? false : activities.some((activity) => { var _a, _b; return activity.verb === `${verb}_FAILED` && ((_b = (_a = activity.metadata) === null || _a === void 0 ? void 0 : _a.error) === null || _b === void 0 ? void 0 : _b.message) === `Error marking batch as complete.`; });
};
exports.isOperationPending = isOperationPending;
