import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface TableConfig {
  hidingColumns: string[];
  toggleColumn: (col: string) => void;
}

const toggleItemInArray = (item: any, items: any[]) =>
  items.includes(item) ? items.filter((i) => i != item) : [...items, item];

export const createUseTableConfig = (key: string) =>
  create<TableConfig>()(
    devtools(
      persist(
        (set) => ({
          hidingColumns: [],
          toggleColumn: (col) =>
            set((state) => ({
              hidingColumns: toggleItemInArray(col, state.hidingColumns),
            })),
        }),
        {
          name: `@progresspay-next/table-config-${key}`,
        }
      )
    )
  );
