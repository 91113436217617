import { clear } from 'console';
import {create} from 'zustand';

type InvoiceActionStoreType = {
  userActionTargetId: string;
  setUserActionTargetId: (userActionTargetId: string) => void;
  clearAll: () => void;
}
export const useInvoiceActionStore = create<InvoiceActionStoreType>((set) => ({
  userActionTargetId: "",
  setUserActionTargetId: (userActionTargetId: string) => set({userActionTargetId}),
  clearAll: () => set({ userActionTargetId: ""})
}))