import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { getApi } from "../utils/api";

export const AttachmentLink = ({ id, children, ...rest }: { id: string, children: React.ReactNode|null, [key:string]:any }) => {
  const api = getApi();
  const [enable, setEnable] = useState<boolean>(false);
  const [clicked, setClicked] = useState<boolean>(false);
  const signedUrlQuery = useQuery({
    queryKey: ['attachmentUrl', {id}],
    queryFn: () => api.getAttachmentUrlById(id),
    enabled: enable,
  });
  useEffect(() => {
    if (clicked && signedUrlQuery.data) {
      window.open(signedUrlQuery.data.url, '_blank')?.focus();
      setClicked(false);
    }
  }, [signedUrlQuery.data, clicked]);
  const go = () => {
    if (signedUrlQuery.data) {
      window.open(signedUrlQuery.data.url, '_blank')?.focus();
    } else {
      setClicked(true);
    }
  }


  return <a {...rest} onMouseEnter={() => setEnable(true)} onClick={() => go() }>{children}</a>;
};
