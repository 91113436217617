"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QueryProjectsRequestSchema = exports.UpdateProjectsRequestSchema = exports.CreateProjectRequestSchema = void 0;
const zod_1 = require("zod");
const project_type_1 = require("../types/project.type");
exports.CreateProjectRequestSchema = project_type_1.ProjectTypeSchema.partial();
exports.UpdateProjectsRequestSchema = project_type_1.ProjectTypeSchema.partial();
exports.QueryProjectsRequestSchema = zod_1.z.object({
    organisation_id: zod_1.z.string().nullish(),
    erp_id: zod_1.z.string().nullish(),
    name: zod_1.z.string().nullish(),
});
