import { InvoiceType } from "@progresspay-next/dtos";
import moment from "moment";

const lastDayNextMonth = moment().add(1, 'month').endOf('month')
const dummyInvoiceForDiscountWidget: InvoiceType = {
  id: 'uuid-abcd',
  contract_id: 'uuid-abzzd',
  erp_id: 'external-123',
  pas_id: 'pas-123',
  addendum_attachment_id: null,
  activities: [],
  original_payment_due_date: lastDayNextMonth.format('YYYY-MM-DD'),
  expired_at: null,
  revised_payment_due_date: null,
  source: null,
  invoice_status: "REQUESTED",
  discount: null,
  discounts_snapshot: null,
  created_at: `2023-05-23T20:30:20Z`,
  updated_at: `2023-05-23T20:30:20Z`,
  sc_user: null,
  external_data: {
    net_claim_incl_tax: 100000
  },
  integrations: null,
  requested_at: null,
  confirmed_at: null,
  terms_agreed_at: null,
  confirmation_required: false,
  requested_prior_pas_approval: false,
  approved_at: null,
  can_approve: null,
  is_posted_claim: null,
  valid_cost_centre: null,
  metadata: null,
  calculator: null,
}

export const dummyInvoices = {
  forDiscountWidget: dummyInvoiceForDiscountWidget
}
