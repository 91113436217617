import { EarlyPaymentRequestDrawerWithButton } from "@/components/invoices/EarlyPaymentRequestDrawerWithButton";
import { ChevronRightIcon, CogIcon } from "@heroicons/react/24/outline";
import { InvoiceResponse } from "@progresspay-next/dtos";
import { formatCurrency, invoiceHelper } from "@progresspay-next/shared";
import { Layout, Popover, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import currency from "currency.js";
import { random } from "lodash";
import { useState } from "react";
import { Link } from "react-router-dom";
import { TableCSVExport } from "../../components/TableCSVExport";
import { TableConfig } from "../../components/TableConfig";
import { FilterData, FilterType, Filters } from "../../components/filters";
import { TypeCheckboxValue } from "../../components/filters/Filter/TypeCheckbox";
import { TypeCurrencyValue } from "../../components/filters/Filter/TypeCurrency";
import { TypeInputValue } from "../../components/filters/Filter/TypeInput";
import { useContextStore } from "../../stores/context";
import { createUseTableConfig } from "../../stores/tableConfig";

const filterInvoices = (
  invoices: InvoiceResponse[],
  filters: FilterData[]
): InvoiceResponse[] => {
  let results = [...invoices];
  const filterByCheckbox = (
    value: TypeCheckboxValue,
    getValueToCheck: (invoice: InvoiceResponse) => any,
    invoices: InvoiceResponse[]
  ) => {
    if (value && value.length) {
      return invoices.filter((invoice) =>
        value.includes(getValueToCheck(invoice))
      );
    }
    return invoices;
  };
  const filterByCurrency = (
    value: TypeCurrencyValue,
    getValueToCheck: (invoice: InvoiceResponse) => any,
    invoices: InvoiceResponse[]
  ) => {
    if (value) {
      return invoices.filter((invoice) => {
        const { operator, value: v } = value;
        const valueToCheck = getValueToCheck(invoice);
        if (operator == `=`) {
          return valueToCheck == v;
        } else if (operator == ">=") {
          return valueToCheck >= v;
        } else if (operator == "<=") {
          return valueToCheck <= v;
        }
        return true;
      });
    }
    return invoices;
  };
  const filterByInput = (
    value: TypeInputValue,
    getValueToCheck: (invoice: InvoiceResponse) => any,
    invoices: InvoiceResponse[]
  ) => {
    if (value) {
      return results.filter(
        (invoice) => getValueToCheck(invoice).indexOf(String(value)) != -1
      );
    }
    return invoices;
  };

  for (let filter of filters) {
    if (filter.name == "status") {
      const value = filter.value as TypeCheckboxValue;
      results = filterByCheckbox(
        value,
        (invoice) => invoice.invoice_status,
        results
      );
    } else if (filter.name == "project") {
      const value = filter.value as TypeInputValue;
      results = filterByInput(
        value,
        (invoice) => invoice.contract?.project?.name,
        results
      );
    } else if (filter.name == "contract") {
      const value = filter.value as TypeInputValue;
      results = filterByInput(
        value,
        (invoice) => invoice.contract?.name,
        results
      );
    } else if (filter.name == "invoiceNumber") {
      const value = filter.value as TypeInputValue;
      results = filterByInput(value, (invoice) => invoice.erp_id, results);
    } else if (filter.name == "invoiceAmount") {
      const value = filter.value as TypeCurrencyValue;
      results = filterByCurrency(
        value,
        (invoice) => {
          const { net_claim_incl_tax } = invoice.external_data;
          return net_claim_incl_tax;
        },
        results
      );
    } else if (filter.name == "discountAmount") {
      const value = filter.value as TypeCurrencyValue;
      results = filterByCurrency(
        value,
        (invoice) => {
          if (invoice.calculator) {
            return invoice.calculator.discountAmountInclTax;
          }
          return 0;
        },
        results
      );
    } else if (filter.name == "subcontractor") {
      const value = filter.value as TypeInputValue;
      results = filterByInput(
        value,
        (invoice) => invoice.contract?.sc_organisation?.name,
        results
      );
    } else if (filter.name == "generalContractor") {
      const value = filter.value as TypeInputValue;
      results = filterByInput(
        value,
        (invoice) => invoice.contract?.organisation?.name,
        results
      );
    }
  }
  return results;
};

const useFilters = (): [FilterData[], (filters: FilterData[]) => void] => {
  const [filters, setFilters] = useState<FilterData[]>([
    {
      type: FilterType.Checkbox,
      heading: "Status",
      key: String(random(1000)),
      format: ({
        name,
        value,
      }: {
        name: string;
        value: TypeCheckboxValue | null;
      }) => {
        return value ? `${name}: ${value.join(", ")}` : null;
      },
      onChange: ({
        name,
        value,
      }: {
        name: string;
        value: TypeCheckboxValue | null;
      }) => {
        let toUpdate = filters.find((f) => f.name == name);
        if (toUpdate) {
          toUpdate.value = Array.isArray(value) && value.length ? value : null;
        }
        setFilters([...filters]);
      },
      name: "status",
      config: {
        options: [
          `ELIGIBLE`,
          `INELIGIBLE`,
          `INTENDED`,
          `UPDATED`,
          `AVAILABLE`,
          `REQUESTED`,
          `APPROVED`,
          `REJECTED`,
          `CANCELLED`,
          `PAID`,
        ].map((v) => ({
          label: v,
          value: v,
        })),
      },
      value: null,
    },
    {
      type: FilterType.Input,
      heading: "Project",
      key: String(random(1000)),
      format: ({
        name,
        value,
      }: {
        name: string;
        value: TypeInputValue | null;
      }) => {
        return `${name}: ${value}`;
      },
      onChange: ({
        name,
        value,
      }: {
        name: string;
        value: TypeInputValue | null;
      }) => {
        let toUpdate = filters.find((f) => f.name == name);
        if (toUpdate) {
          toUpdate.value = value;
        }
        setFilters([...filters]);
      },
      name: "project",
      config: {},
      value: null,
    },
    {
      type: FilterType.Input,
      heading: "Contract",
      key: String(random(1000)),
      format: ({
        name,
        value,
      }: {
        name: string;
        value: TypeInputValue | null;
      }) => {
        return `${name}: ${value}`;
      },
      onChange: ({
        name,
        value,
      }: {
        name: string;
        value: TypeInputValue | null;
      }) => {
        let toUpdate = filters.find((f) => f.name == name);
        if (toUpdate) {
          toUpdate.value = value;
        }
        setFilters([...filters]);
      },
      name: "contract",
      config: {},
      value: null,
    },
    {
      type: FilterType.Input,
      heading: "Invoice Number",
      key: String(random(1000)),
      format: ({
        name,
        value,
      }: {
        name: string;
        value: TypeInputValue | null;
      }) => {
        return `${name}: ${value}`;
      },
      onChange: ({
        name,
        value,
      }: {
        name: string;
        value: TypeInputValue | null;
      }) => {
        let toUpdate = filters.find((f) => f.name == name);
        if (toUpdate) {
          toUpdate.value = value;
        }
        setFilters([...filters]);
      },
      name: "invoiceNumber",
      config: {},
      value: null,
    },
    {
      type: FilterType.Input,
      heading: "Subcontractor",
      key: String(random(1000)),
      format: ({
        name,
        value,
      }: {
        name: string;
        value: TypeInputValue | null;
      }) => {
        return `${name}: ${value}`;
      },
      onChange: ({
        name,
        value,
      }: {
        name: string;
        value: TypeInputValue | null;
      }) => {
        let toUpdate = filters.find((f) => f.name == name);
        if (toUpdate) {
          toUpdate.value = value;
        }
        setFilters([...filters]);
      },
      name: "subcontractor",
      config: {},
      value: null,
    },
    {
      type: FilterType.Currency,
      heading: "Invoice Amount",
      key: String(random(1000)),
      format: ({
        name,
        value,
      }: {
        name: string;
        value: TypeCurrencyValue | null;
      }) => {
        return value
          ? `Invoice Amount ${value.operator} $${value.value}`
          : null;
      },
      onChange: ({
        name,
        value,
      }: {
        name: string;
        value: TypeCurrencyValue | null;
      }) => {
        let toUpdate = filters.find((f) => f.name == name);
        if (toUpdate) {
          toUpdate.value = value;
        }
        setFilters([...filters]);
      },
      name: "invoiceAmount",
      config: {
        operatorOptions: [
          {
            label: "=",
            value: "=",
          },
          {
            label: ">=",
            value: ">=",
          },
          {
            label: "<=",
            value: "<=",
          },
        ],
      },
      value: null,
    },
    {
      type: FilterType.Currency,
      heading: "Discount Amount",
      key: String(random(1000)),
      format: ({
        name,
        value,
      }: {
        name: string;
        value: TypeCurrencyValue | null;
      }) => {
        return value
          ? `Discount Amount ${value.operator} $${value.value}`
          : null;
      },
      onChange: ({
        name,
        value,
      }: {
        name: string;
        value: TypeCurrencyValue | null;
      }) => {
        let toUpdate = filters.find((f) => f.name == name);
        if (toUpdate) {
          toUpdate.value = value;
        }
        setFilters([...filters]);
      },
      name: "discountAmount",
      config: {
        operatorOptions: [
          {
            label: "=",
            value: "=",
          },
          {
            label: ">=",
            value: ">=",
          },
          {
            label: "<=",
            value: "<=",
          },
        ],
      },
      value: null,
    },
  ]);
  return [filters, setFilters];
};

export const InvoicesTableFactory = (props: {
  invoices: InvoiceResponse[];
  isLoading: boolean;
  actionColumnRender?: (record: InvoiceResponse) => JSX.Element | null;
  tableExtraProps?: any;
}) => {
  const {
    invoices,
    isLoading,
    actionColumnRender = null,
    tableExtraProps = {},
  } = props;
  const { isEmbedded, me } = useContextStore();
  const gc_ids = [
    ...new Set(invoices?.map((a: InvoiceResponse) => a.contract?.organisation?.id)),
  ];

  const columns: ColumnsType<InvoiceResponse> = [
    {
      title: "Status",
      key: "Status",
      dataIndex: ["invoice_status"],
    },
    {
      title: "Project Name",
      key: "Project",
      dataIndex: ["contract", "project", "name"],
    },
    {
      title: "Contract Name",
      key: "Contract",
      dataIndex: ["contract", "name"],
    },
    {
      title: "Original Due Date",
      key: "Original Due Date",
      dataIndex: ["original_payment_due_date"],
    },
    {
      title: "Revised Due Date",
      key: "Revised Due Date",
      dataIndex: ["revised_payment_due_date"],
      render(value, record, index) {
        // TODO: consider using a date/time library
        return value || "";
      },
    },
    {
      title: "Claim No",
      key: "claim_number",
      dataIndex: ["external_data", "claim_number"],
    },
    {
      title: "Invoice No",
      key: "Invoice Number",
      dataIndex: ["external_data", "pas_ref"],
    },
    {
      title: "Invoice Amount",
      key: "Invoice Amount",
      dataIndex: ["external_data", "net_claim_incl_tax"],
      render(value, record, index) {
        return currency(value || 0).format();
      },
      align: "right",
    },
    {
      title: "Discount Amount",
      key: "Discount",
      render(value, record, index) {
        if (record.calculator?.discountAmountInclTax !== undefined) {
          return currency(record.calculator.discountAmountInclTax).format();
        }
        return "";
      },
      align: "right",
    },
    {
      title: "Discount %",
      key: "Discount%",
      render(value, record, index) {
        return record.calculator?.discountAmountPercentage !== undefined && record.calculator?.discountAmountPercentage !== null
          ? `${record.calculator.discountAmountPercentage.toFixed(2)}%`
          : "";
      },
      align: "right",
    },
    {
      title: "Early Payment",
      key: "payment",
      render(value, record, index) {
        return record.calculator?.revisedAmountInclTax !== undefined
          ? formatCurrency(record.calculator?.revisedAmountInclTax)
          : "";
      },
      align: "right",
    },
  ];

  if (me?.is_gc || me?.is_system_admin) {
    // add SC column for GC
    columns.splice(1, 0, {
      title: "Subcontractor",
      key: "Subcontractor",
      dataIndex: ["contract", "sc_organisation", "name"],
    });
  }
  if (gc_ids.length > 1 || me?.is_system_admin) {
    // if multiple GCs add GC column for SC
    columns.splice(1, 0, {
      title: "General Contractor",
      key: "General Contractor",
      dataIndex: ["contract", "organisation", "name"],
    });
  }

  if (me?.is_gc || me?.is_system_admin) {
    columns.push(
      {
        title: "Fee",
        key: "Fee",
        render(value, record, index) {
          return record.calculator?.progressPayFeeInclTaxAmount !== undefined
            ? formatCurrency(record.calculator?.progressPayFeeInclTaxAmount)
            : "";
        },
        align: "right",
      },
      {
        title: "Fee %",
        key: "Fee%",
        render(value, record, index) {
          return record.calculator?.progressPayFeePercentage !== undefined
            ? `${record.calculator?.progressPayFeePercentage.toFixed(2)}%`
            : "";
        },
        align: "right",
      },
      {
        title: "Revenue",
        key: "Revenue",
        render(value, record, index) {
          return record.calculator
            ? currency(
                record.calculator?.discountAmountInclTax -
                  record.calculator?.progressPayFeeInclTaxAmount
              ).format()
            : "";
        },
        align: "right",
      }
    );
  }

  if (me?.is_system_admin) {
    columns.push({
      title: "ID",
      key: "ID",
      dataIndex: ["id"],
    });
  }

  const defaultActionColumnRender = (record: InvoiceResponse) => {
    return record.id ? (
      <div className="flex items-center gap-4">
        {isEmbedded ? (
          <Link className="text-blue-500" to={`/embed/invoices/${record.id}`}>
            <ChevronRightIcon className="h-4 w-4 stroke-[4]" />
          </Link>
        ) : (
          <>
            <EarlyPaymentRequestDrawerWithButton invoice={record} />
          </>
        )}
      </div>
    ) : null;
  };

  columns.push({
    title: "",
    key: "Action",
    align: "right",
    render: actionColumnRender
      ? (record) => {
          return (
            <>
              {actionColumnRender(record)}
              {defaultActionColumnRender(record)}
            </>
          );
        }
      : defaultActionColumnRender,
  });

  const [filters, setFilters] = useFilters();

  const getDataSource = () => {
    if (isLoading) {
      return [];
    }
    return filterInvoices(invoices, filters);
  };

  const allColumns = columns.map((c) =>
    c.title ? c.title : c.key
  ) as string[];
  const tableConfigStore = createUseTableConfig("invoices")();

  const filteredColumns = columns.filter(
    (c) =>
      !tableConfigStore.hidingColumns.includes(
        (c.title ? c.title : c.key) as string
      )
  );

  return (
    <div>
      <div className="flex flex-row flex-nowrap justify-between pb-2">
        <div className="flex-1">
          <Filters.ButtonWithDropdown filters={filters} />
        </div>
        <div className="flex flex-row flex-nowrap items-center gap-2">
          <TableCSVExport
            records={getDataSource()}
            columns={filteredColumns.filter((c) => !!c.title)}
          />
          <Popover
            placement="bottomRight"
            arrow
            content={
              <TableConfig
                allColumns={allColumns}
                alwaysShowingColumns={["Action", "Status"]}
                hidingColumns={tableConfigStore.hidingColumns}
                toggleColumn={tableConfigStore.toggleColumn}
              />
            }
            title="Configure Table Column"
            trigger="click"
          >
            <button>
              <CogIcon className="h-6 w-6" />
            </button>
          </Popover>
        </div>
      </div>

      <div className="my-2">
        <Filters.Pills filters={filters} />
      </div>

      <div>
        <Table
          dataSource={getDataSource()}
          rowKey="Order"
          size="small"
          columns={filteredColumns}
          pagination={false}
          loading={isLoading}
          {...tableExtraProps}
          className="wide"
        />
      </div>
    </div>
  );
};
