import { CursorArrowRaysIcon, CursorArrowRippleIcon } from "@heroicons/react/24/outline";

type SectionProps = {
  title?: string;
  children: React.ReactNode;
  noPadding?: boolean;
  attention?: boolean;
  noBorder?: boolean;
};
export const Section = ({
  title,
  children,
  noPadding = false,
  attention = false,
  noBorder = false,
}: SectionProps) => {
  return (
    <div className="relative">
      {title ? (
        <div className="nowrap items-center flex flex-row">
          <div className="py-2 text-xs font-bold uppercase text-gray-400">
            {title}
          </div>
        </div>
      ) : null}
      <div
        className={`bg-white ${noPadding ? `` : `p-6`} rounded-xl ${
          attention ? `ring-2 ring-inset ring-black` : ``
        } ${noBorder ? ``: `border border-gray-200`} text-base relative`}
      >
        { attention ? (
            <span className="flex h-3 w-3 absolute top-3 right-3">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-black opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-black"></span>
            </span>
          ) : null}
          <div>
            {children}
          </div>
      </div>
    </div>
  );
};
