import { DeleteOutlined, LineChartOutlined, PlusCircleOutlined } from "@ant-design/icons";
import autoAnimate from "@formkit/auto-animate";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  App,
  Button,
  DatePicker,
  Empty,
  InputNumber,
  Popconfirm,
  Spin,
  Tag,
} from "antd";
import { useEffect, useRef, useState } from "react";
import {
  DiscountsType,
  DiscountType,
  OrganisationResponse,
  DiscountsWidgetDisplayType,
} from "@progresspay-next/dtos";
import { getApi } from "../../utils/api";
import { queryKey, useQueryOrganisationById } from "../../utils/query";
import dayjs from "dayjs";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { DiscountWidget } from "../../components/invoices/DiscountWidget";
import { dummyInvoices } from "@/utils/mock";
import { momentjsFormat } from "@progresspay-next/shared";
import { cn } from "@/utils/ui";
import { ProRataDiscountsChart } from "@/components/charts/ProRataDiscountsChart";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

interface OrganisationsFormDiscountsTabProps {
  id: number | string | undefined | null;
}
interface LocalDiscountsData {
  payrun_date: string;
  discount: string | null;
  key: string;
}

type LocalDynamicDiscountsData = {
  number_of_days_early: string;
  discount: string;
  key: string;
}[];

const discountsWithKeys = (
  discounts: DiscountType[] | null | undefined
): LocalDiscountsData[] =>
  discounts
    ? discounts.map((d, i) => ({
        payrun_date: d.payrun_date,
        discount: d.discount !== null ? String(d.discount) : null,
        key: `old-${i}-${d.payrun_date}-${d.discount}`,
      }))
    : [];

const dynamicDiscountsWithKeys = (
  dynamicDiscounts: DiscountsType["dynamic_discounts"]
): LocalDynamicDiscountsData =>
  Array.isArray(dynamicDiscounts)
    ? dynamicDiscounts.map((d, i) => ({
        number_of_days_early: `${d.number_of_days_early}`,
        discount: `${d.discount}`,
        key: `old-${i}-${d.number_of_days_early}-${d.discount}`,
      }))
    : [];

const DiscountRow = ({
  discount,
  showDiscountCol = true,
  handleDelete,
  handleUpdate,
}: {
  discount: LocalDiscountsData;
  showDiscountCol?: boolean;
  handleDelete: () => void;
  handleUpdate: (discount: LocalDiscountsData) => void;
}) => {
  return (
    <div key={discount.key} className="flex border-t border-gray-100">
      <div className="w-6/12 px-4 py-2 flex-1">
        <DatePicker
          defaultValue={
            discount.payrun_date ? dayjs(discount.payrun_date) : undefined
          }
          format={momentjsFormat.date}
          onChange={(date, dateString) => {
            if (date) {
              handleUpdate({
                ...discount,
                payrun_date: date.format(momentjsFormat.dateDB),
              });
            }
          }}
        />
      </div>
      <div className={cn("w-6/12 px-4 py-2", !showDiscountCol && "hidden")}>
        <InputNumber
          addonAfter={"%"}
          min="0"
          max="100"
          defaultValue={discount.discount ? discount.discount : undefined}
          disabled={!showDiscountCol}
          onChange={(v) =>
            handleUpdate({
              ...discount,
              discount: v,
            })
          }
        />
      </div>
      <div className="w-2/12 px-4 py-2">
        <Button
          shape="round"
          type="text"
          size="small"
          onClick={() => handleDelete()}
          icon={<DeleteOutlined />}
        >
        </Button>
      </div>
    </div>
  );
};

const DynamicDiscountRow = ({
  discount,
  handleDelete,
  handleUpdate,
}: {
  discount: LocalDynamicDiscountsData[number];
  showDiscountCol?: boolean;
  handleDelete: () => void;
  handleUpdate: (discount: LocalDynamicDiscountsData[number]) => void;
}) => {
  return (
    <div key={discount.key} className="flex border-t border-gray-100">
      <div className="w-6/12 px-4 py-2 flex-1">
        <InputNumber
          defaultValue={
            discount.number_of_days_early
              ? discount.number_of_days_early
              : undefined
          }
          onChange={(v) =>
            handleUpdate({
              ...discount,
              number_of_days_early: `${v}`,
            })
          }
        />
      </div>
      <div className={cn("w-6/12 px-4 py-2")}>
        <InputNumber
          addonAfter={"%"}
          min="0"
          max="100"
          defaultValue={discount.discount ? discount.discount : undefined}
          onChange={(v) =>
            handleUpdate({
              ...discount,
              discount: `${v}`,
            })
          }
        />
      </div>
      <div className="w-2/12 px-4 py-2">
        <Button
          shape="round"
          type="text"
          size="small"
          onClick={() => handleDelete()}
          icon={<DeleteOutlined />}
        >
        </Button>
      </div>
    </div>
  );
};

export const DiscountsSetting = ({
  organisation,
  isNonAdmin = false,
}: {
  organisation: OrganisationResponse;
  isNonAdmin?: boolean;
}) => {
  const { message } = App.useApp();
  const isUsingParentDiscount = organisation.parent && !organisation.discounts;
  let defaultDiscounts: DiscountsType = {
    display_type: DiscountsWidgetDisplayType.FIXED_RATE,
    display_settings: {
      payrun_dropdown_type: "list",
      payrun_offset_days: 0,
    },
    payment_runs: [],
    dynamic_discounts: [],
  };
  let targetOrganisation = organisation;
  if (isUsingParentDiscount) {
    targetOrganisation = organisation.parent as NonNullable<
      typeof organisation.parent
    >;
  }
  if (targetOrganisation.discounts) {
    defaultDiscounts = {
      ...defaultDiscounts,
      ...targetOrganisation.discounts,
    };
  }
  const [discounts, setDiscounts] = useState<LocalDiscountsData[]>(
    discountsWithKeys(defaultDiscounts.payment_runs)
  );
  const [widgetType, setWidgetType] = useState<DiscountsWidgetDisplayType>(
    defaultDiscounts.display_type
  );
  const [payrunOffsetDays, setPayrunOffsetDays] = useState(
    defaultDiscounts.display_settings?.payrun_offset_days
      ? defaultDiscounts.display_settings?.payrun_offset_days
      : 0
  );

  const [newId, setNewId] = useState<number>(0);
  const [newProRataDiscountId, setNewProRataDiscountId] = useState<number>(0);
  const [dynamicDiscounts, setDynamicDiscounts] =
    useState<LocalDynamicDiscountsData>(
      dynamicDiscountsWithKeys(defaultDiscounts.dynamic_discounts!)
    );
  const addNew = () => {
    setDiscounts([
      {
        discount: "",
        payrun_date: "",
        key: `new-${newId}`,
      },
      ...discounts,
    ]);
    setNewId(newId + 1);
  };
  const addNewProRataDiscount = () => {
    setDynamicDiscounts([
      {
        discount: "",
        number_of_days_early: "",
        key: `new-${newProRataDiscountId}`,
      },
      ...dynamicDiscounts,
    ]);
    setNewProRataDiscountId(newProRataDiscountId + 1);
  };

  const handleDelete = (key: string) => {
    setDiscounts([...discounts.filter((d) => d.key !== key)]);
  };
  const handleDeleteDynamicDiscounts = (key: string) => {
    setDynamicDiscounts([...dynamicDiscounts.filter((d) => d.key !== key)]);
  };
  const handleUpdate = (newDiscount: LocalDiscountsData) => {
    setDiscounts(
      discounts.map((d) => {
        if (d.key == newDiscount.key) {
          return newDiscount;
        }
        return d;
      })
    );
  };
  const handleUpdateDynamicDiscounts = (
    newDiscount: LocalDynamicDiscountsData[number]
  ) => {
    setDynamicDiscounts(
      dynamicDiscounts.map((d) => {
        if (d.key == newDiscount.key) {
          return newDiscount;
        }
        return d;
      })
    );
  };

  const parent = useRef(null);
  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  const dynamicDiscountsParent = useRef(null);
  useEffect(() => {
    dynamicDiscountsParent.current &&
      autoAnimate(dynamicDiscountsParent.current);
  }, [dynamicDiscountsParent]);

  const discountsSorted = discounts.sort((a, b) =>
    dayjs(a.payrun_date).isBefore(b.payrun_date) ? 1 : -1
  );

  const dynamicDiscountsSorted = dynamicDiscounts.sort((a, b) =>
    Number(a.number_of_days_early) > Number(b.number_of_days_early) ? 1 : -1
  );

  const api = getApi();
  const queryClient = useQueryClient();
  const updateOrganisationDiscount = useMutation(
    {
      mutationFn: (payload: DiscountsType | null) => {
        if (isNonAdmin) {
          return api.updateOrganisationNonAdmin({
            id: organisation.id,
            discounts: payload ? payload : undefined,
          });
        }
        return api.updateOrganisation({
          id: organisation.id,
          discounts: payload ? payload : undefined,
        });
      },
      onSuccess: (data: OrganisationResponse) => {
        message.success("Discounts have been successfully updated");
        queryClient.invalidateQueries({ queryKey: queryKey.organisations()});

        const isUsingParentDiscount = data.parent && !data.discounts;
        setDiscounts(
          discountsWithKeys(
            isUsingParentDiscount
              ? (data.parent as NonNullable<typeof data.parent>).discounts
                  ?.payment_runs
              : data.discounts?.payment_runs
          )
        );
      },
    }
  );

  const getValidDiscounts = () => {
    // Processing
    // 1. Filter empty value
    // 2. Convert to number
    // 3. Make sure number is in range
    // 4. Sort
    let uniquePayrunDates: string[] = [];
    let validatedDiscounts = discounts
      .filter((d) => {
        if (!d.payrun_date) {
          return false;
        }
        if (uniquePayrunDates.includes(d.payrun_date)) {
          return false;
        }
        uniquePayrunDates.push(d.payrun_date);

        if (widgetType == DiscountsWidgetDisplayType.FIXED_RATE) {
          return (
            (!!d.discount || Number(d.discount) === 0) &&
            Number(d.discount) >= 0 &&
            Number(d.discount) <= 100 &&
            !!d.payrun_date
          );
        } else if (
          widgetType == DiscountsWidgetDisplayType.FLEXIBLE_RATE ||
          widgetType == DiscountsWidgetDisplayType.PRO_RATA_RATE
        ) {
          return !!d.payrun_date;
        }
        return false;
      })
      .map((d) => ({
        payrun_date: d.payrun_date,
        discount:
          d.discount !== null &&
          widgetType !== DiscountsWidgetDisplayType.FLEXIBLE_RATE
            ? Number(d.discount)
            : null,
      }))
      .sort((a, b) => (dayjs(a.payrun_date).isAfter(b.payrun_date) ? 1 : -1)); // Processing
    return validatedDiscounts;
  };

  const getValidDynamicDiscounts = () => {
    // Processing
    // 1. Filter empty value
    // 2. Convert to number
    // 3. Make sure number is in range
    // 4. Sort
    let uniqueNumberOfDaysEarly: string[] = [];
    let validatedDiscounts = dynamicDiscounts
      .filter((d) => {
        if (!d.number_of_days_early) {
          return false;
        }
        if (uniqueNumberOfDaysEarly.includes(d.number_of_days_early)) {
          return false;
        }
        uniqueNumberOfDaysEarly.push(d.number_of_days_early);

        return (
          !!d.number_of_days_early &&
          !!d.discount &&
          Number(d.discount) >= 0 &&
          Number(d.discount) <= 100
        );
      })
      .map((d) => ({
        number_of_days_early: Number(d.number_of_days_early),
        discount: Number(d.discount),
      }))
      .sort((a, b) =>
        Number(a.number_of_days_early) > Number(b.number_of_days_early) ? 1 : -1
      );
    return validatedDiscounts;
  };
  const handleSave = () => {
    const validatedDiscounts = getValidDiscounts();
    const validatedDynamicDiscounts = getValidDynamicDiscounts();
    console.log({ validatedDynamicDiscounts });
    updateOrganisationDiscount.mutate({
      display_type: widgetType,
      payment_runs: validatedDiscounts,
      display_settings: {
        payrun_offset_days: payrunOffsetDays,
      },
      dynamic_discounts: validatedDynamicDiscounts,
    });
  };

  const showDiscountCol = widgetType == DiscountsWidgetDisplayType.FIXED_RATE;

  return (
    <div>
      {/* <div className="mb-10 flex items-center justify-between gap-4">
        <h1 className="text-2xl font-bold">Is Flexible Rate</h1>
        <Switch
          className="bg-gray-400"
          checkedChildren="Yes"
          unCheckedChildren="No"
          defaultChecked={isFlexibleRate}
          onChange={(checked) => setIsFlexibleRate(checked)}
        ></Switch>
      </div> */}
      <div className="flex flex-row flex-wrap gap-8">
        <div className="flex-1">
          <h1 className="flex-0 my-6 w-full text-2xl font-bold">
            Discount Widget Settings
          </h1>

          <div className="flex flex-row gap-8">
            <div className="flex-1">
              <div className="flex-0 my-2 w-full text-lg font-bold">
                Widget Type
              </div>
              <div className="">
                <Select
                  defaultValue={widgetType}
                  onValueChange={(t) =>
                    setWidgetType(t as DiscountsWidgetDisplayType)
                  }
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Select one..." />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value={DiscountsWidgetDisplayType.FIXED_RATE}>
                      Fixed Rate
                    </SelectItem>
                    <SelectItem value={DiscountsWidgetDisplayType.FLEXIBLE_RATE}>
                      Flexible Rate
                    </SelectItem>
                    <SelectItem value={DiscountsWidgetDisplayType.PRO_RATA_RATE}>
                      Dynamic Pro Rata Rate
                    </SelectItem>
                  </SelectContent>
                </Select>
                <p className="px-2 py-1 text-sm italic text-muted-foreground">
                  Depending on the selection, the subcontractor will see different
                  widget when they request early payment.
                </p>
              </div>
            </div>
            <div className="flex-1">
              <div className="flex-0 my-2 w-full text-lg font-bold">
                Payrun Offset Days
              </div>

              <div className="">
                <Select
                  defaultValue={String(payrunOffsetDays)}
                  onValueChange={(t) => setPayrunOffsetDays(Number(t))}
                >
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Payrun Offset Days" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value={"0"}>0</SelectItem>
                    <SelectItem value={"1"}>1</SelectItem>
                    <SelectItem value={"2"}>2</SelectItem>
                    <SelectItem value={"3"}>3</SelectItem>
                  </SelectContent>
                </Select>
                <p className="px-2 py-1 text-sm italic text-muted-foreground">
                  {/* Placeholder for tooltip */}
                </p>
              </div>
            </div>
          </div>
          

          

          <div className="mt-10 mb-4 flex flex-row flex-nowrap items-center justify-between">
            <div className="flex-0 text-lg font-bold">
              Payment Runs
            </div>
            <div>
              {isUsingParentDiscount ? (
                <Tag color={"green"}>
                  Settings are inherited from parent{" "}
                  <span className="font-bold">
                    {
                      (
                        organisation.parent as NonNullable<
                          typeof organisation.parent
                        >
                      ).name
                    }
                  </span>
                </Tag>
              ) : null}
            </div>
            <div className="text-right flex gap-2">
              <Button
                type="default"
                shape="round"
                icon={<PlusCircleOutlined />}
                onClick={() => addNew()}
              >
                Add new
              </Button>
            </div>
          </div>

          <div>
            {discountsSorted.length ? (
              <div>
                <div className="my-6 border" ref={parent}>
                  <div className="flex bg-gray-100">
                    <div className="w-5/12 px-4 py-2 font-bold flex-1">
                      Payment Date
                    </div>
                    <div
                      className={cn(
                        "w-5/12 px-4 py-2 font-bold",
                        !showDiscountCol && "hidden"
                      )}
                    >
                      Discount
                    </div>
                    <div className="w-2/12 px-4 py-2 font-bold"></div>
                  </div>
                  {discountsSorted.map((d, i) => {
                    return (
                      <DiscountRow
                        showDiscountCol={showDiscountCol}
                        key={d.key}
                        discount={d}
                        handleDelete={() => handleDelete(d.key)}
                        handleUpdate={handleUpdate}
                      />
                    );
                  })}
                </div>
              </div>
            ) : (
              <>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </>
            )}
          </div>

          {widgetType == DiscountsWidgetDisplayType.PRO_RATA_RATE ? (
            <>
              <div className="mt-10 mb-4 flex flex-row flex-nowrap items-center justify-between">
                <div className="flex-0 text-lg font-bold">
                  Pro Rata Discount Configuration
                </div>
                <div>
                  {isUsingParentDiscount ? (
                    <Tag color={"green"}>
                      Settings are inherited from parent{" "}
                      <span className="font-bold">
                        {
                          (
                            organisation.parent as NonNullable<
                              typeof organisation.parent
                            >
                          ).name
                        }
                      </span>
                    </Tag>
                  ) : null}
                </div>
                <div className="text-right flex gap-2">
                  <Button
                    type="default"
                    shape="round"
                    icon={<PlusCircleOutlined />}
                    onClick={() => addNewProRataDiscount()}
                  >
                    Add new
                  </Button>

                  <Dialog>
                    <DialogTrigger asChild>
                      <Button
                        type="default"
                        shape="round"
                        icon={<LineChartOutlined />}
                      >
                        Visualise discounts
                      </Button>
                    </DialogTrigger>
                    <DialogContent wide>
                      <DialogHeader>
                        <DialogTitle>Discount Visualisation</DialogTitle>
                        <div className={`w-[80vw] mx-auto h-[clamp(200px,50vh,600px)] pt-10`}>
                          <ProRataDiscountsChart dynamicDiscounts={getValidDynamicDiscounts()}/>
                        </div>
                      </DialogHeader>
                    </DialogContent>
                  </Dialog>
                </div>
              </div>

              <div>
                {dynamicDiscountsSorted.length ? (
                  <div>
                    <div className="my-6 border" ref={dynamicDiscountsParent}>
                      <div className="flex bg-gray-100">
                        <div className="w-5/12 px-4 py-2 font-bold flex-1">
                          Number of Days Early
                        </div>
                        <div className={cn("w-5/12 px-4 py-2 font-bold")}>
                          Discount
                        </div>
                        <div className="w-2/12 px-4 py-2 font-bold"></div>
                      </div>
                      {dynamicDiscountsSorted.map((d, i) => {
                        return (
                          <DynamicDiscountRow
                            key={d.key}
                            discount={d}
                            handleDelete={() =>
                              handleDeleteDynamicDiscounts(d.key)
                            }
                            handleUpdate={handleUpdateDynamicDiscounts}
                          />
                        );
                      })}
                    </div>
                  </div>
                ) : (
                  <>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </>
                )}
              </div>
            </>
          ) : null}
        </div>
        <div className="flex-1">
          <h1 className="flex-0 mt-6 mb-6 w-full text-2xl font-bold">
            Live Preview
          </h1>

          <div className="mb-4">
            <div className="rounded-lg bg-slate-800 p-6 text-white max-w-xl">
              <DiscountWidget
                type={widgetType}
                invoice={dummyInvoices.forDiscountWidget}
                discountConfiguration={getValidDiscounts()}
                dynamicDiscountConfiguration={getValidDynamicDiscounts()}
                onChange={(discount) => {}}
              />
            </div>
          </div>
        </div>

        <div className="flex-0 w-full">
          {isUsingParentDiscount ? (
            <Popconfirm
              title="Edit the discounts will detached the discounts setup from the parent organisation, are you sure?"
              onConfirm={() => {
                handleSave();
              }}
            >
              <Button
                type="primary"
                shape="round"
                disabled={updateOrganisationDiscount.isPending}
                loading={updateOrganisationDiscount.isPending}
              >
                Save
              </Button>
            </Popconfirm>
          ) : (
            <Button
              type="primary"
              shape="round"
              onClick={() => {
                handleSave();
              }}
              disabled={updateOrganisationDiscount.isPending}
              loading={updateOrganisationDiscount.isPending}
            >
              Save
            </Button>
          )}

          {organisation.parent ? (
            <div className="text-center">
              <Button
                type="primary"
                shape="round"
                onClick={() => {
                  updateOrganisationDiscount.mutate(null);
                }}
                disabled={updateOrganisationDiscount.isPending}
                loading={updateOrganisationDiscount.isPending}
              >
                Revert to use parent discounts setup
              </Button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export const OrganisationsFormDiscountsTab: (
  props: OrganisationsFormDiscountsTabProps
) => JSX.Element | null = ({ id }) => {
  const targetOrganisation = useQueryOrganisationById(id as string | number);
  return targetOrganisation.isSuccess ? (
    <DiscountsSetting organisation={targetOrganisation.data} />
  ) : (
    <Spin />
  );
};
