"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FindOrganisationsRequestSchema = exports.UpdateOrganisationsRequestSchema = exports.CreateOrganisationRequestSchema = void 0;
const zod_1 = require("zod");
const organisation_type_1 = require("../types/organisation.type");
exports.CreateOrganisationRequestSchema = organisation_type_1.OrganisationTypeSchema.partial();
exports.UpdateOrganisationsRequestSchema = organisation_type_1.OrganisationTypeSchema.partial();
exports.FindOrganisationsRequestSchema = zod_1.z.object({
    parent_id: zod_1.z.string().nullable(),
    erp_id: zod_1.z.string().nullable(),
    name: zod_1.z.string().nullable()
}).partial();
