"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPayrunOffset = exports.getIntegrationField = exports.isPASGCPay = exports.isPASPayapps = exports.isERPVista = exports.isERPJobpac = void 0;
const isERPJobpac = (organisation) => {
    return (organisation === null || organisation === void 0 ? void 0 : organisation.erp_system) === "JOBPAC";
};
exports.isERPJobpac = isERPJobpac;
const isERPVista = (organisation) => {
    return (organisation === null || organisation === void 0 ? void 0 : organisation.erp_system) === "VISTA";
};
exports.isERPVista = isERPVista;
const isPASPayapps = (organisation) => {
    return (organisation === null || organisation === void 0 ? void 0 : organisation.erp_system) === "PAYAPPS";
};
exports.isPASPayapps = isPASPayapps;
const isPASGCPay = (organisation) => {
    return (organisation === null || organisation === void 0 ? void 0 : organisation.erp_system) === "GCPAY";
};
exports.isPASGCPay = isPASGCPay;
/**
 * Get the Integration fields considering the parent / child organisation hierarchy
 *
 * @param  {OrganisationType} organisation
 * @returns boolean
 */
const getIntegrationField = (organisation) => {
    // If the organisation is a root organisation
    const isRoot = !organisation.parent;
    if (isRoot) {
        const value = organisation.integrations;
        return {
            value,
            isValueInherited: false
        };
    }
    const parentValue = organisation.parent.integrations;
    if (parentValue && !organisation.integrations) {
        return {
            value: parentValue,
            isValueInherited: true
        };
    }
    return {
        value: organisation.integrations,
        isValueInherited: false
    };
};
exports.getIntegrationField = getIntegrationField;
/**
 * @param  {any} organisation
 * @returns number
 */
const getPayrunOffset = (organisation) => {
    var _a, _b;
    const delay = (_b = (_a = organisation === null || organisation === void 0 ? void 0 : organisation.discounts) === null || _a === void 0 ? void 0 : _a.display_settings) === null || _b === void 0 ? void 0 : _b.payrun_offset_days;
    return delay ? Number(delay) : 0;
};
exports.getPayrunOffset = getPayrunOffset;
