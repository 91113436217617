import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import { useContextStore } from '../../stores/context';
import { useGlobalStore } from '../../stores/global';
import { getApi } from '../../utils/api';

interface AuthenticatedGuardProps {
}

export const AuthenticatedGuard: (props:AuthenticatedGuardProps) => JSX.Element|null = () => {
  const api = getApi();
  const store = useGlobalStore();
  const contextStore = useContextStore();
  const navigate = useNavigate();
  // In case the token is expired.
  const me = useQuery({
    queryKey: ['me'],
    queryFn: api.me,
  });
  useEffect(() => {
    if (me.data) {
      store.setIsSystemAdmin(me.data.is_system_admin);
      contextStore.setMe(me.data);
    }
  }, [me.data]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (me.error) {
      navigate('/');
    }
  }, [me.error]); // eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    if (!store.accessToken) {
      navigate('/');
    }
  })
  
  return null;
}
