import { useQuery } from "@tanstack/react-query";
import { Layout, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect } from "react";
import {
  PageTitleSlotType,
  useInternalLayoutContext
} from "../../components/layouts/InternalLayout";
import { PageHeader } from "../../components/PageHeader";
import { getApi } from "../../utils/api";
const { Header, Content, Footer } = Layout;

type Contract = {
  id: string | number | null;
  erp_id: string | number | null;
  name: string | number | null;
};

export const ContractsTableFactory = <T extends Contract>(props: {
  contracts: T[];
  isLoading: boolean;
  actionColumnRender?: (record: T) => JSX.Element | null;
  tableExtraProps?: any;
}) => {
  const {
    contracts,
    isLoading,
    actionColumnRender = null,
    tableExtraProps = {},
  } = props;
  const columns: ColumnsType<(typeof props)["contracts"]> = [
    {
      title: "Order",
      dataIndex: "erp_id",
      key: "erp_id",
    },
    {
      title: "Status",
      dataIndex: ["metadata", "data", "ContractStatus"],
    },
    {
      title: "External Subcontractor ID",
      dataIndex: ["metadata", "data", "Creditor"],
    },
    {
      title: "Subcontractor",
      dataIndex: ["sc_organisation", "name"],
    },
    {
      title: "Contract Description",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
    },
  ];

  if (actionColumnRender) {
    columns.push({
      title: "",
      key: "action",
      align: "right",
      render: actionColumnRender,
    });
  }

  return (
    <div>
      <Table
        dataSource={isLoading ? [] : contracts}
        rowKey="Order"
        size="small"
        columns={columns}
        pagination={false}
        loading={isLoading}
        {...tableExtraProps}
      />
    </div>
  );
};

const routes = [
  {
    path: "/home",
    breadcrumbName: "Home",
  },
  {
    path: "/admin/contracts",
    breadcrumbName: "Contracts",
  },
];

export const Contracts = () => {
const setPageTitleSlotProps = useInternalLayoutContext()?.setPageTitleSlotProps;
  useEffect(() => {
    setPageTitleSlotProps && setPageTitleSlotProps({
      type: PageTitleSlotType.SimplePageTitle,
      props: {
        title: "Contracts",
      },
    });
  }, [setPageTitleSlotProps]);
  const api = getApi();
  const contracts = useQuery({
    queryKey: ["contracts"],
    queryFn: () => api.findOrgContracts({}),
  });
  return (
    <>
      <PageHeader routes={routes}></PageHeader>
      <ContractsTableFactory
        contracts={contracts.data ? contracts.data : []}
        isLoading={contracts.isLoading}
        tableExtraProps={{ rowKey: "id" }}
      />
    </>
  );
};
