import { CheckCircleOutlined, ImportOutlined } from "@ant-design/icons";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { App, Button, Col, Drawer, Modal, Row, Space, Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { OrganisationType } from "@progresspay-next/dtos";
import { getApi } from "../../utils/api";
import {
  queryKey,
  useQueryJobpacOrganisations,
  useQueryOrganisationById,
} from "../../utils/query";
import { useColumnSearchProps } from "../../utils/tables";
import { OrganisationsForm } from "./OrganisationsForm";

interface OrganisationsFormChildrenTabProps {
  id: number | string;
}

const rowKey = (record: { id: any; erp_id: any }) =>
  `${record.id} - ${record.erp_id}`;

const ExternalResourcesModal = ({
  title = "",
  isModalOpen,
  modalContent,
  afterClose,
}: {
  title?: string;
  isModalOpen: boolean;
  modalContent: JSX.Element | null;
  afterClose: () => void;
}) => {
  return (
    <Modal
      title={title}
      open={isModalOpen}
      footer={null}
      onCancel={afterClose}
      width={1000}
    >
      {modalContent}
    </Modal>
  );
};

export const OrganisationsFormChildrenTab: (
  props: OrganisationsFormChildrenTabProps
) => JSX.Element | null = ({ id }) => {
  const { message } = App.useApp();
  const api = getApi();
  const [externalChildOrgs, setExternalChildOrgs] = useState<
    OrganisationType[]
  >([]);
  const [isRefreshClicked, setIsRefreshClicked] = useState(false);

  const [isImportingIds, setIsImportingIds] = useState<(string | number)[]>([]);
  const [recentlyImportedIds, setRecentlyImportedIds] = useState<
    (string | number)[]
  >([]);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [isNewOrgFormOpen, setIsNewOrgFormOpen] = useState(false);

  const queryClient = useQueryClient();

  const targetOrganisation = useQueryOrganisationById(id);
  const internalChildOrgs = targetOrganisation.data?.children || [];

  const importExternalOrganisation = useMutation(
    {
      mutationFn: (payload: any) => {
        if (targetOrganisation?.data?.integrations?.erp?.type) {
          payload.source = targetOrganisation.data.integrations.erp.type;
        }
        return api.createOrganisation(payload);
      },
      onSuccess: (data) => {
        const erp_id = data.erp_id as string;
        queryClient.invalidateQueries({ queryKey: queryKey.organisations()});
        message.success("The organistion has been imported successfully!");
        setRecentlyImportedIds([...recentlyImportedIds, erp_id]);

        const newExternalChildOrgs = [...externalChildOrgs];
        // Replace old record with new
        newExternalChildOrgs.splice(
          externalChildOrgs.findIndex((org) => org.erp_id == erp_id),
          1,
          data
        );
        setExternalChildOrgs(newExternalChildOrgs);
      },
      onMutate: (payload) => {
        const newIsImportingIds = [...isImportingIds];
        newIsImportingIds.push(payload.erp_id);
        setIsImportingIds(newIsImportingIds);
      },
      onSettled: (data, error, payload) => {
        const index = isImportingIds.findIndex((id) => id == payload.erp_id);
        if (index != -1) {
          const newIsImportingIds = [...isImportingIds];
          newIsImportingIds.splice(index, 1);
          setIsImportingIds(newIsImportingIds);
        }
      },
    }
  );

  const handleImportExternalOrganisation = (org: any) => {
    importExternalOrganisation.mutate(org);
  };

  const jobpacOrganisationsQuery = useQueryJobpacOrganisations(id, {
    enabled: targetOrganisation.isFetchedAfterMount && isRefreshClicked,
  });
  useEffect(() => {
    if (jobpacOrganisationsQuery.data) {
      const data = jobpacOrganisationsQuery.data;
      setExternalChildOrgs(data as OrganisationType[]);
      setIsRefreshClicked(false);
      if (data.length == 0) {
        message.warning(`No external organisation could be found.`);
      } else {
        if (data.filter((org: any) => !org.id).length == 0) {
          message.warning(`All external organisations have been imported.`);
        } else {
          setIsModalOpen(true);
        }
      }
    }
  },[jobpacOrganisationsQuery.data]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns: ColumnsType<OrganisationType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => Number(a.id) - Number(b.id),
    },
    {
      title: "ERP ID",
      dataIndex: "erp_id",
      key: "erp_id",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => String(a.name).localeCompare(String(b.name)),
      sortDirections: ["descend"],
      ...useColumnSearchProps<OrganisationType>("name"),
    },
    {
      title: "ABN",
      dataIndex: "abn",
      key: "abn",
    },
    {
      title: "ACN",
      dataIndex: "acn",
      key: "acn",
    },
    {
      title: "Type",
      key: "type",
      dataIndex: "type",
      render: (_, { type }) =>
        type ? (
          <Tag color={type == "SC" ? "geekblue" : "green"}>{type}</Tag>
        ) : null,
      filters: [
        {
          text: "SC",
          value: "SC",
        },
        {
          text: "GC",
          value: "GC",
        },
      ],
      onFilter: (value, record) => record.type.indexOf(value as string) === 0,
    },
    {
      title: "",
      key: "action",
      render: (text, record) => {
        return record.id ? (
          recentlyImportedIds.find((id) => id == record.erp_id) ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Just Imported
            </Tag>
          ) : null
        ) : (
          <Button
            shape="round"
            loading={!!isImportingIds.find((id) => id == record.erp_id)}
            size={"small"}
            type="primary"
            onClick={() => {
              handleImportExternalOrganisation(record);
            }}
          >
            Import
          </Button>
        );
      },
    },
  ];

  const handleRefresh = async () => {
    if (!isRefreshClicked) {
      setIsRefreshClicked(true);
    } else {
      queryClient.invalidateQueries({ queryKey: [
        "organisations",
        "externalChildOrganisations",
      ]});
    }
  };

  const getTableDataSource = () => {
    return [
      ...externalChildOrgs,
      ...internalChildOrgs.filter(
        (org) =>
          !recentlyImportedIds.find((id) => id == org.erp_id) &&
          !externalChildOrgs.find((eo) => eo.erp_id == org.erp_id)
      ),
    ];
  };

  const getTableDataSourceExternalAndRecentlyImported = () => {
    return [
      ...externalChildOrgs.filter(
        (org) => !!recentlyImportedIds.find((id) => id == org.erp_id) || !org.id
      ),
    ];
  };
  const getTableDataSourceInternalAndRecentlyImported = () => {
    const recentlyImported = externalChildOrgs.filter(
      (org) => !!recentlyImportedIds.find((id) => id == org.erp_id)
    );
    return [
      ...recentlyImported,
      ...internalChildOrgs.filter(
        (org) => recentlyImported.filter((r) => r.id == org.id).length == 0
      ),
    ];
  };

  const isLoadingExternalChildOrganisations =
    jobpacOrganisationsQuery.isLoading &&
    jobpacOrganisationsQuery.fetchStatus !== "idle";

  const orgTables = (data: OrganisationType[], isLoading: boolean) => {
    return (
      <Table
        size="small"
        columns={columns}
        loading={isLoading}
        rowKey={rowKey}
        rowClassName={(record) => (record.id ? "internalRow" : "externalRow")}
        dataSource={data}
        pagination={false}
      />
    );
  };

  return (
    <div>
      <Row justify="space-between">
        <Col></Col>
        <Col>
          <Space>
            <Button
              type="primary"
              htmlType="submit"
              onClick={() => {
                setIsNewOrgFormOpen(true);
              }}
              loading={targetOrganisation.isLoading}
            >
              Create
            </Button>
            {targetOrganisation?.data?.integrations?.erp?.type && (
              <Button
                type="primary"
                htmlType="submit"
                onClick={handleRefresh}
                loading={isLoadingExternalChildOrganisations}
              >
                Import from {targetOrganisation?.data?.integrations?.erp?.type}
              </Button>
            )}
          </Space>
        </Col>
      </Row>
      <hr />
      {orgTables(
        getTableDataSourceInternalAndRecentlyImported(),
        targetOrganisation.isLoading || isLoadingExternalChildOrganisations
      )}
      <ExternalResourcesModal
        modalContent={orgTables(
          getTableDataSourceExternalAndRecentlyImported(),
          false
        )}
        afterClose={() => {
          setIsModalOpen(false);
        }}
        isModalOpen={isModalOpen}
        title={`External Organisations for ${targetOrganisation.data?.name}`}
      />

      {targetOrganisation.isSuccess ? (
        <Drawer
          title={`Create organisation under ${targetOrganisation.data.name}`}
          placement="right"
          open={isNewOrgFormOpen}
          onClose={()=>{setIsNewOrgFormOpen(false)}}
          maskClosable={false}
          width={"75vw"}
        >
          {isNewOrgFormOpen ? (
            <OrganisationsForm
              context="drawer"
              parentId={targetOrganisation.data.id}
              onActionSuccess={() => {
                setIsNewOrgFormOpen(false);
              }}
            />
          ) : null}
        </Drawer>
      ) : null}
    </div>
  );
};
