import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from "@/components/ui/table";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import {
  PageTitleSlotType,
  useInternalLayoutContext
} from "../../components/layouts/InternalLayout";

import { FilterData, FilterType, Filters } from "@/components/filters";
import { TypeInputValue } from "@/components/filters/Filter/TypeInput";
import { ConfirmDialog } from "@/components/ui/confirm";
import { Switch } from "@/components/ui/switch";
import { getApi } from "@/utils/api";
import {
  useQueryProjectsNonAdmin,
  useQuerySubcontractorsNonAdmin,
  useQueryUserNonAdmin
} from "@/utils/query";
import autoAnimate from "@formkit/auto-animate";
import { CreateProjectRequest, ProjectType } from "@progresspay-next/dtos";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { App } from "antd";
import { random } from "lodash";
import { BuildingIcon } from "lucide-react";
import { useEffect, useRef, useState } from "react";

interface SupplyChainsProp {}

const filterProjects = (
  projects: ProjectType[],
  filters: FilterData[]
): ProjectType[] => {
  let results = [...projects];
  const filterByInput = (
    value: TypeInputValue,
    getValueToCheck: (project: ProjectType) => any,
    projects: ProjectType[]
  ) => {
    if (value) {
      return results.filter(
        (project) =>
          getValueToCheck(project).indexOf(String(value).toLowerCase()) != -1
      );
    }
    return projects;
  };

  for (let filter of filters) {
    if (filter.name == "project") {
      const value = filter.value as TypeInputValue;
      results = filterByInput(
        value,
        (project) => project.name?.toLowerCase(),
        results
      );
    }
  }
  return results;
};

const useFilters = (): [FilterData[], (filters: FilterData[]) => void] => {
  const [filters, setFilters] = useState<FilterData[]>([
    {
      type: FilterType.Input,
      heading: "Project Name",
      key: String(random(1000)),
      format: ({
        name,
        value,
      }: {
        name: string;
        value: TypeInputValue | null;
      }) => {
        return `${name}: ${value}`;
      },
      onChange: ({
        name,
        value,
      }: {
        name: string;
        value: TypeInputValue | null;
      }) => {
        let toUpdate = filters.find((f) => f.name == name);
        if (toUpdate) {
          toUpdate.value = value;
        }
        setFilters([...filters]);
      },
      name: "project",
      config: {},
      value: null,
    },
  ]);
  return [filters, setFilters];
};

const ConfirmDisableDialog = ({
  open,
  onOpenChange,
  callback,
  title = "Disable Early Payment Confirmation",
  subTitle = "",
  content,
}: {
  open: boolean;
  onOpenChange: (v: boolean) => void;
  callback: () => void;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  content?: React.ReactNode;
}) => {
  return (
    <ConfirmDialog
      open={open}
      onOpenChange={onOpenChange}
      callback={callback}
      title={title}
    >
      <div className="text-base font-bold text-gray-400">{subTitle}</div>
      <div>{content}</div>
    </ConfirmDialog>
  );
};

const ProjectsView = () => {
  const { message } = App.useApp();

  // TODO: This needs to be better typed.
  let projects: any[] = [];
  const api = getApi();
  const me = useQuery({
    queryKey: ["me"],
    queryFn: api.me,
  });
  const apiQueryClient = useQueryClient();

  // Confirm Dialog
  const [isDialogActive, setIsDialogActive] = useState<boolean>(false);
  const [dialogSubtitle, setDialogSubTitle] = useState<React.ReactNode>("");
  const [dialogContent, setDialogContent] = useState<React.ReactNode>("");
  const [dialogCallback, setDialogCallback] = useState<() => void>(() => {});

  const projectsQuery = useQueryProjectsNonAdmin({});
  if (projectsQuery.data) {
    projects = projectsQuery.data;
  }

  const clearCache = () => {
    apiQueryClient.invalidateQueries({ queryKey: ["projects"] });
  };

  const importProjectMutation = useMutation({
    mutationFn: (projectPayload: CreateProjectRequest) => {
      return api.importProjectNonAdmin(projectPayload);
    },
    onSuccess: () => {
      clearCache();
      message.success("This project has been enabled.");
    },
  });

  const enableProjectMutation = useMutation({
    mutationFn: (id: number) => {
      return api.enableProjectNonAdmin(id);
    },
    onSuccess: () => {
      clearCache();
      message.success("This project has been enabled.");
    },
  });

  const disableProjectMutation = useMutation({
    mutationFn: (id: number) => {
      return api.disableProjectNonAdmin(id);
    },
    onSuccess: () => {
      clearCache();
      message.success("This project has been disabled.");
    },
  });

  const projectsContainer = useRef(null);
  useEffect(() => {
    projectsContainer.current && autoAnimate(projectsContainer.current);
  }, [projectsContainer]);

  const [filters, setFilters] = useFilters();
  projects = filterProjects(projects, filters);

  return (
    <>
      <ConfirmDisableDialog
        open={isDialogActive}
        onOpenChange={(v) => setIsDialogActive(v)}
        subTitle={dialogSubtitle}
        content={dialogContent}
        callback={dialogCallback}
      ></ConfirmDisableDialog>
      <div className="text-right">
        {/* <Dialog>
          <DialogTrigger className="rounded-lg border border-primary px-4 py-2">
            <div>
              <span>+ Import Project</span>
            </div>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Import Project</DialogTitle>
              <DialogDescription>
                <div className="pt-4">
                  <ExternalContractorCombobox />
                </div>
                <div className="pt-4">
                  <ExternalProjectCombobox organisationExternalId="123" />
                </div>
              </DialogDescription>
            </DialogHeader>
          </DialogContent>
        </Dialog> */}
      </div>
      <div className="my-4">
        <Filters.ButtonWithDropdown filters={filters} />
      </div>
      <div className="my-2">
        <Filters.Pills filters={filters} />
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="">Project</TableHead>
            <TableHead className="text-right">Payapps id</TableHead>
            <TableHead className="text-right">Address</TableHead>
            <TableHead className="text-right">City</TableHead>
            <TableHead className="text-right">Enable</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody ref={projectsContainer}>
          {projects.map((p) => {
            return (
              <TableRow key={`${p.id}-${p.erp_id}-${p.erp_id_2}-${p.pas_id}`}>
                <TableCell className="">{p.name}</TableCell>
                <TableCell className="text-right">{p.pas_id}</TableCell>
                <TableCell className="text-right">
                  {p.address?.street}
                </TableCell>
                <TableCell className="text-right">{p.address?.city}</TableCell>
                <TableCell className="text-right">
                  <Switch
                    checked={!!(p.id && !p.disabled_at)}
                    onCheckedChange={(a) => {
                      if (!p.id) {
                        importProjectMutation.mutate(p);
                      } else if (p.disabled_at) {
                        enableProjectMutation.mutate(p.id);
                      } else {
                        setIsDialogActive(true);
                        setDialogSubTitle(`Project: ${p.name}`);
                        setDialogContent(
                          `Are you sure you want to disable access to Early Payment for project: ${p.name}?`
                        );
                        setDialogCallback(() => {
                          return () => disableProjectMutation.mutate(p.id);
                        });
                      }
                    }}
                    disabled={!me.isSuccess || !me.data?.is_org_admin}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

const SubcontractorView = () => {
  const api = getApi();
  let subcontractors = [];
  const me = useQuery({
    queryKey: ["me"],
    queryFn: api.me,
  });
  const meDetails = useQueryUserNonAdmin(me.data?.id as string, {
    enabled: me.isSuccess,
  });

  const apiQueryClient = useQueryClient();

  const subcontractorsQuery = useQuerySubcontractorsNonAdmin();
  if (subcontractorsQuery.data) {
    subcontractors = subcontractorsQuery.data;
  }

  const clearCache = () => {
    apiQueryClient.invalidateQueries({ queryKey: ["subcontractors"] });
  };

  return <div>Under 🏗️</div>;
};

export const SupplyChains = (props: SupplyChainsProp) => {
const setPageTitleSlotProps = useInternalLayoutContext()?.setPageTitleSlotProps;
  useEffect(() => {
    setPageTitleSlotProps && setPageTitleSlotProps({
      type: PageTitleSlotType.SimplePageTitle,
      props: {
        title: "Supply Chains",
      },
    });
  }, [setPageTitleSlotProps]);
  return (
    <div>
      <Tabs defaultValue="projects" className="">
        <div className="absolute -top-20 right-0">
          <TabsList className="justify-end bg-slate-200 p-1">
            {/* <TabsTrigger value="subcontractors">
              <HammerIcon className="mr-1 h-4 w-4"></HammerIcon> Subcontractors
            </TabsTrigger> */}
            <TabsTrigger value="projects">
              <BuildingIcon className="mr-1 h-4 w-4"></BuildingIcon>Projects
            </TabsTrigger>
          </TabsList>
        </div>
        <TabsContent value="subcontractors">
          <SubcontractorView />
        </TabsContent>
        <TabsContent value="projects">
          <ProjectsView />
        </TabsContent>
      </Tabs>
    </div>
  );
};
