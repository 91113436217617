"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttachmentTypeSchema = void 0;
const zod_1 = require("zod");
exports.AttachmentTypeSchema = zod_1.z.object({
    id: zod_1.z.string(),
    uuid: zod_1.z.string(),
    name: zod_1.z.string().nullable(),
    type: zod_1.z.string().nullable(),
    size: zod_1.z.number().nullable(),
    created_at: zod_1.z.string(),
});
