import { useMutation, useQueryClient } from "@tanstack/react-query";
import { App, Button, Form, Input, Spin } from "antd";
import { getApi } from "../../utils/api";
import { useQueryOrganisationById } from "../../utils/query";
import { OrganisationFormIntegrationItems } from "../OrganisationProfile";
import { organisationHelper } from "@progresspay-next/shared";
import { useCallback, useState } from "react";
interface OrganisationsFormIntegrationTabProps {
  id: number | string | undefined | null;
}

export const OrganisationsFormIntegrationTab: (
  props: OrganisationsFormIntegrationTabProps
) => JSX.Element | null = ({ id }) => {
  const { message } = App.useApp();
  const api = getApi();
  const queryClient = useQueryClient();
  const organisationDetails = useQueryOrganisationById(id as string | number);

  const updateOrganisation = useMutation(
    {
      mutationFn: (organisation: any) => {
        return api.updateOrganisation(organisation);
      },
      onSuccess: (data, variables, context) => {
        message.success("Your organisation has been updated successfully.");
        queryClient.invalidateQueries({ queryKey: ["users"]});
        queryClient.invalidateQueries({ queryKey: ["organisations"]});
      },
    }
  );

  const [onFinishType, setOnFinishType] = useState<"set" | "reset">("set");
  const onFinish = (values: any) => {
    if (onFinishType == "set") {
      updateOrganisation.mutate(values);
    } else if (onFinishType == "reset") {
      const newValue = {
        ...values,
      };
      newValue.integrations = null;
      updateOrganisation.mutate(newValue);
    }
  };
  const [form] = Form.useForm();
  const [isIntegrationTouched, setIsIntegrationTouched] = useState(false);

  return (
    <div>
      {organisationDetails.isSuccess ? (
        <Form
          onValuesChange={(changedValues, allValues) => {
            if (changedValues.integrations) {
              setIsIntegrationTouched(true);
            }
          }}
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={(() => {
            const { isValueInherited, value } =
              organisationHelper.getIntegrationField(organisationDetails.data);
            return {
              id: id,
              pas_id: organisationDetails.data?.pas_id,
              erp_id: organisationDetails.data?.erp_id,
              erp_id_2: organisationDetails.data?.erp_id_2,
              integrations: {
                pas: {
                  type: value?.pas?.type,
                  iframe: value?.pas?.iframe,
                },
                erp: {
                  type: value?.erp?.type,
                  url: value?.erp?.url,
                  username: value?.erp?.username,
                  password: value?.erp?.password,
                  environment: value?.erp?.environment,
                  dynamic: value?.erp?.dynamic,
                },
              },
            };
          })()}
        >
          <Form.Item noStyle name="id">
            <Input type="hidden" id="organisationId" />
          </Form.Item>
          <OrganisationFormIntegrationItems
            isShowingEarlyPaymentSettingOnly={
              !!organisationDetails.data.parent
            }
            isValueInherited={(() =>
              organisationHelper.getIntegrationField(organisationDetails.data)
                .isValueInherited)()}
          />
          <Form.Item className="mt-6">
            <div className="flex flex-row flex-nowrap gap-2">
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => {
                  setOnFinishType("set");
                }}
              >
                Update
              </Button>
              {organisationDetails.data.parent ? (
                <Button
                  htmlType="submit"
                  onClick={() => {
                    setOnFinishType("reset");
                  }}
                >
                  Revert to parent setting
                </Button>
              ) : null}
            </div>
          </Form.Item>
        </Form>
      ) : (
        <Spin />
      )}
    </div>
  );
};
