import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { Spin } from "antd";
// import { Tabs } from "../../components/Tabs";
import { InvoiceDetails } from "@/components/invoices/InvoiceDetails";
import { InvoiceStatusTag } from "@/components/invoices/InvoiceStatusTag";
import { InvoiceResponse } from "@progresspay-next/dtos";
import { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { PageHeader } from "../../components/PageHeader";
import {
  PageTitleSlotType,
  useInternalLayoutContext
} from "../../components/layouts/InternalLayout";
import { useContextStore } from "../../stores/context";
import { useQueryInvoiceByIdNonAdmin } from "../../utils/query";

interface InvoiceProps {
  invoice: InvoiceResponse | { id: string | number };
  refetchInvoice?: boolean;
}

export const InvoiceEarlyPayment: (
  props: InvoiceProps
) => JSX.Element | null = ({ invoice, refetchInvoice = true }) => {
  const invoiceQuery = useQueryInvoiceByIdNonAdmin(invoice.id as string, {
    enabled: refetchInvoice,
  });

  return (
    <div>
      {refetchInvoice ? (
        invoiceQuery.isSuccess ? (
          <InvoiceDetails invoice={invoiceQuery.data} />
        ) : (
          <Spin />
        )
      ) : (
        <InvoiceDetails invoice={invoice as InvoiceResponse} />
      )}
    </div>
  );
};

const routes = [
  {
    path: "/home",
    breadcrumbName: "Home",
  },
  {
    path: "/invoices",
    breadcrumbName: "Invoices",
  },
  {
    path: "#",
    breadcrumbName: "Invoice",
  },
];

export const EarlyPaymentRequestHeaderRow = ({
  invoice,
}: {
  invoice: InvoiceResponse;
}) => {
  return (
    <div className="flex flex-row flex-nowrap items-center justify-between font-normal text-sm">
      <div className="text-2xl font-bold">Early Payment Request</div>
      <div>
        <span className="mr-2 text-gray-500">Status</span>
        <InvoiceStatusTag status={invoice.invoice_status.toLowerCase()} />
      </div>
    </div>
  );
};

export const InvoiceEarlyPaymentPage = ({
  isEmbedded = false,
}: {
  isEmbedded?: boolean;
}) => {
  const contextStore = useContextStore();
  contextStore.setIsEmbedded(isEmbedded);
  const urlParams = useParams();

  const location = useLocation();
  if (location.pathname.indexOf("admin") != -1) {
    routes[1].path = `/admin/invoices`;
  }

  const { id } = urlParams;
  const invoiceQuery = useQueryInvoiceByIdNonAdmin(id as string);

const setPageTitleSlotProps = useInternalLayoutContext()?.setPageTitleSlotProps;
  useEffect(() => {
    if (!isEmbedded) {
      setPageTitleSlotProps && setPageTitleSlotProps({
        type: PageTitleSlotType.SimplePageTitle,
        props: {
          title: "Invoice Early Payment Request",
        },
      });
    }
  }, [isEmbedded, setPageTitleSlotProps]);

  return invoiceQuery.isSuccess ? (
    <>
      <div className="mb-4 text-sm">
        <Link to="/embed/dashboard">
          <span className="flex flex-row items-center">
            <ChevronLeftIcon className="h-4 w-4" />
            <span className="ml-2">Back to dashboard</span>
          </span>
        </Link>
      </div>
      <div className="mb-6">
        <EarlyPaymentRequestHeaderRow invoice={invoiceQuery.data} />
      </div>
      {isEmbedded ? null : <PageHeader routes={routes}></PageHeader>}
      {id ? <InvoiceEarlyPayment invoice={{ id }} /> : null}
    </>
  ) : (
    <Spin></Spin>
  );
};
