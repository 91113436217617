import axios from "axios";
import { useGlobalStore } from "../../stores/global";

export const baseURL = import.meta.env.VITE_API_URL || `http://localhost:7777/api`;

const apiAxios = axios.create({
  baseURL
})

export const getHeaders = () => {
  if (typeof process !== 'undefined' && process.env.VITEST_API_TOKEN) {
    return {
      'Authorization': 'Bearer ' + process.env.VITEST_API_TOKEN
    }
  }
  return {
    'Authorization': 'Bearer ' + useGlobalStore.getState().accessToken
  }
}

const _post = <R,>(url:string, data: any):Promise<R> => {
  return apiAxios.post(url, data, {
    headers: getHeaders()
  }).then(
    (res) => {
      return res.data;
    },
    (e) => {
      throw new Error(e.response?.data?.message);
    }
  );
}

const _delete = <T,>(url:string):Promise<T> => {
  return apiAxios.delete(url, {
    headers: getHeaders()
  }).then(
    (res) => {
      return res.data;
    },
    (e) => {
      throw new Error(e.response.data.message);
    }
  );
}

const _put = <R,>(url:string, data: any):Promise<R> => {
  return apiAxios
    .put(url, data, {
      headers: getHeaders()
    })
    .then(
      (res) => {
        return res.data;
      },
      (e) => {
        throw new Error(e.response.data.message);
      }
    );
}

const _get = <R,>(url:string, query = {}):Promise<R> => {
  return apiAxios.get(url, {
    params: query,
    headers: getHeaders()
  }).then(
    (res) => {
      return res.data;
    },
    (e) => {
      throw new Error(e.response.data.message);
    }
  );
}

const request = {
  _get,
  _post,
  _put,
  _delete
}

export default request