import { Fragment } from "react";

type DataSectionProps = {
  title: string;
  data: [string, string][]
}
export const DataSection = ({
  data,
  title = '',
}: DataSectionProps) => {
  return <div className="p-6 rounded-lg bg-white">
  <div className="text-xl font-bold mb-6">{title}</div>
  <div className="flex flex-wrap justify-between">
    {
      data.map((datum, i) => {
        return <Fragment key={datum[1]}>
          { i % 2 == 1 ? (
            <div className="flex-initial">
              <div className="w-[1px] h-full mx-4 bg-gray-200"></div>
            </div>
          ) : null }
          { ( i % 2 == 0 && i > 0 ) ? (
            <div className="flex-initial w-full">
              <div className="h-[1px] my-4 bg-gray-200"></div>
            </div>
          ): null }
          <div className="flex-1">
            <div>{datum[0]}</div>
            <div className="mt-3 text-base font-bold">{datum[1]}</div>
          </div>
        </Fragment>
      })
    }
  </div>
</div>
}