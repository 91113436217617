"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsersResponseSchema = exports.UserResponseSchema = exports.UserSignUpResponseSchema = void 0;
const zod_1 = require("zod");
const user_type_1 = require("../types/user.type");
exports.UserSignUpResponseSchema = user_type_1.UserTypeSchema.extend({
    access_token: zod_1.z.string(),
});
exports.UserResponseSchema = user_type_1.UserTypeSchema;
exports.UsersResponseSchema = zod_1.z.array(user_type_1.UserTypeSchema);
