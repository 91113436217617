import { useContextStore } from "@/stores/context";
import { useRememberFilterModel } from "@/utils/ag-grid";
import { queryKey, useQueryEligibleInvoicesNonAdmin } from "@/utils/query";
import autoAnimate from "@formkit/auto-animate";
import { EligibleInvoice } from "@progresspay-next/dtos";
import { formatCurrency, momentjsFormat } from "@progresspay-next/shared";
import { useQueryClient } from "@tanstack/react-query";
import {
  ColDef,
  ColGroupDef,
  FilterChangedEvent,
  GetRowIdFunc,
  GetRowIdParams,
  GridReadyEvent,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { CheckCircleIcon, ChevronRightIcon } from "lucide-react";
import moment from "moment";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useLocation } from "react-router-dom";
import { Pills } from "../ag-grid-filters/Pills";
import { ChangeTabValueContext } from "../dashboard/Dashboard";
import { Alert, AlertDescription, AlertTitle } from "../ui/alert";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { Skeleton } from "../ui/skeleton";

const ActionsCellRenderer = (props: ICellRendererParams<EligibleInvoice>) => {
  const record = props.data;
  const { isEmbedded } = useContextStore();
  return record?.pasId ? (
    <Link
      to={
        isEmbedded
          ? `/embed/eligible-invoices/${record.pasId}`
          : `/eligible-invoices/${record.pasId}`
      }
    >
      <Button variant={"ghost"}>
        <ChevronRightIcon className="h-4 w-4 stroke-[4]" />
      </Button>
    </Link>
  ) : null;
};

const EligibleInvoiceActionAlert = () => {
  const location = useLocation();

  const [show, setShow] = useState(false);
  const [originalStatus, setOriginalStatus] = useState("");
  const parent = useRef(null);
  const [redirectId, setRedirectId] = useState<string | null>(null);
  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);
  useEffect(() => {
    if (location.state?.approvedId) {
      setShow(true);
      setRedirectId(location.state?.approvedId);
      setOriginalStatus(location.state?.originalStatus);
      window.history.replaceState({}, document.title);
    }
  }, [setShow, location.state]);
  const setTabValue = useContext(ChangeTabValueContext);
  return (
    <div ref={parent}>
      {show ? (
        <div className="mb-2">
          <Alert variant={"success"}>
            <CheckCircleIcon className="h-4 w-4" />
            <AlertTitle>Success</AlertTitle>
            <AlertDescription>
              {`${originalStatus}`.toUpperCase() === "SUBMITTED" ? (
                <p>
                  Your{" "}
                  <Link
                    className="underline font-bold"
                    to={`/embed/invoices/${redirectId}`}
                  >
                    {" "}
                    early payment intent
                  </Link>{" "}
                  has been submitted. We'll be in touch once the claim has been
                  approved in Payapps.
                </p>
              ) : null}
              {`${originalStatus}`.toUpperCase() === "APPROVED" ? (
                <p>
                  Your{" "}
                  <Link
                    className="underline font-bold"
                    to={`/embed/invoices/${redirectId}`}
                  >
                    {" "}
                    early payment request
                  </Link>{" "}
                  has been submitted for approval.
                </p>
              ) : null}
              <p>
                You can now find it in the{" "}
                <a
                  className="underline font-bold"
                  onClick={() => setTabValue("requested")}
                >
                  In Progress
                </a>{" "}
                tab.
              </p>
            </AlertDescription>
          </Alert>
        </div>
      ) : null}
    </div>
  );
};

type InvoicesTableProps = {
  invoices: EligibleInvoice[];
};
const Table = ({ invoices }: InvoicesTableProps) => {
  const agGridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState(invoices);
  useEffect(() => setRowData(invoices), [invoices]);

  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState<
    (ColDef<EligibleInvoice> | ColGroupDef<EligibleInvoice>)[]
  >([
    {
      field: "claimNumber",
      headerName: "Claim #",
      type: ["withTextFilterCol"],
    },
    {
      field: "status",
      headerName: "Payapps Status",
      type: ["withTextFilterCol"],
    },
    {
      headerName: "Subcontractor",
      field: "scName",
      type: ["withTextFilterCol"],
    },
    {
      headerName: "Project",
      field: "project",
      type: ["withTextFilterCol"],
    },
    {
      headerName: "Contract",
      field: "contract",
      type: ["withTextFilterCol"],
    },
    {
      headerName: "Original Due Date",
      valueGetter: ({ getValue, data }) =>
        data ? moment(data.originalDueDate).format(momentjsFormat.date) : "",
      filterValueGetter: ({ data }: ValueGetterParams) =>
        data ? moment(data.originalDueDate).toDate() : null,
      type: [`withDateFilterCol`],
    },
    {
      field: "amountIncludingGST",
      headerName: "Invoice Amount",
      type: ["currencyCol", "numericColumn"],
    },
    {
      field: "invoiceNumber",
      headerName: "Invoice #",
      type: ["withTextFilterCol"],
      pinned: "left",
      width: 230,
    },
    {
      colId: "actions",
      headerName: "",
      cellRenderer: ActionsCellRenderer,
      flex: 1,
      minWidth: 130,
      maxWidth: 130,
      cellStyle: { textAlign: "center" },
      resizable: false,
      pinned: "right",
    },
  ]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
    }),
    []
  );

  const customColTypes = useMemo(
    () => ({
      currencyCol: {
        valueFormatter: (params: ValueFormatterParams<EligibleInvoice>) => {
          if (params.value === null || params.value === undefined) {
            return "";
          }
          return formatCurrency(params.value);
        },
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: [
            "equals",
            "notEqual",
            "lessThan",
            "lessThanOrEqual",
            "greaterThan",
            "greaterThanOrEqual",
            "inRange",
          ],
          buttons: ["reset"],
        },
      },
      dateTimeCol: {
        valueFormatter: (params: ValueFormatterParams<EligibleInvoice>) => {
          if (
            params.value === null ||
            params.value === undefined ||
            params.value === ""
          ) {
            return "";
          }
          return moment(params.value).format(momentjsFormat.dateTime);
        },
      },
      dateCol: {
        valueFormatter: (params: ValueFormatterParams<EligibleInvoice>) => {
          if (
            params.value === null ||
            params.value === undefined ||
            params.value === ""
          ) {
            return "";
          }
          return moment(params.value).format(momentjsFormat.date);
        },
      },
      percentageCol: {
        valueFormatter: (params: ValueFormatterParams<EligibleInvoice>) => {
          if (params.value === null || params.value === undefined) {
            return "";
          }
          return `${params.value.toFixed(2)}%`;
        },
        filter: "agNumberColumnFilter",
        filterParams: {
          filterOptions: [
            "equals",
            "notEqual",
            "lessThan",
            "lessThanOrEqual",
            "greaterThan",
            "greaterThanOrEqual",
            "inRange",
          ],
          buttons: ["reset"],
        },
      },
      withTextFilterCol: {
        filter: true,
        filterParams: {
          filterOptions: [
            "contains",
            "notContains",
            "equals",
            "notEqual",
            "blank",
            "notBlank",
          ],
          buttons: ["reset"],
        },
      },
      withDateFilterCol: {
        filter: "agDateColumnFilter",
        filterParams: {
          buttons: ["reset"],
          comparator: (filterLocalDateAtMidnight: Date, cellValue: any) => {
            const dateAsString = cellValue;

            if (dateAsString == null) {
              return 0;
            }

            const cellDate = moment(dateAsString);

            // Now that both parameters are Date objects, we can compare
            if (cellDate.isBefore(moment(filterLocalDateAtMidnight), "days")) {
              return -1;
            } else if (
              cellDate.isAfter(moment(filterLocalDateAtMidnight), "days")
            ) {
              return 1;
            }
            return 0;
          },
        },
      },
    }),
    []
  );

  const { getFilterModel, setFilterModel } = useRememberFilterModel(
    "dashboard-eligible-invoices"
  );

  const onGridReady = useCallback(
    (e: GridReadyEvent) => {
      const allCols = e.api.getColumns();
      if (allCols) {
        e.api.sizeColumnsToFit();
      }
      // Resume filters in the localstorage
      if (getFilterModel()) {
        e.api.setFilterModel(getFilterModel());
      }
    },
    [getFilterModel]
  );

  const [filterPills, setFilterPills] = useState<
    { key: string; text: string }[]
  >([]);

  const onFilterChanged = useCallback(
    (e: FilterChangedEvent) => {
      const filterModel = e.api.getFilterModel();
      const all = [];
      for (let colKey in filterModel) {
        const col = e.columnApi.getColumn(colKey);
        if (col) {
          all.push({
            key: col.getColId(),
            text: `Filter: ${col.getColDef().headerName || colKey}`,
          });
        }
      }
      setFilterPills(all);
      // Store filters in the localstorage
      setFilterModel(filterModel);
    },
    [setFilterModel]
  );

  const onFilterRemoved = (key: string) => {
    const gridApi = agGridRef.current!.api;
    const currentModel = gridApi.getFilterModel();
    delete currentModel[key];
    gridApi.setFilterModel(currentModel);
  };

  const getRowId = useMemo<GetRowIdFunc>(() => {
    return (params: GetRowIdParams<EligibleInvoice>) => `${params.data.pasId}`;
  }, []);

  return (
    <div>
      <EligibleInvoiceActionAlert />

      <div className="mb-2 flex flex-row flex-nowrap justify-between items-center">
        <div className="flex-1">
          <Input
            className="max-w-[400px]"
            placeholder="Quick search..."
            onChange={(e) => {
              agGridRef.current?.api.setQuickFilter(e.target.value);
            }}
          ></Input>
        </div>
        <div className="flex-0"></div>
      </div>
      <div>
        <Pills filters={filterPills} onFilterRemoved={onFilterRemoved}></Pills>
      </div>
      <div className="ag-theme-alpine w-full h-[40vh]">
        <AgGridReact
          ref={agGridRef}
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          columnTypes={customColTypes}
          onGridReady={onGridReady}
          onFilterChanged={onFilterChanged}
          suppressColumnVirtualisation // This is to resize cols not in viewport yet (with a performance cost)
          getRowId={getRowId}
        />
      </div>
    </div>
  );
};

export const EligibleInvoices = () => {
  const { data: eligibleInvoices } = useQueryEligibleInvoicesNonAdmin({
    staleTime: 1000 * 60 * 5, // 5 min stale time
  });
  const queryCient = useQueryClient();

  useEffect(() => {
    if (eligibleInvoices) {
      eligibleInvoices.forEach((invoice) => {
        // Cache it for individual eligible invoice query
        queryCient.setQueryData(
          queryKey.findEligibleInvoiceNonAdmin(invoice.pasId),
          invoice
        );
      });
    }
  }, [queryCient, eligibleInvoices]);
  return eligibleInvoices === undefined ? (
    <Skeleton className="w-full h-12 rounded-xl" />
  ) : (
    <Table invoices={eligibleInvoices!} />
  );
};
