"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateInviteTokenResponseSchema = exports.MeResponseSchema = exports.DecodeInviteTokenResponseSchema = exports.ForgotPasswordResponseSchema = exports.ResetPasswordResponseSchema = exports.LoginResponseSchema = void 0;
const zod_1 = require("zod");
exports.LoginResponseSchema = zod_1.z.object({
    id: zod_1.z.string(),
    organisation_id: zod_1.z.string(),
    organisation: zod_1.z.any().optional(),
    email: zod_1.z.string(),
    access_token: zod_1.z.string(),
    first_name: zod_1.z.string(),
    last_name: zod_1.z.string()
});
exports.ResetPasswordResponseSchema = exports.LoginResponseSchema;
exports.ForgotPasswordResponseSchema = zod_1.z.boolean();
exports.DecodeInviteTokenResponseSchema = zod_1.z.object({
    invoice_id: zod_1.z.string().nullable(),
    user: exports.LoginResponseSchema.nullable()
});
exports.MeResponseSchema = zod_1.z.object({
    id: zod_1.z.string(),
    first_name: zod_1.z.string(),
    last_name: zod_1.z.string(),
    first_last: zod_1.z.string(),
    email: zod_1.z.string(),
    mobile: zod_1.z.string().nullable(),
    phone: zod_1.z.string().nullable(),
    organisation_id: zod_1.z.string(),
    organisation: zod_1.z.any(),
    is_system_admin: zod_1.z.boolean(),
    is_org_admin: zod_1.z.boolean(),
    is_gc: zod_1.z.boolean(),
    pas: zod_1.z.string().nullable(),
    erp: zod_1.z.string().nullable(),
    created_at: zod_1.z.date(),
    updated_at: zod_1.z.date(),
    disabled_at: zod_1.z.date().nullable(),
    verified_at: zod_1.z.date().nullable(),
    terms_accepted: zod_1.z.boolean(),
    terms_accepted_at: zod_1.z.date().nullable(),
});
exports.ValidateInviteTokenResponseSchema = zod_1.z.object({
    token: zod_1.z.string(),
    first_name: zod_1.z.string(),
    last_name: zod_1.z.string(),
    first_last: zod_1.z.string(),
    email: zod_1.z.string(),
});
