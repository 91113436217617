"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityVerb = void 0;
var ActivityVerb;
(function (ActivityVerb) {
    ActivityVerb["CREATE"] = "CREATE";
    ActivityVerb["UPDATE"] = "UPDATE";
    ActivityVerb["DELETE"] = "DELETE";
    ActivityVerb["EXPIRED"] = "EXPIRED";
    ActivityVerb["THANK_YOU_FOR_EARLY_PAYMENT_INTEREST_SC_SENT"] = "THANK_YOU_FOR_EARLY_PAYMENT_INTEREST_SC_SENT";
    ActivityVerb["THANK_YOU_FOR_EARLY_PAYMENT_INTENT_SC_SENT"] = "THANK_YOU_FOR_EARLY_PAYMENT_INTENT_SC_SENT";
    ActivityVerb["ELIGIBLE_NOTIFICATION_SENT"] = "ELIGIBLE_NOTIFICATION_SENT";
    ActivityVerb["THANK_YOU_FOR_EARLY_PAYMENT_REQUEST_SC_SENT"] = "THANK_YOU_FOR_EARLY_PAYMENT_REQUEST_SC_SENT";
    ActivityVerb["EARLY_PAYMENT_REQUESTED_GC_SENT"] = "EARLY_PAYMENT_REQUESTED_GC_SENT";
    ActivityVerb["EARLY_PAYMENT_REQUEST_APPROVED_SC_SENT"] = "EARLY_PAYMENT_REQUEST_APPROVED_SC_SENT";
    ActivityVerb["EARLY_PAYMENT_REQUEST_REJECTED_SC_SENT"] = "EARLY_PAYMENT_REQUEST_REJECTED_SC_SENT";
    ActivityVerb["EARLY_PAYMENT_REQUEST_PAID_SC_SENT"] = "EARLY_PAYMENT_REQUEST_PAID_SC_SENT";
    ActivityVerb["EARLY_PAYMENT_REQUEST_CONFIRMATION_REQUEST_SENT"] = "EARLY_PAYMENT_REQUEST_CONFIRMATION_REQUEST_SENT";
    ActivityVerb["EARLY_PAYMENT_REQUEST_CONFIRMATION_APPROVAL_SENT"] = "EARLY_PAYMENT_REQUEST_CONFIRMATION_APPROVAL_SENT";
    ActivityVerb["ADDENDUM_NOTIFICATION_SENT"] = "ADDENDUM_NOTIFICATION_SENT";
    ActivityVerb["EARLY_PAYMENT_INTENT_CREATED"] = "EARLY_PAYMENT_INTENT_CREATED";
    ActivityVerb["EARLY_PAYMENT_INTENT_UPDATED"] = "EARLY_PAYMENT_INTENT_UPDATED";
    ActivityVerb["EARLY_PAYMENT_INTENT_SUBMITTED"] = "EARLY_PAYMENT_INTENT_SUBMITTED";
    ActivityVerb["EARLY_PAYMENT_REQUEST_CREATED"] = "EARLY_PAYMENT_REQUEST_CREATED";
    ActivityVerb["EARLY_PAYMENT_REQUEST_OFFERED"] = "EARLY_PAYMENT_REQUEST_OFFERED";
    ActivityVerb["EARLY_PAYMENT_REQUEST_AVAILABLE"] = "EARLY_PAYMENT_REQUEST_AVAILABLE";
    ActivityVerb["EARLY_PAYMENT_REQUEST_UPDATED"] = "EARLY_PAYMENT_REQUEST_UPDATED";
    ActivityVerb["EARLY_PAYMENT_REQUEST_SENT"] = "EARLY_PAYMENT_REQUEST_SENT";
    ActivityVerb["EARLY_PAYMENT_REQUEST_SUBMITTED"] = "EARLY_PAYMENT_REQUEST_SUBMITTED";
    ActivityVerb["EARLY_PAYMENT_REQUEST_APPROVED"] = "EARLY_PAYMENT_REQUEST_APPROVED";
    ActivityVerb["EARLY_PAYMENT_REQUEST_APPROVAL_FAILED"] = "EARLY_PAYMENT_REQUEST_APPROVAL_FAILED";
    ActivityVerb["EARLY_PAYMENT_REQUEST_REJECTED"] = "EARLY_PAYMENT_REQUEST_REJECTED";
    ActivityVerb["EARLY_PAYMENT_REQUEST_CANCELLED"] = "EARLY_PAYMENT_REQUEST_CANCELLED";
    ActivityVerb["EARLY_PAYMENT_TERMS_ACCEPTED"] = "EARLY_PAYMENT_TERMS_ACCEPTED";
    ActivityVerb["EARLY_PAYMENT_REQUEST_CONFIRMED"] = "EARLY_PAYMENT_REQUEST_CONFIRMED";
    ActivityVerb["JOBPAC_SYNC"] = "JOBPAC_SYNC";
    ActivityVerb["JOBPAC_SYNC_FAILED"] = "JOBPAC_SYNC_FAILED";
    ActivityVerb["JOBPAC_UPDATE_PAYMENT_DATE"] = "JOBPAC_UPDATE_PAYMENT_DATE";
    ActivityVerb["JOBPAC_UPDATE_PAYMENT_DATE_FAILED"] = "JOBPAC_UPDATE_PAYMENT_DATE_FAILED";
    ActivityVerb["JOBPAC_ADD_CREDIT_NOTE_CREDITOR"] = "JOBPAC_ADD_CREDIT_NOTE_CREDITOR";
    ActivityVerb["JOBPAC_ADD_CREDIT_NOTE_CREDITOR_FAILED"] = "JOBPAC_ADD_CREDIT_NOTE_CREDITOR_FAILED";
    ActivityVerb["JOBPAC_ADD_CREDIT_NOTE_PROGRESSPAY"] = "JOBPAC_ADD_CREDIT_NOTE_PROGRESSPAY";
    ActivityVerb["JOBPAC_ADD_CREDIT_NOTE_PROGRESSPAY_FAILED"] = "JOBPAC_ADD_CREDIT_NOTE_PROGRESSPAY_FAILED";
    ActivityVerb["JOBPAC_ADD_NEW_PM_WORKSHEET"] = "JOBPAC_ADD_NEW_PM_WORKSHEET";
    ActivityVerb["JOBPAC_ADD_NEW_PM_WORKSHEET_FAILED"] = "JOBPAC_ADD_NEW_PM_WORKSHEET_FAILED";
})(ActivityVerb || (exports.ActivityVerb = ActivityVerb = {}));
