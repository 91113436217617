import {
  PageTitleSlotType,
  useInternalLayoutContext,
} from "@/components/layouts/InternalLayout";
import { StyledAccordion } from "@/components/StyledAccordion";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { cn } from "@/utils/ui";
import { UploadOutlined } from "@ant-design/icons";
import {
  UpdateOrganisationsRequest,
  UpdateUserRequest,
  UserInviteRequest,
} from "@progresspay-next/dtos";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  App,
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
  TabsProps,
  Upload,
} from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getApi } from "../utils/api";
import { baseURL, getHeaders } from "../utils/api/_request";
import { useQueryUserNonAdmin, useQueryUsersNonAdmin } from "../utils/query";
import { UsersListTable } from "./users/UsersList";

interface ProfileProps {}

const DynanmicFieldsSection = ({ readOnly }: { readOnly: boolean }) => {
  const [label, setLabel] = useState("");
  const [id, setId] = useState("");
  const [value, setValue] = useState("");
  const form = Form.useFormInstance();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const dynamic = Form.useWatch(["integrations", "erp", "dynamic"], {
    form,
    preserve: true,
  });
  const shouldGenerateId = true;

  const resetForm = () => {
    setLabel("");
    setId("");
    setValue("");
  };

  const isNewFormValid = () => {
    // Check required
    if (!label || !id || !value) {
      return false;
    }
    // Check duplication
    if (dynamic) {
      for (let f of dynamic) {
        if (f.id == id) {
          return false;
        }
      }
    }
    return true;
  };

  const addNewDynamicField = () => {
    const newValue = dynamic ? dynamic : [];
    newValue.push({
      label: label,
      id: id,
      value: value,
      type: "text",
    });
    form.setFieldValue(["integrations", "erp", "dynamic"], newValue);
    resetForm();
    setIsDialogOpen(false);
  };

  const removeDynamicField = (i: number) => {
    const newValue = dynamic.map((d: any) => d);
    newValue.splice(i, 1);
    form.setFieldValue(["integrations", "erp", "dynamic"], newValue);
  };

  return (
    <>
      <Row gutter={16}>
        <Col span={24} className={cn("mb-4")}>
          <h2>
            <b>Early Payment Settings</b>
          </h2>
        </Col>
      </Row>
      <Row gutter={16}>
        {dynamic
          ? (dynamic as any[]).map((field, i) => {
              return (
                <Col span={6} key={field.id}>
                  <Form.Item
                    label={`${field.label}`}
                    name={["integrations", "erp", "dynamic", i, "value"]}
                    tooltip={`ID: ${field.id}`}
                    extra={
                      readOnly ? null : (
                        <div className="text-right">
                          <a
                            className="text-xs"
                            onClick={(e) => removeDynamicField(i)}
                          >
                            Delete this field
                          </a>
                        </div>
                      )
                    }
                  >
                    <Input disabled={readOnly} />
                  </Form.Item>

                  <Form.Item
                    noStyle
                    name={["integrations", "erp", "dynamic", i, "label"]}
                  >
                    <Input hidden disabled={readOnly} />
                  </Form.Item>

                  <Form.Item
                    noStyle
                    name={["integrations", "erp", "dynamic", i, "id"]}
                  >
                    <Input hidden disabled={readOnly} />
                  </Form.Item>

                  <Form.Item
                    noStyle
                    name={["integrations", "erp", "dynamic", i, "type"]}
                  >
                    <Input hidden disabled={readOnly} />
                  </Form.Item>
                </Col>
              );
            })
          : null}
        {readOnly ? null : (
          <Col span={6}>
            <Dialog
              onOpenChange={(v) => setIsDialogOpen(v)}
              open={isDialogOpen}
            >
              <DialogTrigger asChild>
                <Button className="flex h-full min-h-[110px] w-full items-center justify-center border border-dashed border-gray-300">
                  Add New Field
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Add a dynamic field</DialogTitle>
                  <DialogDescription asChild>
                    <Row gutter={16} className="pt-4">
                      <Col span={8}>
                        <Form.Item label={`Label`}>
                          <Input
                            disabled={readOnly}
                            className=""
                            value={label}
                            onChange={(e) => {
                              setLabel(e.target.value);
                              if (shouldGenerateId) {
                                setId(_.snakeCase(e.target.value));
                              }
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label={`ID`}>
                          <Input
                            disabled={readOnly}
                            className=""
                            value={id}
                            onChange={(e) => setId(e.target.value)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label={`Value`}>
                          <Input
                            disabled={readOnly}
                            className=""
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </DialogDescription>
                  <DialogFooter>
                    <div className="flex flex-nowrap items-center">
                      <p className="p-2 text-xs italic text-gray-500">
                        Remember to click <b>"Update"</b> to save them in the
                        next screen
                      </p>
                      <Button
                        onClick={addNewDynamicField}
                        disabled={!isNewFormValid()}
                      >
                        Add Field
                      </Button>
                    </div>
                  </DialogFooter>
                </DialogHeader>
              </DialogContent>
            </Dialog>
          </Col>
        )}
      </Row>
    </>
  );
};

export const OrganisationFormIntegrationItems = (props: {
  readOnly?: boolean;
  isValueInherited?: boolean;
  isShowingEarlyPaymentSettingOnly?: boolean;
}) => {
  const {
    readOnly = false,
    isValueInherited = false,
    isShowingEarlyPaymentSettingOnly = false,
  } = props;
  const form = Form.useFormInstance();
  const integrationType = Form.useWatch(["integrations", "pas", "type"], form);
  const externalIntegrationType = Form.useWatch(
    ["integrations", "erp", "type"],
    form
  );

  return (
    <>
      <div className={isShowingEarlyPaymentSettingOnly ? "hidden" : ""}>
        <Divider>
          <div>
            <div>Payment Application System</div>
            {isValueInherited ? (
              <span className="text-xs text-gray-400">
                (inherited from parent organisation)
              </span>
            ) : null}
          </div>
        </Divider>

        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              label="Payment Application System"
              name={["integrations", "pas", "type"]}
            >
              <Select
                disabled={readOnly || isShowingEarlyPaymentSettingOnly}
                className=""
                options={[
                  {
                    value: "GCPAY",
                    label: "GCPay",
                  },
                  {
                    value: "PAYAPPS",
                    label: "PayApps",
                  },
                ]}
              ></Select>
            </Form.Item>
          </Col>
          {integrationType ? (
            <Col span={6}>
              <Form.Item label="Payment Application System ID" name="pas_id">
                <Input disabled={readOnly} />
              </Form.Item>
            </Col>
          ) : null}
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            {integrationType ? (
              <Form.Item
                label="Show in iframe?"
                name={["integrations", "pas", "iframe"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={readOnly || isShowingEarlyPaymentSettingOnly}
                  className=""
                />
              </Form.Item>
            ) : null}
          </Col>
        </Row>
      </div>

      <Divider>
        <div>
          <div>ERP Integration</div>
          {isValueInherited ? (
            <span className="text-xs text-gray-400">
              (inherited from parent organisation)
            </span>
          ) : null}
        </div>
      </Divider>
      <div className={isShowingEarlyPaymentSettingOnly ? "hidden" : ""}>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              label="ERP Integration Type"
              name={["integrations", "erp", "type"]}
            >
              <Select
                disabled={readOnly || isShowingEarlyPaymentSettingOnly}
                className=""
                options={[
                  {
                    value: "JOBPAC",
                    label: "Jobpac",
                  },
                  {
                    value: "VISTA",
                    label: "Vista",
                  },
                ]}
              ></Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="ERP ID" name="erp_id">
              <Input disabled={readOnly} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="ERP ID 2" name="erp_id_2">
              <Input disabled={readOnly} />
            </Form.Item>
          </Col>
        </Row>
        {externalIntegrationType ? (
          <Row gutter={16}>
            <Col span={24} className={cn("mb-4")}>
              <h2>
                <b>{externalIntegrationType} Connection</b>
              </h2>
            </Col>
          </Row>
        ) : null}
        <Row gutter={16}>
          {externalIntegrationType ? (
            <>
              <Col span={6}>
                <Form.Item
                  label={`${externalIntegrationType} URL`}
                  name={["integrations", "erp", "url"]}
                >
                  <Input
                    disabled={readOnly || isShowingEarlyPaymentSettingOnly}
                    className=""
                  />
                </Form.Item>
              </Col>
              <br />
              <Col span={6}>
                <Form.Item
                  label={`${externalIntegrationType} Username`}
                  name={["integrations", "erp", "username"]}
                >
                  <Input
                    disabled={readOnly || isShowingEarlyPaymentSettingOnly}
                    className=""
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={`${externalIntegrationType} Password`}
                  name={["integrations", "erp", "password"]}
                >
                  <Input
                    disabled={readOnly || isShowingEarlyPaymentSettingOnly}
                    type="password"
                    className=""
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={`${externalIntegrationType} Environment`}
                  name={["integrations", "erp", "environment"]}
                >
                  <Input
                    disabled={readOnly || isShowingEarlyPaymentSettingOnly}
                    className=""
                  />
                </Form.Item>
              </Col>
            </>
          ) : null}
        </Row>
      </div>

      {externalIntegrationType ? (
        <DynanmicFieldsSection readOnly={readOnly} />
      ) : null}
    </>
  );
};

const OrganisationFormSettingsItems = () => {
  const api = getApi();
  const me = useQuery({
    queryKey: ["me"],
    queryFn: api.me,
  });
  return (
    <Row gutter={16}>
      <Col span={12}>
        <Form.Item
          label="ProgressPay Fee Percentage"
          name={["settings", "pp_fee"]}
        >
          <InputNumber readOnly addonAfter="%" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label="Total Facility Limit"
          name={["settings", "facilityLimit"]}
        >
          <InputNumber addonBefore="$" />
        </Form.Item>
      </Col>
    </Row>
  );
};

const UpdateOrganisationForm = () => {
  const { message } = App.useApp();
  const api = getApi();
  const me = useQuery({
    queryKey: ["me"],
    queryFn: api.me,
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const meDetails = useQueryUserNonAdmin(me.data?.id as string, {
    enabled: me.isSuccess,
  });
  useEffect(() => {
    if (meDetails.data?.organisation?.logo_attachment_id) {
      setLogoId(meDetails.data?.organisation?.logo_attachment_id);
    }
  }, [meDetails.data?.organisation?.logo_attachment_id]);

  const updateOrganisation = useMutation({
    mutationFn: (organisation: UpdateOrganisationsRequest) => {
      return api.updateOrganisationNonAdmin(organisation);
    },
    onSuccess: (data, variables, context) => {
      message.success("Your organisation has been updated successfully.");
      queryClient.invalidateQueries({ queryKey: ["users"] });
      queryClient.invalidateQueries({ queryKey: ["organisations"] });
    },
  });

  const onFinish = (values: any) => {
    updateOrganisation.mutate(values);
  };
  const [form] = Form.useForm();
  const [formIntegration] = Form.useForm();
  const [formSettings] = Form.useForm();
  const [logoId, setLogoId] = useState<string | null>(null);
  const [logoUrl, setLogoUrl] = useState<string | null>(null);

  const signedUrlQuery = useQuery({
    queryKey: ["attachmentUrl", { logoId }],
    queryFn: () => api.getAttachmentUrlById(logoId as string),
    enabled: !!logoId,
  });
  useEffect(() => {
    if (signedUrlQuery.data) {
      setLogoUrl(signedUrlQuery.data.url);
    }
  }, [signedUrlQuery.data]);
  const onLogoChange = ({ file, fileList, event }: any) => {
    if (file.status == "done") {
      setLogoId(file.response.id);
      form.setFieldValue("logo_attachment_id", file.response.id);
    }
  };
  return (
    <div>
      {me.isSuccess && meDetails.isSuccess ? (
        <>
          <StyledAccordion
            defaultKey={["basicInfo"]}
            type="multiple"
            items={[
              {
                heading: `Basic Info`,
                key: `basicInfo`,
                content: (
                  <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinish}
                    autoComplete="off"
                    initialValues={{
                      id: meDetails.data?.organisation?.id,
                      name: meDetails.data?.organisation?.name,
                      erp_id: meDetails.data?.organisation?.erp_id,
                      abn: meDetails.data?.organisation?.abn,
                      acn: meDetails.data?.organisation?.acn,
                      address: {
                        street: meDetails.data?.organisation?.address?.street,
                        postcode:
                          meDetails.data?.organisation?.address?.postcode,
                        city: meDetails.data?.organisation?.address?.city,
                        state: meDetails.data?.organisation?.address?.state,
                      },
                      logo_attachment_id: logoId,
                    }}
                  >
                    <Form.Item noStyle name="id" id="organisationId">
                      <Input type="hidden" />
                    </Form.Item>

                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="Company Logo">
                          <Form.Item name="logo_attachment_id" noStyle>
                            <Input type="hidden" />
                          </Form.Item>
                          {logoUrl ? (
                            <img className="mb-4" src={logoUrl} />
                          ) : null}
                          <Upload
                            showUploadList={false}
                            action={`${baseURL}/attachments/organisation-logo`}
                            headers={getHeaders()}
                            onChange={onLogoChange}
                          >
                            <Button icon={<UploadOutlined />}>
                              Click to Upload a New Logo
                            </Button>
                          </Upload>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="ERP ID" name="erp_id">
                          <Input className="" readOnly disabled />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Name" name="name">
                          <Input className="" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col span={12}>
                        <Form.Item label="ABN" name="abn">
                          <Input className="" />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item label="ACN" name="acn">
                          <Input className="" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={16}>
                      <Col span={6}>
                        <Form.Item label="Street" name={["address", "street"]}>
                          <Input className="" />
                        </Form.Item>
                      </Col>

                      <Col span={6}>
                        <Form.Item label="Suburb" name={["address", "city"]}>
                          <Input className="" />
                        </Form.Item>
                      </Col>

                      <Col span={6}>
                        <Form.Item label="State" name={["address", "state"]}>
                          <Input className="" />
                        </Form.Item>
                      </Col>

                      <Col span={6}>
                        <Form.Item
                          label="Postcode"
                          name={["address", "postcode"]}
                        >
                          <Input className="" />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item>
                      <Button shape="round" type="primary" htmlType="submit">
                        Update
                      </Button>
                    </Form.Item>
                  </Form>
                ),
              },
              // {
              //   heading: `Integrations`,
              //   key: `integrations`,
              //   content: (
              //     <Form
              //       form={formIntegration}
              //       layout="vertical"
              //       onFinish={onFinish}
              //       autoComplete="off"
              //       initialValues={{
              //         id: meDetails.data?.organisation?.id,
              //         integrations: {
              //           pas: {
              //             type: meDetails.data?.organisation?.integrations?.pas
              //               ?.type,
              //             iframe:
              //               meDetails.data?.organisation?.integrations?.pas
              //                 ?.iframe,
              //           },
              //           erp: {
              //             type: meDetails.data?.organisation?.integrations?.erp
              //               ?.type,
              //             url: meDetails.data?.organisation?.integrations?.erp
              //               ?.url,
              //             username:
              //               meDetails.data?.organisation?.integrations?.erp
              //                 ?.username,
              //             password:
              //               meDetails.data?.organisation?.integrations?.erp
              //                 ?.password,
              //             environment:
              //               meDetails.data?.organisation?.integrations?.erp
              //                 ?.environment,
              //             dynamic:
              //               meDetails.data?.organisation?.integrations?.erp
              //                 ?.dynamic,
              //           },
              //         },
              //       }}
              //     >
              //       <OrganisationFormIntegrationItems readOnly />
              //     </Form>
              //   ),
              // },
              // {
              //   heading: `Disounts`,
              //   key: `disounts`,
              //   content: (
              //     <DiscountsSetting
              //       isNonAdmin
              //       organisation={meDetails.data?.organisation}
              //     />
              //   ),
              // },
              // ...me.data.is_gc || me.data.is_system_admin
              //   ? [{
              //       heading: `Settings`,
              //       key: `settings`,
              //       content: (
              //         <Form
              //           form={formSettings}
              //           layout="vertical"
              //           onFinish={onFinish}
              //           autoComplete="off"
              //           initialValues={{
              //             id: meDetails.data?.organisation?.id,
              //             settings: {
              //               pp_fee:
              //                 meDetails.data?.organisation?.settings?.pp_fee ??
              //                 10,
              //               facilityLimit:
              //                 meDetails.data?.organisation?.settings
              //                   ?.facilityLimit ?? 0,
              //             },
              //           }}
              //         >
              //           <OrganisationFormSettingsItems />
              //           <Form.Item>
              //             <Button
              //               shape="round"
              //               type="primary"
              //               htmlType="submit"
              //             >
              //               Update
              //             </Button>
              //           </Form.Item>
              //         </Form>
              //       ),
              //     }]
              //   : [],
              // ...(me.data.is_gc || me.data.is_system_admin) &&
              // me.data.organisation_id
              //   ? [{
              //       heading: `Facility Exclusions`,
              //       key: `facilityLimittExclusions`,
              //       content: <ExclusionSettingForm id={me.data.organisation_id} />,
              //     }]
              //   : [],
              {
                heading: `Users`,
                key: `users`,
                content: <InviteForm />,
              },
            ]}
          ></StyledAccordion>
        </>
      ) : (
        <Spin />
      )}
    </div>
  );
};
export const EditUserButtonAndModal = ({
  first_name,
  last_name,
  email,
  mobile,
  phone,
  is_org_admin,
  is_fin_admin,
  id,
}: {
  first_name: string;
  last_name: string;
  email: string;
  mobile: string;
  phone: string;
  is_org_admin: boolean;
  is_fin_admin: boolean;
  id: string;
}) => {
  const { message } = App.useApp();
  const api = getApi();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const updateUserMutation = useMutation({
    mutationFn: (payload: UpdateUserRequest) => api.updateUser(payload),
    onSuccess: () => {
      message.success("User updated!");
      queryClient.invalidateQueries({ queryKey: ["users"] });
      queryClient.invalidateQueries({ queryKey: ["organisations"] });
      setIsModalOpen(false);
    },
  });
  const initialFormValues = {
    firstName: first_name,
    lastName: last_name,
    email: email,
    phone: phone,
    mobile: mobile,
    isOrgAdmin: is_org_admin,
    isFinAdmin: is_fin_admin,
  };
  const onFinish = (v: typeof initialFormValues) => {
    updateUserMutation.mutate({
      id,
      first_name: v.firstName,
      last_name: v.lastName,
      email: v.email,
      mobile: v.mobile,
      phone: v.phone,
      is_org_admin: v.isOrgAdmin,
      is_fin_admin: v.isFinAdmin,
    });
  };
  return (
    <>
      <Button type="primary" onClick={showModal}>
        Edit
      </Button>
      <Modal
        title="Edit User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={initialFormValues}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="First Name" name="firstName" required>
                <Input className="" required />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Last Name" name="lastName" required>
                <Input className="" required />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Mobile" name="mobile" required>
                <Input className="" required />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Phone" name="phone">
                <Input className="" type="email" required />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Email" name="email" required>
                <Input className="" type="email" required />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="isOrgAdmin" valuePropName="checked">
                <Checkbox className="">Organisation Admin Role</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="isFinAdmin" valuePropName="checked">
                <Checkbox className="">Finance Admin Role</Checkbox>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export const InviteUserButtonAndModal = ({
  organisationId,
}: {
  organisationId: string;
}) => {
  const { message } = App.useApp();
  const api = getApi();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const inviteUserMutation = useMutation({
    mutationFn: (payload: UserInviteRequest) => api.inviteUser(payload),
    onSuccess: () => {
      message.success("Invitation sent!");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["users"] });
      queryClient.invalidateQueries({ queryKey: ["organisations"] });
      setIsModalOpen(false);
    },
  });
  const initialFormValues = {
    firstName: ``,
    lastName: ``,
    email: ``,
    phone: ``,
    mobile: ``,
    isOrgAdmin: false,
    isFinAdmin: false,
  };
  const onFinish = (v: typeof initialFormValues) => {
    inviteUserMutation.mutate({
      organisation_id: organisationId,
      first_name: v.firstName,
      last_name: v.lastName,
      email: v.email,
      mobile: v.mobile,
      phone: v.phone,
      is_org_admin: v.isOrgAdmin,
      is_fin_admin: v.isFinAdmin,
    });
  };
  return (
    <>
      <Button
        type="primary"
        shape="round"
        onClick={showModal}
        style={{ marginBottom: 10 }}
      >
        Add User
      </Button>
      <Modal
        title="Add User"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={initialFormValues}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="First Name" name="firstName" required>
                <Input className="" required />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Last Name" name="lastName" required>
                <Input className="" required />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Mobile" name="mobile" required>
                <Input className="" required />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Phone" name="phone">
                <Input className="" type="email" required />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Email" name="email" required>
                <Input className="" type="email" required />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="isOrgAdmin"
                label={`Organisaion Admin Role`}
                valuePropName="checked"
              >
                <Switch className="bg-slate-300"></Switch>
                {/* <Checkbox className="">Organisaion Admin Role</Checkbox> */}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="isFinAdmin"
                label={`Finance Admin Role`}
                valuePropName="checked"
              >
                <Switch className="bg-slate-300"></Switch>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
const InviteForm = () => {
  const api = getApi();
  const me = useQuery({
    queryKey: ["me"],
    queryFn: api.me,
  });
  const usersInMyOrganisation = useQueryUsersNonAdmin(
    {
      organisation_id: me.data?.organisation_id as string,
    },
    {
      enabled: me.isSuccess,
    }
  );

  return (
    <div>
      <UsersListTable
        users={usersInMyOrganisation.data}
        loading={usersInMyOrganisation.isLoading}
      />
      <Divider></Divider>
      <InviteUserButtonAndModal
        organisationId={String(me.data?.organisation_id)}
      ></InviteUserButtonAndModal>
    </div>
  );
};
const items: TabsProps["items"] = [
  {
    key: "organisation",
    label: `Organisation`,
    children: <UpdateOrganisationForm />,
  },
  {
    key: "invite",
    label: `Invite`,
    children: <InviteForm />,
  },
];

export const OrganisationProfile: (
  props: ProfileProps
) => JSX.Element | null = () => {
const setPageTitleSlotProps = useInternalLayoutContext()?.setPageTitleSlotProps;
  useEffect(() => {
    setPageTitleSlotProps && setPageTitleSlotProps({
      type: PageTitleSlotType.SimplePageTitle,
      props: {
        title: "My Organisation",
      },
    });
  }, [setPageTitleSlotProps]);
  return (
    <div>
      <UpdateOrganisationForm />
    </div>
  );
};

export const PageTitle = () => {
  return <div className="pageTitle">My Organisation</div>;
};
