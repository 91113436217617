import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

interface GlobalState {
  accessToken: string;
  isAdmin: boolean;
  setIsSystemAdmin: (isAdmin: boolean) => void;
  userLogin: (token: string) => void;
  userLogout: () => void;
}
export const useGlobalStore = create<GlobalState>()(
  devtools(
    persist(
      (set) => ({
        accessToken: "",
        isAdmin: false,
        setIsSystemAdmin: (isAdmin: boolean) => set((state) => ({ isAdmin })),
        userLogin: (token: string) => set((state) => ({ accessToken: token })),
        userLogout: () => set((state) => ({ accessToken: "" })),
      }),
      {
        name: "@progresspay-next/global-storage",
      }
    )
  )
);
