"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserTypeSchema = void 0;
const zod_1 = require("zod");
const organisation_type_1 = require("./organisation.type");
exports.UserTypeSchema = zod_1.z.object({
    id: zod_1.z.string(),
    pas_id: zod_1.z.string().nullable(),
    first_name: zod_1.z.string().nullable(),
    last_name: zod_1.z.string().nullable(),
    first_last: zod_1.z.string().nullish(),
    email: zod_1.z.string(),
    mobile: zod_1.z.string().nullable(),
    phone: zod_1.z.string().nullable(),
    is_system_admin: zod_1.z.boolean(),
    is_org_admin: zod_1.z.boolean(),
    is_fin_admin: zod_1.z.boolean(),
    is_gc: zod_1.z.boolean(),
    organisation_id: zod_1.z.string(),
    disabled_at: zod_1.z.string().nullable(),
    organisation: organisation_type_1.OrganisationTypeSchema.nullable(),
});
