import { App, Button, Checkbox, Form, Input, Layout, Space, Spin, Typography } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useGlobalStore } from '../stores/global';
import { getApi } from '../utils/api';
import { ResetPasswordRequest, ValidateInviteTokenResponse } from '@progresspay-next/dtos';
import { useEffect, useState } from 'react';
import { useForm } from 'antd/es/form/Form';

interface ResetPasswordProps {
  isNewUser?: boolean
}

export const ResetPassword: (props:ResetPasswordProps) => JSX.Element | null = ({
  isNewUser = false
}) => {
    const { message } = App.useApp();
    const store = useGlobalStore();
    const { token } = useParams();
    const navigate = useNavigate();
    const api = getApi();
    const [form] = useForm();

    const [isChecking, setIsChecking] = useState<boolean>(false);
    const [validateTokenResult, setValidateTokenResult] = useState<ValidateInviteTokenResponse>();
    // If this is for new user, we'll need to validate token first
    const validateToken = useMutation({
      mutationFn: () => api.validateInviteToken(token ? token : ''),
      onSuccess: (data) => {
        setValidateTokenResult(data);
        form.setFieldValue('email', data.email);
      },
      onSettled: (data) => {
        setIsChecking(false);
      },
    });
    const { mutate: validateTokenMutate } = validateToken;
    useEffect(() => {
      if (token && isNewUser) {
        setIsChecking(true);
        validateTokenMutate();
      }
    },[isNewUser, token, validateTokenMutate])

    const resetPassword = useMutation({
      mutationFn: (payload:ResetPasswordRequest) => {
        return api.resetPassword(payload);
      },
      onSuccess: (data, variables, context) => {
        navigate('/');
        message.success("Your password has been successfully reset!");
      }
    })
  
    const onFinish = (values: any) => {
      resetPassword.mutate(values);
    };
  
  
      return isChecking ? <Spin/> : (
        <>
          <div className="mb-8 flex items-center gap-4">
            <span className="text-3xl font-bold">{ isNewUser ? `Set New Password`: `Reset Password`}</span>
          </div>
          <Form
            form={form}
            name="basic"
            onFinish={onFinish}
            autoComplete="off"
            initialValues={{
                token: token,
                email: ``
            }}
            layout="vertical"
          >
  
            <Form.Item
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Please enter your email!" },
                { type: "email", message: "Please enter a valid emaill!" },
              ]}
            >
              <Input  readOnly={isNewUser} disabled={isNewUser}/>
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please enter your password!" },
                {
                  min: 6,
                  message: "The password should be at least 6 characters!",
                },
              ]}
            >
              <Input.Password/>
            </Form.Item>

            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              rules={[
                { required: true, message: "Please enter your password!" },
                {
                  min: 6,
                  message: "The password should be at least 6 characters!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item noStyle
              label="Token"
              name="token"
            >
              <Input type={'hidden'} />
            </Form.Item>
  
            {resetPassword.isError ? (
              <Form.Item>
                <Typography.Paragraph type={"danger"}>
                  An error occurred: {(resetPassword.error as Error).message}
                </Typography.Paragraph>
              </Form.Item>
            ) : null}
  
            <Form.Item>
              <Space>
                <Button size='large' type="primary" htmlType="submit">
                  Submit
                </Button>
                <Link to="/">
                  <Button size='large' htmlType="button">Back</Button>
                </Link>
              </Space>
            </Form.Item>
          </Form>
        </>
      );
}