import { Breadcrumb } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const breadcrumbItemRender = (route:any, params:any, routes:any[], paths:any) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={route.path}>{route.breadcrumbName}</Link>
  );
}

export const PageHeader = ({
  routes,
  extra
}: {
  routes: any[],
  extra?:React.ReactNode|null
}) => {
  return (<div className="pb-4 flex flex-row nowrap justify-between"
>
  <Breadcrumb items={routes.map((r,index) => {
    const last = index === routes.length - 1;
    return {
      title: last ? r.breadcrumbName : <Link to={r.path}>{r.breadcrumbName}</Link>
    }}
  )} />
  {
    extra ? extra :null
  }
</div>)
}